import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import LoadingOverlay from 'react-loading-overlay';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { handleGetRequest } from '../../services/GetTemplate';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { handlePostRequestFormData } from '../../services/PostFormDataTemplate';
import { baseURL } from '../../Config';

const EditUsersSuperAdmin = () => {

    const location = useLocation();
    const { isEditable, rowData, superAdminActive } = location?.state;

    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")

    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [allRoles, setAllRoles] = useState([])
    const [userTypes, setUserTypes] = useState([])
    const [warehouseData, setWarehouseData] = useState([])
    const [userTypeAs, setUserTypeAs] = useState(1);
    const [fileInfo, setFileInfo] = useState(null);
    const [showImage, setShowImage] = useState('');
    const [editableRemoved, setEditableRemoved] = useState(false);
    const [myStatus, setMyStatus] = useState();

    const [systemUser, setSystemUser] = useState(false);
    const [WHAdmin, setWHAdmin] = useState(false);
    const [WhAdminUser, setWHAdminUser] = useState(false);

    const history = useHistory()
    const dispatch = useDispatch()

    //WH Admin
    const validationSchema = roletypeid == '2' && usertype_id == 2 && warehouseid
        ? Yup.object().shape({
            user_id: Yup.string().required("This field is required."),
            user_name: Yup.string().required("This field is required."),
            email: Yup.string().required("This field is required."),
            role: Yup.string().required("This field is required."),
            rfid_code: Yup.string().required("This field is required."),
            contact: Yup.string().required("This field is required."),
        })
        //Super Admin for UserType Warehouse
        : (roletypeid == '1' && userTypeAs == 2) || (roletypeid === '2' && userTypeAs == 2)
            ? Yup.object().shape({
                user_type_id: Yup.string().required("This field is required."),
                warehouse_id: Yup.string().required("This field is required."),
                user_name_2: Yup.string().required("This field is required."),
                email_2: Yup.string().required("This field is required."),
                contact_2: Yup.string().required("This field is required."),
            })
            //Super Admin for Usertype System User
            : (roletypeid == '1' && userTypeAs == 0 && !warehouseid) ?
                Yup.object().shape({
                    user_type_id: Yup.string().required("This field is required."),
                    warehouse_id: Yup.string().required("This field is required."),
                    user_name_2: Yup.string().required("This field is required."),
                    email_2: Yup.string().required("This field is required."),
                    role_2: Yup.string().required("This field is required."),
                    contact_2: Yup.string().required("This field is required."),
                    rfid_code_2: Yup.string().required("This field is required."),
                })
                //System User
                : (roletypeid == '2' && userTypeAs == 1) || (roletypeid === '2' && !userTypeAs)
                    ? Yup.object().shape({
                        user_type_id: Yup.string().required("This field is required."),
                        user_name_2: Yup.string().required("This field is required."),
                        email_2: Yup.string().required("This field is required."),
                        contact_2: Yup.string().required("This field is required."),
                        role_2: Yup.string().required("This field is required."),
                        rfid_code_2: Yup.string().required("This field is required."),
                    })

                    // Super Admin login Adding System User
                    : (roletypeid == '1' && userTypeAs == 1 && usertype_id == '0')
                        ? Yup.object().shape({
                            user_type_id: Yup.string().required("This field is required."),
                            user_name_2: Yup.string().required("This field is required."),
                            email_2: Yup.string().required("This field is required."),
                            contact_2: Yup.string().required("This field is required."),
                            role_2: Yup.string().required("This field is required."),
                            rfid_code_2: Yup.string().required("This field is required."),
                        })
                        : null;


    const formik = useFormik({
        //  validationSchema: validationSchema,
        initialValues: {
            // For Admin
            user_id: "",
            user_name: "",
            email: "",
            rfid_code: "",
            role: "",
            contact: "",

            // For Super Admin (WH Admin)
            user_type_id: 1,
            warehouse_id: "",
            user_name_2: "",
            email_2: "",
            contact_2: "",
            user_id2: "",

            // For Super Admin (Users)
            rfid_code_2: "",
            role_2: "",
        },

        onSubmit: async (data) => {

            let formData = new FormData();
            let formData2 = new FormData();
            let formData3 = new FormData();

            if ((fileInfo && isEditable === false) || (fileInfo && isEditable === true && editableRemoved) || (fileInfo && isEditable === true && !showImage)) {
                formData.append('image', fileInfo.file);
                formData2.append('image', fileInfo.file);
                formData3.append('image', fileInfo.file);
            }
            // else if (fileInfo && isEditable === true) {
            //     formData.append('image', 'unchanged');
            //     formData2.append('image', 'unchanged');
            //     formData3.append('image', 'unchanged');
            // }

            if (isEditable) {

                //For WH Admin User
                formData.append('p_id', parseInt(rowData?.id));
                formData.append('User_id', parseInt(formik.values.user_id));
                formData.append('User_Name', formik.values.user_name);
                formData.append('User_Email', formik.values.email);
                formData.append('RFID_code', formik.values.rfid_code);
                formData.append('Role_id', formik.values.role);
                formData.append('contact_number', formik.values.contact);
                formData.append('Status_id', myStatus);
                formData.append('roletype_id', !isNaN(parseInt(roletypeid)) ? parseInt(roletypeid) : 0);
                formData.append('warehouse_id', formik.values.warehouse_id);
                formData.append(
                    'usertypeid',
                    roletypeid == '2' && usertype_id == 2 && warehouseid ? 1 : !isNaN(parseInt(usertype_id)) ? parseInt(usertype_id) : 0
                );
                formData.append('CreatedBy', !isNaN(parseInt(user_id)) ? parseInt(user_id) : 0);
                formData.append('image', `${baseURL}${showImage}`)

                //For WH ADMIN
                formData2.append('p_id', parseInt(rowData?.id));
                formData2.append('User_id', parseInt(formik.values.user_id2));
                formData2.append('usertypeid', parseInt(formik.values.user_type_id));
                formData2.append('User_Name', formik.values.user_name_2);
                formData2.append('User_Email', formik.values.email_2);
                formData2.append('contact_number', formik.values.contact_2);
                formData2.append('warehouse_id', formik.values.warehouse_id);
                formData2.append('Status_id', myStatus);
                formData2.append('RFID_code', 0);
                formData2.append('roletype_id', !isNaN(parseInt(roletypeid)) ? parseInt(roletypeid) : 0);
                // formData2.append('usertypeid', !isNaN(parseInt(usertype_id)) ? parseInt(usertype_id) : 0);
                formData2.append('CreatedBy', !isNaN(parseInt(user_id)) ? parseInt(user_id) : 0);
                formData2.append('image', `${baseURL}${showImage}`)

                //For Super Admin System Users
                formData3.append('p_id', parseInt(rowData?.id));
                formData3.append('User_id', parseInt(formik.values.user_id2));
                formData3.append('usertypeid', parseInt(formik.values.user_type_id));
                formData3.append('User_Name', formik.values.user_name_2);
                formData3.append('User_Email', formik.values.email_2);
                formData3.append('contact_number', formik.values.contact_2);
                formData3.append('RFID_code', formik.values.rfid_code_2);
                formData3.append('Role_id', formik.values.role_2);
                formData3.append('Status_id', myStatus);
                formData3.append('roletype_id', !isNaN(parseInt(roletypeid)) ? parseInt(roletypeid) : 0);
                // formData3.append('usertypeid', !isNaN(parseInt(usertype_id)) ? parseInt(usertype_id) : 0);
                formData3.append('CreatedBy', !isNaN(parseInt(user_id)) ? parseInt(user_id) : 0);
                formData3.append('image', `${baseURL}${showImage}`)

                setSaveBtnLoading(true);

                let response;
                response = await dispatch(handlePostRequestFormData(
                    WHAdmin === true && WhAdminUser === false && systemUser === false ? formData2 :
                        WHAdmin === false && WhAdminUser === false && systemUser === true ? formData3 :
                            WHAdmin === false && WhAdminUser === true && systemUser === false ? formData :
                                null,
                    "/api/Authentication/UpdatenewUser",
                    true,
                    true
                ));

                if (response?.statusCode == 200) {
                    history.push('./users')
                    getUserList();
                    setSaveBtnLoading(false);
                }
                setSaveBtnLoading(false);
            }

            else {
            }

        }

    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    //Get All Users
    const getUserList = async () => {

        setIsActive(true);
        const response = await dispatch(handleGetRequest(`/api/Authentication/GetAllUsers?roletype_id=${roletypeid}&usertype_id=${usertype_id}&warehouse_id=${warehouseid || 0}`, false));
        setIsActive(false);
    };


    //Get User By Id
    const getUserById = async (rowData) => {

        setIsActive(true)
        const response = await dispatch(handleGetRequest(`/api/Authentication/GetUserByid?p_id=${rowData?.id}`, true));

        const imagePath = response[0]?.p_image && response[0]?.p_image !== '' ? response[0]?.p_image : null;
        setShowImage(response[0]?.p_image ? response[0]?.p_image : '')
        // const formData = new FormData();

        if (imagePath) {
            fetch(imagePath)
                .then(response => response.blob())
                .then(blob => {
                    const blobURL = URL.createObjectURL(blob);

                    // Get the complete file name from the path
                    const fileName = imagePath.substring(imagePath.lastIndexOf('/') + 1);
                    // Add "Profile_Image_" before the file name
                    const modifiedFileName = 'Profile_Image/' + fileName;
                    // Determine the file extension
                    const format = fileName.split('.').pop().toLowerCase();
                    // Set the correct MIME type for the file
                    const fileType = `image/${format === 'jpg' ? 'jpeg' : format}`;

                    // Create the File object with the blob, retaining the modified file name and type
                    const file = new File([blob], modifiedFileName, { type: fileType });
                    // Append the file to the FormData object
                    const formData = new FormData();
                    setFileInfo(file)
                    formData.append('image', file);

                    URL.revokeObjectURL(blobURL);
                })
                .catch(error => {
                    console.error('Error fetching image:', error);
                });
        }


        const keyData = response[0]

        if (keyData) {

            //Super Admin Login Cases
            if (keyData?.p_roletype_id == 2 && keyData?.p_usertypeid == 2 && keyData?.p_warehouse_id !== 0 && superAdminActive && isEditable) {
                setSystemUser(false)
                setWHAdmin(true)
                setWHAdminUser(false)
                setUserTypeAs(keyData?.p_usertypeid)
                setMyStatus(keyData?.p_isActive)

                formik.setFieldValue('user_type_id', keyData?.p_usertypeid)
                formik.setFieldValue('warehouse_id', keyData?.p_warehouse_id)
                formik.setFieldValue('contact_2', keyData?.p_contactNumber)
                formik.setFieldValue('user_name_2', keyData?.p_user_name)
                formik.setFieldValue('email_2', keyData?.p_User_Email)
                formik.setFieldValue('user_id2', keyData?.p_User_id)

            }

            else if (keyData?.p_roletype_id == 2 && keyData?.p_usertypeid == 1 && keyData?.p_warehouse_id !== 0 && superAdminActive && isEditable) {
                setSystemUser(false)
                setWHAdmin(false)
                setWHAdminUser(true)
                setUserTypeAs(keyData?.p_usertypeid)
                setMyStatus(keyData?.p_isActive)

                formik.setFieldValue('user_id', keyData?.p_User_id)
                formik.setFieldValue('user_name', keyData?.p_user_name)
                formik.setFieldValue('email', keyData?.p_User_Email)
                formik.setFieldValue('rfid_code', keyData?.p_RFID_code)
                formik.setFieldValue('contact', keyData?.p_contactNumber)
                formik.setFieldValue('role', keyData?.p_Role_id)
                formik.setFieldValue('warehouse_id', keyData?.p_warehouse_id)

            }

            else if (keyData?.p_roletype_id == 2 && keyData?.p_usertypeid == 1 && keyData?.p_warehouse_id === 0 && superAdminActive && isEditable) {
                setSystemUser(true)
                setWHAdmin(false)
                setWHAdminUser(false)
                setUserTypeAs(keyData?.p_usertypeid)
                setMyStatus(keyData?.p_isActive)

                formik.setFieldValue('user_id2', keyData?.p_User_id)
                formik.setFieldValue('user_name_2', keyData?.p_user_name)
                formik.setFieldValue('email_2', keyData?.p_User_Email)
                formik.setFieldValue('rfid_code_2', keyData?.p_RFID_code)
                formik.setFieldValue('contact_2', keyData?.p_contactNumber)
                formik.setFieldValue('role_2', keyData?.p_Role_id)
            }
        }
    };

    const ResetForm = () => {
        formik.setFieldValue('user_id2', '')
        formik.setFieldValue('user_id', '')
        formik.setFieldValue('user_name', '')
        formik.setFieldValue('email', '')
        formik.setFieldValue('rfid_code', '')
        formik.setFieldValue('role', '')
        formik.setFieldValue('contact', '')
        formik.setFieldValue('user_type_id', '')
        formik.setFieldValue('warehouse_id', '')
        formik.setFieldValue('user_name_2', '')
        formik.setFieldValue('email_2', '')
        formik.setFieldValue('contact_2', '')
        formik.setFieldValue('rfid_code_2', '')
        formik.setFieldValue('role_2', '')
        setFileInfo(null)
    }

    useEffect(() => {
        if (isEditable, rowData) {
            getUserById(rowData);
        }
    }, [isEditable, rowData]);

    const getAllRoles = async () => {

        setIsActive(true);
        const response = await dispatch(handleGetRequest(`/api/Authentication/getAllRole?rid=${2}&uid=${formik.values.user_type_id || 0}&wid=${formik?.values?.warehouse_id || 0}`, true));
        if (response) {
            setAllRoles(response);
        }
        setIsActive(false);
    };

    const getUserTypes = async () => {

        setIsActive(true);
        const res = await dispatch(handleGetRequest("/api/Authentication/UserType", true));
        if (res) {
            setUserTypes(res);
        }
        setIsActive(false);
    };

    const getWarehouseData = async () => {

        setIsActive(true);
        const res = await dispatch(handleGetRequest("/api/Authentication/Warehouse", true));
        if (res) {
            setWarehouseData(res);
        }
        setIsActive(false);
    };

    useEffect(() => {
        getUserTypes()
        getWarehouseData()
    }, [])

    useEffect(() => {
        if (formik.values.warehouse_id || formik.values.user_type_id) {
            getAllRoles()
        }
    }, [formik.values.warehouse_id, formik.values.user_type_id])

    //File Handling
    const handleIconClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.onchange = handleChange;
        fileInput.click();
    };

    const handleChange = (event) => {
        processFile(event.target.files[0]);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            processFile(event.dataTransfer.files[0]);
            event.dataTransfer.clearData();
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const processFile = (event) => {
        const file = event;
        if (file) {
            const objectURL = URL.createObjectURL(file);
            setFileInfo({
                file: file,  // Storing the actual File object
                objectURL: objectURL,
                lastModified: file.lastModified,
                lastModifiedDate: file.lastModifiedDate,
                name: file.name,
                size: file.size,
                type: file.type,
                webkitRelativePath: file.webkitRelativePath || ""
            });
        }
    };

    const handleRemoveImage = () => {
        if (showImage) {
            URL.revokeObjectURL(fileInfo.objectURL); // Free up memory by releasing object URL
            setShowImage('')
            setEditableRemoved(true)
        }
        if (fileInfo) {
            URL.revokeObjectURL(fileInfo.objectURL); // Free up memory by releasing object URL
            setShowImage('')
        }
        setFileInfo(null);
    };

    //Generate User Id
    const handleGenerateUserId = () => {
        const newUserId = generateRandomUserId();
        formik.setFieldValue('user_id', newUserId);
    };

    const generateRandomUserId = () => {
        const length = 4;
        const charset = "0123456789";
        let userId = "";

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            userId += charset[randomIndex];
        }

        return userId;
    };

    const handleGenerateUserId2 = () => {
        const newUserId = generateRandomUserId2();
        formik.setFieldValue('user_id2', newUserId);
    };

    const generateRandomUserId2 = () => {
        const length = 4;
        const charset = "0123456789";
        let userId = "";

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            userId += charset[randomIndex];
        }

        return userId;
    };

    //Generate RFID Code
    const handleGenerateCode = () => {
        const newCode = generateRandomCode();
        formik.setFieldValue('rfid_code', newCode);
    };

    const generateRandomCode = () => {
        const length = 10;
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let code = "";

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            code += charset[randomIndex];
        }
        return code;
    };

    //Generate RFID Code 2
    const handleGenerateCode2 = () => {
        const newCode = generateRandomCode2();
        formik.setFieldValue('rfid_code_2', newCode);
    };

    const generateRandomCode2 = () => {
        const length = 10;
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let code = "";

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            code += charset[randomIndex];
        }
        return code;
    };

    return (

        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgb(38 41 51 / 78%)',
                        width: '107.9%',
                        height: '125%',
                        top: '-27px',
                        left: '-35px'
                    })
                }}
            >
                <div className='main-form-card'>
                    <div className='form-header flex flex-row justify-content-between'>
                        <div className='form-header-content'>
                            <h4 style={{ fontWeight: '600', letterSpacing: '1px', color: 'black' }}> {isEditable ? 'Edit User' : 'Add New User'}</h4>
                            <h6 style={{ color: 'gray' }}> Add Details</h6>
                        </div>
                        <div className='mt-4 mr-3'>
                            <Button className='Back-Button mr-3' icon="pi pi-arrow-left" label='Go Back' onClick={() => history.goBack()}>
                            </Button>
                        </div>
                    </div>
                    <div className='form-div'>
                        <form onSubmit={formik.handleSubmit}>

                            <div className="card-form">
                                <div className="card-nested-form">
                                    <div className="grid p-3">
                                        <div className="col-12 lg:col-4 md:col-4 p-5">
                                            <div className='flex flex-column'>
                                                <span style={{ fontWeight: "bold", fontSize: "18px", color: 'black' }}>Profile Photo</span>
                                                <span style={{ color: "light", fontSize: "14px" }}>This image will be displayed on your profile</span>
                                            </div>
                                        </div>
                                        <div className="settings-header col-12 lg:col-2 md:col-2 p-3 flex">
                                            {fileInfo || showImage ?
                                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                                    <img src={isEditable && showImage ? `${baseURL}${showImage}` : isEditable && editableRemoved === true ? fileInfo?.objectURL : fileInfo?.objectURL} alt="Uploaded" style={{ width: '120px', height: '120px', borderRadius: "50%" }} />
                                                    <i className="pi pi-times" style={{ position: 'absolute', top: '0', right: '0', cursor: 'pointer', fontSize: '1em', color: 'red', padding: '5px', background: 'rgba(255, 255, 255, 0.8)', borderRadius: '50%' }} onClick={handleRemoveImage}></i>
                                                </div>
                                                :
                                                null}
                                        </div>
                                        <div className="settings-header col-12 lg:col-6 md:col-6 p-3">
                                            <div>
                                                <div className="flex align-items-center flex-column dp-scrollable"
                                                    onDrop={handleDrop} onDragOver={handleDragOver}
                                                    style={{ border: '2px dashed #ccc', padding: '20px', width: '100%', textAlign: 'center' }}>
                                                    <div style={{ backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <i className="pi pi-cloud-upload" style={{ fontSize: '2em', cursor: 'pointer' }} onClick={handleIconClick}></i>
                                                    </div>
                                                    <span style={{ fontSize: '1.2em', color: 'black' }} className="mb-1 image-template">
                                                        <span style={{ fontWeight: "bold" }}> Click to Upload </span> or drag and drop
                                                    </span>
                                                    <span style={{ fontSize: '1em', color: 'gray' }} className="mb-2 image-template">
                                                        JPG or PNG (Recommended size 500x500px)
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {(WhAdminUser === false && systemUser === true) || (WhAdminUser === false && WHAdmin === true) ?
                                        <>
                                            <div className="grid">
                                                <div className="col-12 lg:col-4 md:col-4">
                                                    <div className="input-card flex flex-column p-3">
                                                        <div className='flex flex-row'>
                                                            <label> User Type </label>
                                                            <span className="Label__Required">*</span>
                                                        </div>
                                                        <Dropdown
                                                            disabled
                                                            placeholder='--Select Role--'
                                                            id="user_type_id"
                                                            name="user_type_id"
                                                            options={userTypes}
                                                            optionLabel='name'
                                                            optionValue='id'
                                                            value={formik.values.user_type_id}
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                                setUserTypeAs(e.value); // Assuming e.value corresponds to the selected value in the dropdown
                                                            }}
                                                            className="p-inputtext-sm"
                                                            autoComplete="off"
                                                        ></Dropdown>

                                                        <span className='ml-2'>
                                                            {getFormErrorMessage("user_type_id")}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4 md:col-4">
                                                    {formik.values.user_type_id == 2 ?
                                                        <div className="input-card flex flex-column p-3">
                                                            <div className='flex flex-row'>
                                                                <label> Warehouse </label>
                                                                <span className="Label__Required">*</span>
                                                            </div>
                                                            <Dropdown
                                                                filter
                                                                filterBy='name'
                                                                placeholder='--Select Warehouse--'
                                                                id="warehouse_id"
                                                                name="warehouse_id"
                                                                options={warehouseData}
                                                                optionLabel='name'
                                                                optionValue='id'
                                                                value={formik.values.warehouse_id}
                                                                onChange={formik.handleChange}
                                                                className="p-inputtext-sm"
                                                                autoComplete="off"
                                                            ></Dropdown>
                                                            <span className='ml-2'>
                                                                {getFormErrorMessage("warehouse_id")}
                                                            </span>
                                                        </div>
                                                        :
                                                        <div className="input-card flex flex-column p-3">
                                                            <div className='flex flex-row'>
                                                                <label> User Name </label>
                                                                <span className="Label__Required">*</span>
                                                            </div>
                                                            <InputText
                                                                maxLength={30}
                                                                keyfilter={/^[a-zA-Z\s]*$/}
                                                                placeholder='Enter Name'
                                                                id="user_name_2"
                                                                name="user_name_2"
                                                                value={formik.values.user_name_2}
                                                                onChange={formik.handleChange}
                                                                className="p-inputtext-sm"
                                                                autoComplete="off"
                                                            ></InputText>
                                                            <span className='ml-2'>
                                                                {getFormErrorMessage("user_name_2")}
                                                            </span>
                                                        </div>
                                                    }
                                                </div>

                                                <div className="col-12 lg:col-4 md:col-4">
                                                    {formik.values.user_type_id == 2 ?
                                                        <div className="input-card flex flex-column p-3">
                                                            <div className='flex flex-row'>
                                                                <label> Contact No. </label>
                                                                <span className="Label__Required">*</span>
                                                            </div>
                                                            <InputText maxLength={15} minLength={10} keyfilter={/^[0-9.+]+/} className="p-inputtext-sm" id="contact_2" name="contact_2" placeholder='Enter Contact' value={formik.values.contact_2} onChange={formik.handleChange} type="text" />
                                                            <span className='ml-2'>
                                                                {getFormErrorMessage("contact_2")}
                                                            </span>
                                                        </div>
                                                        :
                                                        <div className="input-card flex-column p-3">
                                                            <div className='flex-row'>
                                                                <label> RFID Entry Code </label>
                                                                <span className="Label__Required">*</span>
                                                            </div>
                                                            <div style={{ position: 'relative' }}>
                                                                <InputText
                                                                    disabled
                                                                    type='text'
                                                                    placeholder='Auto Generate RFID Code'
                                                                    id='rfid_code_2'
                                                                    name="rfid_code_2"
                                                                    value={formik.values.rfid_code_2}
                                                                    onChange={formik.handleChange}
                                                                    className="p-inputtext-sm"
                                                                    autoComplete="off"
                                                                />
                                                                <Button
                                                                    tooltip='Auto Generate User Id'
                                                                    tooltipOptions={{ position: "top" }}
                                                                    type="button"
                                                                    icon="pi pi-refresh"
                                                                    className='auto-generate-button'
                                                                    onClick={handleGenerateCode2}
                                                                    style={{
                                                                        background: '#8d7ad5',
                                                                        borderColor: "#8d7ad5",
                                                                        position: 'absolute',
                                                                        top: '30%',
                                                                        right: '8px',
                                                                        transform: 'translateY(-50%)',
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className='ml-2'>
                                                                {getFormErrorMessage("rfid_code_2")}
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            </div>

                                            <div className="grid">

                                                <div className="col-12 lg:col-4 md:col-4">
                                                    {formik.values.user_type_id == 1 || formik.values.user_type_id === '' ?
                                                        <div className="input-card flex flex-column p-3">
                                                            <div className='flex flex-row'>
                                                                <label> Role </label>
                                                                <span className="Label__Required">*</span>
                                                            </div>
                                                            <Dropdown
                                                                placeholder='--Select Role--'
                                                                id="role_2"
                                                                name="role_2"
                                                                options={allRoles}
                                                                optionLabel='name'
                                                                optionValue='id'
                                                                value={formik.values.role_2}
                                                                onChange={formik.handleChange}
                                                                className="p-inputtext-sm"
                                                                autoComplete="off"
                                                            ></Dropdown>
                                                            <span className='ml-2'>
                                                                {getFormErrorMessage("role_2")}
                                                            </span>
                                                        </div>
                                                        :
                                                        formik.values.user_type_id === 2 ?
                                                            <div className="input-card flex flex-column p-3">
                                                                <div className='flex flex-row'>
                                                                    <label> User Name </label>
                                                                    <span className="Label__Required">*</span>
                                                                </div>
                                                                <InputText maxLength={30} keyfilter={/^[a-zA-Z\s]*$/} className="p-inputtext-sm" id="user_name_2" name="user_name_2" placeholder='Enter Name' value={formik.values.user_name_2} onChange={formik.handleChange} type="text" />
                                                                <span className='ml-2'>
                                                                    {getFormErrorMessage("user_name_2")}
                                                                </span>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>

                                                <div className="col-12 lg:col-4 md:col-4">
                                                    <div className="input-card flex flex-column p-3">

                                                        <div className='flex flex-row'>
                                                            <label> Email </label>
                                                            <span className="Label__Required">*</span>
                                                        </div>
                                                        <InputText disabled maxLength={40} className="p-inputtext-sm" id="email_2" name="email_2" placeholder='Enter Email' value={formik.values.email_2} onChange={formik.handleChange} type="email" />
                                                        <span className='ml-2'>
                                                            {getFormErrorMessage("email_2")}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-12 lg:col-4 md:col-4">
                                                    {formik.values.user_type_id !== 2 ?
                                                        <div className="input-card flex flex-column p-3">
                                                            <div className='flex flex-row'>
                                                                <label> Contact No. </label>
                                                                <span className="Label__Required">*</span>
                                                            </div>
                                                            <InputText maxLength={15} minLength={10} keyfilter={/^[0-9.+]+/} className="p-inputtext-sm" id="contact_2" name="contact_2" placeholder='Enter Contact' value={formik.values.contact_2} onChange={formik.handleChange} type="text" />
                                                            <span className='ml-2'>
                                                                {getFormErrorMessage("contact_2")}
                                                            </span>
                                                        </div>
                                                        :
                                                        <div className="input-card flex-column p-3">
                                                            <div className='flex-row'>
                                                                <label> User ID </label>
                                                                <span className="Label__Required">*</span>
                                                            </div>
                                                            <div style={{ position: 'relative' }}>
                                                                <InputText
                                                                    disabled
                                                                    type='text'
                                                                    placeholder='Auto Generate User Id'
                                                                    id='user_id2'
                                                                    name="user_id2"
                                                                    value={formik.values.user_id2}
                                                                    onChange={formik.handleChange}
                                                                    className="p-inputtext-sm"
                                                                    autoComplete="off"
                                                                />
                                                                <Button
                                                                    // disabled={isEditable && disabledWHAdminField}
                                                                    tooltip='Auto Generate User Id'
                                                                    tooltipOptions={{ position: "top" }}
                                                                    type="button"
                                                                    icon="pi pi-refresh"
                                                                    className='auto-generate-button'
                                                                    onClick={handleGenerateUserId2}
                                                                    style={{
                                                                        background: '#8d7ad5',
                                                                        borderColor: "#8d7ad5",
                                                                        position: 'absolute',
                                                                        top: '30%',
                                                                        right: '8px',
                                                                        transform: 'translateY(-50%)',
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className='ml-2'>
                                                                {getFormErrorMessage("user_id2")}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                            </div>

                                            {systemUser == true && WHAdmin == false && WhAdminUser == false
                                                ?
                                                <div className="grid">
                                                    <div className="col-12 lg:col-4 md:col-4">
                                                        <div className="input-card flex-column p-3">
                                                            <div className='flex-row'>
                                                                <label> User ID </label>
                                                                <span className="Label__Required">*</span>
                                                            </div>
                                                            <div style={{ position: 'relative' }}>
                                                                <InputText
                                                                    disabled
                                                                    type='text'
                                                                    placeholder='Auto Generate User Id'
                                                                    id='user_id2'
                                                                    name="user_id2"
                                                                    value={formik.values.user_id2}
                                                                    onChange={formik.handleChange}
                                                                    className="p-inputtext-sm"
                                                                    autoComplete="off"
                                                                />
                                                                <Button
                                                                    // disabled={isEditable && disabledWHAdminField}
                                                                    tooltip='Auto Generate User Id'
                                                                    tooltipOptions={{ position: "top" }}
                                                                    type="button"
                                                                    icon="pi pi-refresh"
                                                                    className='auto-generate-button'
                                                                    onClick={handleGenerateUserId2}
                                                                    style={{
                                                                        background: '#8d7ad5',
                                                                        borderColor: "#8d7ad5",
                                                                        position: 'absolute',
                                                                        top: '30%',
                                                                        right: '8px',
                                                                        transform: 'translateY(-50%)',
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className='ml-2'>
                                                                {getFormErrorMessage("user_id2")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null}

                                        </>
                                        :
                                        null}

                                    {WhAdminUser && systemUser === false && WHAdmin === false ?
                                        <>

                                            <div className="grid">
                                                <div className="col-12 lg:col-4 md:col-4">
                                                    <div className="input-card flex flex-column p-3">
                                                        <div className='flex flex-row'>
                                                            <label> User Type </label>
                                                            <span className="Label__Required">*</span>
                                                        </div>
                                                        <Dropdown
                                                            disabled
                                                            placeholder='--Select Role--'
                                                            id="user_type_id"
                                                            name="user_type_id"
                                                            options={userTypes}
                                                            optionLabel='name'
                                                            optionValue='id'
                                                            value={formik.values.user_type_id}
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                                setUserTypeAs(e.value); // Assuming e.value corresponds to the selected value in the dropdown
                                                            }}
                                                            className="p-inputtext-sm"
                                                            autoComplete="off"
                                                        ></Dropdown>

                                                        <span className='ml-2'>
                                                            {getFormErrorMessage("user_type_id")}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4 md:col-4">
                                                    <div className="input-card flex flex-column p-3">
                                                        <div className='flex flex-row'>
                                                            <label> Warehouse </label>
                                                            <span className="Label__Required">*</span>
                                                        </div>
                                                        <Dropdown
                                                            filter
                                                            filterBy='name'
                                                            placeholder='--Select Warehouse--'
                                                            id="warehouse_id"
                                                            name="warehouse_id"
                                                            options={warehouseData}
                                                            optionLabel='name'
                                                            optionValue='id'
                                                            value={formik.values.warehouse_id}
                                                            onChange={formik.handleChange}
                                                            className="p-inputtext-sm"
                                                            autoComplete="off"
                                                        ></Dropdown>
                                                        <span className='ml-2'>
                                                            {getFormErrorMessage("warehouse_id")}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4 md:col-4">
                                                    <div className="input-card flex flex-column p-3">
                                                        <div className='flex flex-row'>
                                                            <label> Role </label>
                                                            <span className="Label__Required">*</span>
                                                        </div>
                                                        <Dropdown
                                                            placeholder='--Select Role--'
                                                            id="role"
                                                            name="role"
                                                            options={allRoles}
                                                            optionLabel='name'
                                                            optionValue='id'
                                                            value={formik.values.role}
                                                            onChange={formik.handleChange}
                                                            className="p-inputtext-sm"
                                                            autoComplete="off"
                                                        ></Dropdown>
                                                        <span className='ml-2'>
                                                            {getFormErrorMessage("role")}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="grid">
                                                <div className="col-12 lg:col-4 md:col-4">
                                                    <div className="input-card flex-column p-3">
                                                        <div className='flex-row'>
                                                            <label> User ID </label>
                                                            <span className="Label__Required">*</span>
                                                        </div>
                                                        <div style={{ position: 'relative' }}>
                                                            <InputText
                                                                disabled
                                                                type='text'
                                                                placeholder='Auto Generate User Id'
                                                                id='user_id'
                                                                name="user_id"
                                                                value={formik.values.user_id}
                                                                onChange={formik.handleChange}
                                                                className="p-inputtext-sm"
                                                                autoComplete="off"
                                                            />
                                                            <Button
                                                                tooltip='Auto Generate User Id'
                                                                tooltipOptions={{ position: "top" }}
                                                                type="button"
                                                                icon="pi pi-refresh"
                                                                className='auto-generate-button'
                                                                onClick={handleGenerateUserId}
                                                                style={{
                                                                    background: '#8d7ad5',
                                                                    borderColor: "#8d7ad5",
                                                                    position: 'absolute',
                                                                    top: '30%',
                                                                    right: '8px',
                                                                    transform: 'translateY(-50%)',
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='ml-2'>
                                                            {getFormErrorMessage("user_id")}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4 md:col-4">
                                                    <div className="input-card flex flex-column p-3">
                                                        <div className='flex flex-row'>
                                                            <label> User Name </label>
                                                            <span className="Label__Required">*</span>
                                                        </div>
                                                        <InputText maxLength={30} keyfilter={/^[a-zA-Z\s]*$/} className="p-inputtext-sm" id="user_name" name="user_name" placeholder='Enter Name' value={formik.values.user_name} onChange={formik.handleChange} type="text" />
                                                        <span className='ml-2'>
                                                            {getFormErrorMessage("user_name")}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-12 lg:col-4 md:col-4">
                                                    <div className="input-card flex flex-column p-3">

                                                        <div className='flex flex-row'>
                                                            <label> Email </label>
                                                            <span className="Label__Required">*</span>
                                                        </div>
                                                        <InputText disabled maxLength={40} className="p-inputtext-sm" id="email" name="email" placeholder='Enter Email' value={formik.values.email} onChange={formik.handleChange} type="email" />
                                                        <span className='ml-2'>
                                                            {getFormErrorMessage("email")}
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="grid">
                                                <div className="col-12 lg:col-4 md:col-4">
                                                    <div className="input-card flex-column p-3">

                                                        <div className='flex-row'>
                                                            <label> RFID Entry Code </label>
                                                            <span className="Label__Required">*</span>
                                                        </div>
                                                        <div style={{ position: 'relative' }}>
                                                            <InputText
                                                                disabled
                                                                type='text'
                                                                placeholder='Auto Generate RFID Code'
                                                                id='rfid_code'
                                                                name="rfid_code"
                                                                value={formik.values.rfid_code}
                                                                onChange={formik.handleChange}
                                                                className="p-inputtext-sm"
                                                            />
                                                            <Button
                                                                tooltip='Auto Generate RFID Code'
                                                                tooltipOptions={{ position: "top" }}
                                                                type="button"
                                                                className='auto-generate-button'
                                                                icon="pi pi-refresh"
                                                                onClick={handleGenerateCode}
                                                                style={{
                                                                    background: '#8d7ad5',
                                                                    borderColor: "#8d7ad5",
                                                                    position: 'absolute',
                                                                    top: '30%',
                                                                    right: '8px',
                                                                    transform: 'translateY(-50%)',
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='ml-2'>
                                                            {getFormErrorMessage("rfid_code")}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4 md:col-4">
                                                    <div className="input-card flex flex-column p-3">
                                                        <div className='flex flex-row'>
                                                            <label> Contact No. </label>
                                                            <span className="Label__Required">*</span>
                                                        </div>
                                                        <InputText
                                                            maxLength={15}
                                                            minLength={10}
                                                            keyfilter={/^[0-9.+]+/}
                                                            placeholder='Enter Contact'
                                                            id="contact"
                                                            name="contact"
                                                            value={formik.values.contact}
                                                            onChange={formik.handleChange}
                                                            className="p-inputtext-sm"
                                                        ></InputText>
                                                        <span className='ml-2'>
                                                            {getFormErrorMessage("contact")}
                                                        </span>
                                                    </div>

                                                </div>

                                                <div className="col-12 lg:col-4 md:col-4">

                                                </div>
                                            </div>
                                        </>
                                        :
                                        null
                                    }

                                    <div className='col-12 d-flex flex-row text-center mt-4 pb-2'>
                                        <Button className="Cancel-Button" label="Reset" type="button" onClick={ResetForm} disabled={isEditable ? true : false} />
                                        <Button className="Save-Button ml-2" label={isEditable ? "Update" : "Add User"} loading={saveBtnLoading} type="submit" />
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </LoadingOverlay>
        </>
    )
}

export default EditUsersSuperAdmin