import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    token: localStorage.getItem('token') || null,
};

const logoutSlice = createSlice({
    name: 'logout',
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.clear();
            localStorage.removeItem('lastActive');
            localStorage.removeItem("permissions");
            localStorage.removeItem("login");
            localStorage.removeItem("token");
            window.localStorage.clear();
            window.sessionStorage.clear();
            state.token = null;
        },
    },
});

export const { logout } = logoutSlice.actions;
export default logoutSlice.reducer;
