import React, { useLayoutEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { useHistory,useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import loginImage from "../../../assets/warehouse-assets/warehouse.png"
import { handlePostRequest } from "../../../services/PostTemplate";
import { toast } from "react-toastify";
import { handleAuthRequest } from "../../../services/Auth";

const ResetPassword = () => {

    const {search }= useLocation();
    const query = new URLSearchParams(search);
    const token = query?.get('token')
    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    const validationSchema = Yup.object().shape({
        password: Yup.mixed().required("This field is required."),
        confirmPassword: Yup.mixed().required("This field is required."),
    });

    const formik = useFormik({

        validationSchema: validationSchema,
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        onSubmit: async (data) => {


            if (data?.password === data?.confirmPassword) {
                setSaveBtnLoading(true);
                let obj = {
                    newPassword:data?.confirmPassword,
                    user_token:token
                }
                setSaveBtnLoading(false)
                const response = await dispatch(handleAuthRequest(obj, "/api/Authentication/ResetPassword", true, true));
                if (response?.statusCode == 200) {
                    setSaveBtnLoading(false)
                }
                else {
                    setSaveBtnLoading(false)
                }
            } else {
                toast.warn("Password entered didn't match")
            }

        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useLayoutEffect(()=>{
        if(!token){
            history.goBack()
        }
    },[token])


    return (

        <>
            <div className="login-page-container">
                <div className="left-section">
                    <img src={loginImage} alt="Image Alt Text" className="imgMainLogo" />
                    {/* <img src={logo} className="imgAppLogo" /> */}
                </div>

                <div className="right-section mb-6">
                    <form onSubmit={formik.handleSubmit} className="login-form">
                        <div className="text-center justify-content-center">
                            <h1 className="mb-6" style={{ color: "black", letterSpacing: "1px", fontWeight: "700", color: "#6a4ed1" }}> WareHouse <span style={{ color: 'black' }} >  MS </span> </h1>
                            <h3 className="mt-7" style={{ color: "black", letterSpacing: "1px", fontWeight: "600" }}> Reset Your Password </h3>
                            <h6 className="mb-6" style={{ color: "black", letterSpacing: "1px", fontWeight: "400", color: "#8c8c8c" }}> Time for a fresh start! Go ahead and set a new password. </h6>
                        </div>

                        <div className="login-div mt-6">
                            <div class="input-card-login flex-column p-3">
                                <div className='flex-row'>
                                    <label> New Password </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <Password
                                    placeholder="Enter new password"
                                    id="password"
                                    name="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    toggleMask
                                    autoComplete="off"
                                    feedback={false}
                                    className="w-full custom-password-field"
                                />
                                <span className="ml-2">
                                    {getFormErrorMessage("password")}
                                </span>
                            </div>

                            <div class="input-card-login flex-column p-3">
                                <div className='flex-row'>
                                    <label> Confirm New Password </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <Password
                                    placeholder="Enter confirm password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    toggleMask
                                    autoComplete="off"
                                    feedback={false}
                                    className="w-full custom-password-field"
                                />
                                <span className="ml-2">
                                    {getFormErrorMessage("confirmPassword")}
                                </span>
                            </div>

                            <div className='col-12 d-flex flex-row text-center mt-5'>
                                <Button className="Login-Button" label="Save Changes" loading={saveBtnLoading} type="submit" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>

    );
};

export default ResetPassword;
