
import { setCustomerData } from "../slices/checkoutUserSlice";

export const userAction = (inventoryData) => async (dispatch) => {
    const res = await inventoryData;
    localStorage.removeItem('customerDetail');
    if (res) {
        localStorage.setItem('customerDetail', JSON.stringify(res));
    }
    dispatch(setCustomerData(res));
    return res;
};