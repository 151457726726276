import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "authenticationSlice",
    initialState: {
        token: localStorage.getItem("token"),
    },
    reducers: {
        LOGIN_SUCCESS: (state, action) => {
            return {
                ...state,          
                token: localStorage.getItem("token"),
            };
        },
        LOGIN_ERROR: (state) => {
            return {
                ...state,
                token: "",
            };
        },
    },
});

export const { LOGIN_SUCCESS, LOGIN_ERROR } = slice.actions;
export default slice.reducer;
