import { baseURL } from "../Config";
import axios from "axios";
import { toast } from "react-toastify";
import { loadingAction } from "../redux/actions/loadingAction";
import { logout } from "../redux/slices/logoutSlice";

export const handleGetRequest = (url, isShowLoad = false) =>
    async (dispatch) => {
        try {
            if (isShowLoad) dispatch(loadingAction(true));
            const response = await axios.get(`${baseURL + url}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            // if (isShowLoad) dispatch(loadingAction(false));
            return response.data;
        } catch (error) {
            if (error?.response?.data?.StatusCode == '403' && error?.response?.status == 401) {
                toast.warn(error?.response?.data?.StatusMessage || 'Authentication Failed ! Please login again');
                dispatch(logout());
            }
            else {
                toast.warn(error?.response?.data?.statusMessage || error?.response?.data?.message || error?.message || "Something went wrong !!");
            }
        }
    };