import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import LoadingOverlay from 'react-loading-overlay'
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { handlePostRequest } from '../../services/PostTemplate';
import { handlePutRequest } from '../../services/PutTemplate';
import { useDispatch } from 'react-redux';
import { handleGetRequest } from '../../services/GetTemplate';
import { Dropdown } from 'primereact/dropdown';

const AddEditSubProject = () => {
    const [isActive, setIsActive] = useState(false);
    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const [projectLOV, setProjectLOV] = useState([])

    const location = useLocation();
    const history = useHistory()
    const dispatch = useDispatch()

    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")

    const { isEditable, rowData, isView } = location?.state || {};


    const validationSchema = Yup.object().shape({
        projectName: Yup.mixed().required("This field is required."),
        subProjectName: Yup.mixed().required("This field is required."),
        workOrderNumber: Yup.mixed().required("This field is required."),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            projectName: "",
            subProjectName: "",
            workOrderNumber: "",
            description: ""
        },

        onSubmit: async (data) => {
            if (isEditable) {
                setIsActive(true)
                const obj = {
                    obj_id: rowData?.obj_id,
                    isActive: rowData?.status,
                    name: data?.subProjectName,
                    description: data?.description,
                    workordernumber: data?.workOrderNumber,
                    parentid: data?.projectName,
                    warehouseid: warehouseid,
                    edit_by: user_id
                }

                setIsActive(false)

                const response = await dispatch(handlePutRequest(obj, "/api/Setups/UpdateSubProject", true, true));
                setSaveBtnLoading(true);
                if (response?.statusCode == 200) {
                    history.push('./project')
                    setSaveBtnLoading(false);
                }
            }
            else {
                setIsActive(true)

                const obj = {
                    name: data?.subProjectName,
                    description: data?.description,
                    workordernumber: data?.workOrderNumber,
                    parent_id: data?.projectName,
                    warehouseid: warehouseid,
                    created_by: user_id
                }

                const response = await dispatch(handlePostRequest(obj, "/api/Setups/AddSubProject", true, true));
                setSaveBtnLoading(true);
                if (response?.statusCode == 200) {
                    history.push('./project')
                    setSaveBtnLoading(false);
                }

                setIsActive(false)
            }
        },
    })

    const formStyle = {
        pointerEvents: isView ? 'none' : 'auto',
        opacity: isView ? 0.7 : 1,
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const ResetForm = () => {
        formik.resetForm()
    }

    const getProjectLOV = async () => {
        setIsActive(true);

        const response = await dispatch(handleGetRequest(`/api/Setups/GetAllSubProjects?warehouseid=${warehouseid || 0}`, true));
        if (response) {
            setProjectLOV(response);
        }
        setIsActive(false);

    }

    const getSubProjectById = async () => {
        setIsActive(true)
        const response = await dispatch(handleGetRequest(`/api/Setups/GetSubProjectByid?obj_id=${rowData?.obj_id}`, false));
        const keyData = response[0]
        if (keyData) {
            formik.setFieldValue('projectName', keyData?.parent_id)
            formik.setFieldValue('subProjectName', keyData?.obj_Name)
            formik.setFieldValue('workOrderNumber', keyData?.workordernumber)
            formik.setFieldValue('description', keyData?.obj_Description)
        }
        setIsActive(false)
    }
    useEffect(() => {
        getProjectLOV()
    }, [])

    useEffect(() => {
        if (isEditable, rowData) {
            getSubProjectById(rowData);
        }
    }, [isEditable, rowData]);




    return (
        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgb(38 41 51 / 78%)',
                        width: '107.9%',
                        height: '125%',
                        top: '-27px',
                        left: '-35px'
                    })
                }}
            >
                <div className='main-form-card'>
                    <div className='form-header flex flex-row justify-content-between'>
                        <div className='form-header-content'>
                            <h4 style={{ fontWeight: '600', letterSpacing: '1px', color: 'black' }}>{isEditable && isView ? 'View Sub Project' : isEditable && isView === false ? 'Edit Sub Project' : 'Add New Sub Project'}</h4>
                            <h6 style={{ color: 'gray' }}>{isView ? '' : 'Add Details'} </h6>
                        </div>
                        <div className='mt-4 mr-3'>
                            <Button className='Back-Button mr-3' icon="pi pi-arrow-left" label='Go Back' onClick={() => history.goBack()}>
                            </Button>
                        </div>
                    </div>
                    <div className='form-div'>
                        <form onSubmit={formik.handleSubmit} style={formStyle}>
                            <div className="card-form">
                                <div className="card-nested-form">
                                    <div className="grid">
                                        <div className="col-12 lg:col-4 md:col-4">
                                            <div className="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label>Project Name</label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <Dropdown
                                                    placeholder='--Select Project--'
                                                    id="projectName"
                                                    name="projectName"
                                                    options={projectLOV}
                                                    optionLabel='obj_name'
                                                    optionValue='obj_id'
                                                    value={formik?.values?.projectName}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                    autoComplete="off"
                                                ></Dropdown>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("projectName")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 lg:col-4 md:col-4">
                                            <div className="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label>Sub Project Name </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText maxLength={30} className="p-inputtext-sm" id="subProjectName" name="subProjectName" placeholder='Enter Sub Project Name' value={formik.values.subProjectName} onChange={formik.handleChange} type="text" />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("subProjectName")}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-12 lg:col-4 md:col-4">
                                            <div className="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label>Work Order Number </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText maxLength={30} keyfilter={/^[a-zA-Z0-9-]+$/} className="p-inputtext-sm" id="workOrderNumber" name="workOrderNumber" placeholder='Enter Address' value={formik.values.workOrderNumber} onChange={formik.handleChange} type="text" />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("workOrderNumber")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="col-12 lg:col-4 md:col-4">
                                            <div className="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Description </label>
                                                    {/* <span className="Label__Required">*</span> */}
                                                </div>
                                                <InputTextarea maxLength={250} rows={5} className="p-inputtext-sm" id="description" name="description" placeholder='Add Description' value={formik.values.description} onChange={formik.handleChange} type="text" />
                                                {/* <span className='ml-2'>
                                                    {getFormErrorMessage("description")}
                                                </span> */}
                                            </div>
                                        </div>
                                    </div>
                                    {isView ?
                                        null
                                        :
                                        <div className='col-12 d-flex flex-row text-center mt-4 pb-2'>
                                            <Button className="Cancel-Button" label="Reset" type="button" onClick={(e) => ResetForm(e)} />
                                            <Button className="Save-Button ml-2" label={isEditable ? "Update" : "Add"} loading={saveBtnLoading} type="submit" />
                                        </div>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </LoadingOverlay>
        </>
    )
}

export default AddEditSubProject
