import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { BrowserRouter, Routes, Route, useLocation, Switch, useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./components/AppConfig/AppConfig";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "react-loading-overlay";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";
import Login from "./pages/login/Login";
import ManageUsers from "./pages/UserManagement/ManageUsers";
import Settings from "./pages/Settings/Settings";
import Dashboard from "./pages/Dashboard/Dashboard";
import ManageRoles from "./pages/RolesManagement/ManageRoles";
import AddEditRoles from "./pages/RolesManagement/AddEditRoles";
import AssignPermissions from "./pages/RolesManagement/AssignPermissions";
import ManageCategory from "./pages/CategoryManagement/ManageCategory";
import AddEditCategory from "./pages/CategoryManagement/AddEditCategory";
import ManageInventory from "./pages/InventoryManagement/ManageInventory";
import AddEditInventory from "./pages/InventoryManagement/AddEditInventory";
import CheckIn from "./pages/InventoryManagement/CheckIn/CheckIn";
import CheckOut from "./pages/InventoryManagement/CheckOut/CheckOut";
import ManageAsset from "./pages/AssetManagement/ManageAsset";
import AddEditAsset from "./pages/AssetManagement/AddEditAsset";
import CheckInAsset from "./pages/AssetManagement/CheckInAsset/CheckInAsset";
import BorrowAsset from "./pages/AssetManagement/BorrowAsset/BorrowAsset";
import ManageRFID from "./pages/RfidManagement/ManageRFID";
import Reporting from "./pages/Reporting/Reporting";
import ManageWarehouse from "./pages/WarehouseManagement/ManageWarehouse";
import AddEditWarehouse from "./pages/WarehouseManagement/AddEditWarehouse";
import ForgotPassword from "./pages/login/forgetpassword/ForgotPassword";
import ResetPassword from "./pages/login/ResetPassword/ResetPassword";
import EditUsersSuperAdmin from "./pages/UserManagement/EditUsersSuperAdmin";
import ManageVendor from "./pages/vendorManagement/ManageVendor";
import ManageBrand from "./pages/BrandManagement/ManageBrand";
import AddEditVendor from "./pages/vendorManagement/AddEditVendor";
import AddEditSubCategory from "./pages/CategoryManagement/AddEditSubCategory";
import AddEditBrand from "./pages/BrandManagement/AddEditBrand";
import EditUsersWH from "./pages/UserManagement/EditUsersWH";
import { Dialog } from 'primereact/dialog';
import CheckInGrid from "./pages/InventoryManagement/CheckIn/CheckInGrid";
import CheckOutGrid from "./pages/InventoryManagement/CheckOut/CheckOutGrid";
import CheckInAssetGrid from "./pages/AssetManagement/CheckInAsset/CheckInAssetGrid";
import BorrowAssetGrid from "./pages/AssetManagement/BorrowAsset/BorrowAssetGrid";
import AddUsers from "./pages/UserManagement/AddUsers";
import ReplenishedItemReport from "./pages/Reporting/AdminReportingModules/ReplenishedItemReport";
import TotalRegisteredAssets from "./pages/Reporting/AdminReportingModules/TotalRegisteredAssets";
import TotalRegisteredInventory from "./pages/Reporting/AdminReportingModules/TotalRegisteredInventory";
import VendorInventoryList from "./pages/Reporting/AdminReportingModules/VendorInventoryList";
import VendorAssetList from "./pages/Reporting/AdminReportingModules/VendorAssetList";
import AssetBorrowItems from "./pages/Reporting/AdminReportingModules/AssetBorrowItems";
import InventoryValueReport from "./pages/Reporting/AdminReportingModules/InventoryValueReport";
import InventoryQuantityReport from "./pages/Reporting/AdminReportingModules/InventoryQuantityReport";
import InventoryByWarehouse from "./pages/Reporting/SuperAdminReportingModules/InventoryByWarehouse";
import InventoryByUsers from "./pages/Reporting/SuperAdminReportingModules/InventoryByUsers";
import ItemsByValueClient from "./pages/Reporting/SuperAdminReportingModules/ItemsByValueClient";
import VendorsForAssetClient from "./pages/Reporting/SuperAdminReportingModules/VendorsForAssetClient";
import VendorsForInventoryClient from "./pages/Reporting/SuperAdminReportingModules/VendorsForInventoryClient";
import ReplinishedItems from "./pages/Reporting/SuperAdminReportingModules/ReplinishedItems";
import LowLevelList from "./pages/Dashboard/LowLevelList";
import ManageProject from "./pages/projectManagement/ManageProject";
import AddEditProject from "./pages/projectManagement/AddEditProject";
import ManageIndustry from "./pages/IndustryManagement/ManageIndustry";
import AddEditIndustry from "./pages/IndustryManagement/AddEditIndustry";
import AddEditSubProject from "./pages/projectManagement/AddEditSubProject";
import NoPageFound from "./pages/NotFound/NoPageFound";
import { useSelector } from "react-redux";

const App = () => {

    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const [allPermittedRoutes, setAllPermittedRoutes] = useState([]);
    const [dynamicMenu, setDynamicMenu] = useState([]);
    const [countdown, setCountdown] = useState(5);
    const [showPopup, setShowPopup] = useState(false);

    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")
    const login = localStorage.getItem('login')

    const intervalRef = useRef();
    const copyTooltipRef = useRef();
    const location = useLocation();
    const history = useHistory()

    const token = useSelector((state) => state?.logoutSlice?.token);

    useEffect(() => {
        if (!token) {
            history.push('/');
        }
    }, [token]);

    useEffect(() => {
        if (roletypeid) {
            getPermissions();
        }
    }, [roletypeid]);

    PrimeReact.ripple = true;
    let menuClick = false;
    let mobileTopbarMenuClick = false;

    const getPermissions = () => {

        const permissions = localStorage.getItem('permissions');
        if (!permissions) {
            console.log("No permissions found in local storage.");
        }

        const parsedPermissions = JSON.parse(permissions);

        if (parsedPermissions?.length) {
            let menuItems = parsedPermissions && parsedPermissions.map(module => {
                const subMenuItems = module?.submodules && module?.submodules?.length > 0 ? module?.submodules?.map(subModule => ({
                    label: subModule?.contentName,
                    icon: "",
                    to: subModule?.routepath,
                })) : null;

                return {
                    label: module?.contentName,
                    icon: module?.icon_class,
                    to: module?.routepath,
                    items: subMenuItems
                };
            });

            setDynamicMenu([{ items: menuItems }]);

            // Navigate to the first module's route if available
            if (sessionStorage.getItem('firstLogin') === 'true') {
                sessionStorage.removeItem('firstLogin');  // Remove from localStorage
                if (menuItems?.length > 0 && menuItems[0].to) {
                    console.log('menuItems[0]?.to', menuItems[0]?.to)
                    history.push(menuItems[0]?.to);
                }
            }
            return
        }
        else {

            setDynamicMenu([]);
            return
        }
    };

    const isPermittedRoute = (route) => {
        const permittedRoutes = dynamicMenu[0]?.items?.flatMap(item => {
            let mainLink = item?.to ? [item.to] : [];
            let subLinks = item?.items?.map(subItem => subItem?.to).filter(Boolean) || [];
            return mainLink?.concat(subLinks)
        }).filter(Boolean);

        return permittedRoutes?.includes(route)
    }

    useEffect(() => {
        if (dynamicMenu && dynamicMenu?.length > 0) {
            const permittedRoutes = dynamicMenu[0]?.items?.flatMap(item => {
                let mainLink = item?.to ? [item.to] : [];
                let subLinks = item?.items?.map(subItem => subItem?.to).filter(Boolean) || [];
                return mainLink?.concat(subLinks);
            }).filter(Boolean);

            setAllPermittedRoutes(permittedRoutes);
        }
    }, [dynamicMenu]);

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {

        menuClick = true;
        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }
        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event?.item?.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };

    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const sideBarMenu = [
        {
            items: [
                {
                    label: "Dashboard",
                    icon: "custom-dashboard-icon",
                    to: "/Dashboard",
                },
                {
                    label: "User Management",
                    icon: "custom-user-icon",
                    to: "/users",
                },
                {
                    label: "Role Management",
                    icon: "custom-role-icon",
                    to: "/roles",
                },

                {
                    label: "RFID Labelling",
                    icon: "custom-rfid-icon",
                    to: "/Rfid",
                },

                ...((roletypeid == 1 && usertype_id == 0 && !warehouseid)
                    ? [{ label: "Warehouse Management", icon: "custom-warehouse-icon", to: "/warehouse" }]
                    : []),

                {
                    label: "Inventory Management",
                    icon: "custom-inventory-icon",
                    to: "/inventory",
                    items: [
                        { label: "Check In", icon: "", to: "/checkin" },
                        { label: "Check Out", icon: "", to: "/checkout" },
                    ],
                },
                {
                    label: "Asset Management",
                    icon: "custom-asset-icon",
                    to: "/asset",
                    items: [
                        { label: "Check In", icon: "", to: "/checkinasset" },
                        { label: "Borrow", icon: "", to: "/borrowasset" },
                    ],
                },
                {
                    label: "Reporting",
                    icon: "custom-reporting-icon",
                    to: "/reporting",
                },
                {
                    label: "Setups",
                    icon: "custom-setups-icon",
                    to: "/",
                    items: [
                        { label: "Category Setup", icon: "", to: "/category" },
                        { label: "Sub Category Setup", icon: "", to: "/assignsubcategory" },
                        { label: "Brand Setup", icon: "", to: "/brand" },
                        { label: "Vendor Setup", icon: "", to: "/vendor" },
                    ],
                },
            ],
        },
    ];

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    function checkActivity() {
        if (localStorage.getItem("login") === "false" || localStorage.getItem("login") === null) {
            return;
        }

        const lastActive = localStorage.getItem('lastActive');
        const elapsed = Date.now() - lastActive;
        if (elapsed > 1800000) { // 30 minutes in milliseconds
            setShowPopup(true);

            let countdownValue = 5;
            const countdownInterval = setInterval(() => {
                setCountdown(countdownValue);
                countdownValue -= 1;
                if (countdownValue < 0) {
                    clearInterval(countdownInterval);
                    setShowPopup(false);
                    logoutUser();
                }
            }, 1000);
        }
    }

    function logoutUser() {
        localStorage.removeItem('lastActive');
        localStorage.removeItem("permissions");
        localStorage.removeItem("login");
        window.localStorage.clear();
        window.sessionStorage.clear();
        localStorage.clear();
        history.push("/");
        clearInterval(intervalRef.current);
    }

    // Set up an interval to run the checkActivity function every 30 minutes
    useEffect(() => {
        intervalRef.current = setInterval(checkActivity, 1800000);
        return () => clearInterval(intervalRef.current);
    }, []);

    // Update the user's last active time whenever they interact with the page
    useEffect(() => {
        if (login) {
            function handleActivity() {
                localStorage.setItem('lastActive', Date.now());
            }

            window.addEventListener('click', handleActivity);
            window.addEventListener('keydown', handleActivity);
            window.addEventListener('mousemove', handleActivity);
            window.addEventListener('mousedown', handleActivity);
            window.addEventListener('scroll', handleActivity);
            window.addEventListener('keypress', handleActivity);

            return () => {
                window.removeEventListener('click', handleActivity);
                window.removeEventListener('keydown', handleActivity);
                window.removeEventListener('mousemove', handleActivity);
                window.removeEventListener('mousedown', handleActivity);
                window.removeEventListener('scroll', handleActivity);
                window.removeEventListener('keypress', handleActivity);
            };
        }
    }, [login]);

    useEffect(() => {
        if (!login || login === null) {
            localStorage.removeItem('lastActive');
        }
    }, [login]);

    LoadingOverlay.propTypes = undefined;

    return (

        <div className={wrapperClass} onClick={onWrapperClick}>

            {showPopup && (
                <Dialog header="Logging Out" visible={showPopup} modal onHide={() => { }}>
                    <p>Due to inactivity, the system is logging out in {countdown} seconds...</p>
                </Dialog>
            )}

            {/* <LoadingOverlay active={isLoading} spinner text="Loading ..."> */}
            <ToastContainer />
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            {(localStorage.getItem("login") === null || localStorage.getItem("login") === undefined || !localStorage.getItem("login")) ?
                (
                    <>
                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Route exact path="/forgotPassword" component={ForgotPassword} />
                            <Route path="/reset-password" component={ResetPassword} />
                        </Switch>
                    </>
                ) : (
                    <>
                        <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />
                        <div className="layout-sidebar" onClick={onMenuItemClick}>
                            {/* {dynamicMenu?.length === 0 ? (
                                <div className="loader-container">
                                    <div className="loading-text">Loading your content...</div>
                                    <div className="loader"></div>
                                </div>
                            ) : ( */}
                            <AppMenu model={dynamicMenu}
                                onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                            {/* )} */}
                        </div>

                        <div className="layout-main-container innr-Body">
                            <div className="layout-main">
                                <Switch>

                                    {/* Dashboard */}
                                    <Route exact path="/dashboard" render={() => (isPermittedRoute('/dashboard',) ? <Dashboard /> : <NoPageFound />)} />
                                    <Route exact path="/lowlevellist" render={() => (isPermittedRoute('/dashboard') ? <LowLevelList /> : <NoPageFound />)} />
                                    <Route exact path="/settings" component={Settings} />

                                    {/* User */}
                                    <Route exact path="/users" render={() => (isPermittedRoute('/users') ? <ManageUsers /> : <NoPageFound />)} />
                                    <Route exact path="/addusers" render={() => (isPermittedRoute('/users') ? <AddUsers /> : <NoPageFound />)} />
                                    <Route exact path="/edituser" render={() => (isPermittedRoute('/users') ? <EditUsersSuperAdmin /> : <NoPageFound />)} />
                                    <Route exact path="/edituseradmin" render={() => (isPermittedRoute('/users') ? <EditUsersWH /> : <NoPageFound />)} />

                                    {/* Role */}
                                    <Route exact path="/roles" render={() => (isPermittedRoute('/roles') ? <ManageRoles /> : <NoPageFound />)} />
                                    <Route exact path="/addeditroles" render={() => (isPermittedRoute('/roles') ? <AddEditRoles /> : <NoPageFound />)} />
                                    <Route exact path="/assignpermissions" render={() => (isPermittedRoute('/roles') ? <AssignPermissions /> : <NoPageFound />)} />

                                    {/* Inventory */}
                                    <Route exact path="/inventory" render={() => (isPermittedRoute('/inventory') ? <ManageInventory /> : <NoPageFound />)} />
                                    <Route exact path="/addeditinventory" render={() => (isPermittedRoute('/inventory') ? <AddEditInventory /> : <NoPageFound />)} />
                                    <Route exact path="/checkin" render={() => (isPermittedRoute('/checkin') ? <CheckInGrid /> : <NoPageFound />)} />
                                    <Route exact path="/checkinaddedit" render={() => (isPermittedRoute('/checkin') ? <CheckIn /> : <NoPageFound />)} />
                                    <Route exact path="/checkout" render={() => (isPermittedRoute('/checkout') ? <CheckOutGrid /> : <NoPageFound />)} />
                                    <Route exact path="/checkoutaddedit" render={() => (isPermittedRoute('/checkout') ? <CheckOut /> : <NoPageFound />)} />

                                    {/* Asset */}
                                    <Route exact path="/asset" render={() => (isPermittedRoute('/asset') ? <ManageAsset /> : <NoPageFound />)} />
                                    <Route exact path="/addeditasset" render={() => (isPermittedRoute('/asset') ? <AddEditAsset /> : <NoPageFound />)} />
                                    <Route exact path="/checkinasset" render={() => (isPermittedRoute('/checkinasset') ? <CheckInAssetGrid /> : <NoPageFound />)} />
                                    <Route exact path="/checkinassetaddedit" render={() => (isPermittedRoute('/checkinasset') ? <CheckInAsset /> : <NoPageFound />)} />
                                    <Route exact path="/borrowasset" render={() => (isPermittedRoute('/borrowasset') ? <BorrowAssetGrid /> : <NoPageFound />)} />
                                    <Route exact path="/borrowassetaddedit" render={() => (isPermittedRoute('/borrowasset') ? <BorrowAsset /> : <NoPageFound />)} />

                                    {/* Setups */}
                                    <Route exact path="/brand" render={() => (isPermittedRoute('/brand') ? <ManageBrand /> : <NoPageFound />)} />
                                    <Route exact path="/addeditbrand" render={() => (isPermittedRoute('/brand') ? <AddEditBrand /> : <NoPageFound />)} />
                                    <Route exact path="/vendor" render={() => (isPermittedRoute('/vendor') ? <ManageVendor /> : <NoPageFound />)} />
                                    <Route exact path="/addeditvendor" render={() => (isPermittedRoute('/vendor') ? <AddEditVendor /> : <NoPageFound />)} />
                                    <Route exact path="/category" render={() => (isPermittedRoute('/category') ? <ManageCategory /> : <NoPageFound />)} />
                                    <Route exact path="/addeditcategory" render={() => (isPermittedRoute('/category') ? <AddEditCategory /> : <NoPageFound />)} />
                                    <Route exact path="/addeditsubcategory" render={() => (isPermittedRoute('/category') ? <AddEditSubCategory /> : <NoPageFound />)} />

                                    {/* Warehouse */}
                                    <Route exact path="/rfid" render={() => (isPermittedRoute('/rfid') ? <ManageRFID /> : <NoPageFound />)} />
                                    <Route exact path="/warehouse" render={() => (isPermittedRoute('/warehouse') ? <ManageWarehouse /> : <NoPageFound />)} />
                                    <Route exact path="/addeditwarehouse" render={() => (isPermittedRoute('/warehouse') ? <AddEditWarehouse /> : <NoPageFound />)} />

                                    {/* Industry */}
                                    {/* <Route exact path="/industry" render={()=> (isPermittedRoute('/industry') ? <ManageIndustry/> :<NoPageFound/> )} /> */}
                                    {/* <Route exact path="/addeditindustry" render={()=> (isPermittedRoute('/industry') ? <AddEditIndustry/> :<NoPageFound/> )} /> */}

                                    {/* Project */}
                                    {/* <Route exact path="/project" render={()=> (isPermittedRoute('/project') ? <ManageProject/> :<NoPageFound/> )} />
                                    <Route exact path="/addeditproject" render={()=> (isPermittedRoute('/project') ? <AddEditProject/> :<NoPageFound/> )} />
                                    <Route exact path="/addeditsubproject" render={()=> (isPermittedRoute('/project') ? <AddEditSubProject/> :<NoPageFound/> )} /> */}

                                    <Route exact path="/reporting" render={() => (isPermittedRoute('/reporting') ? <Reporting /> : <NoPageFound />)} />

                                    {(roletypeid == 1 && ["/report-warehouseinv", "/report-usersinv", "/report-usersinvvalue", "/report-vendorinvvalue", "/report-vendorassetvalue", "/report-replinisheditems"]?.includes(location?.pathname)) || (roletypeid == 2 && usertype_id == 1 && !warehouseid && ["/report-warehouseinv", "/report-usersinv", "/report-usersinvvalue", "/report-vendorinvvalue", "/report-vendorassetvalue", "/report-replinisheditems"]?.includes(location?.pathname)) ?
                                        (<>
                                            {/* Reports Super Admin */}
                                            <Route exact path="/report-warehouseinv" render={() => (isPermittedRoute('/reporting') ? <InventoryByWarehouse /> : <NoPageFound />)} />
                                            <Route exact path="/report-usersinv" render={() => (isPermittedRoute('/reporting') ? <InventoryByUsers /> : <NoPageFound />)} />
                                            <Route exact path="/report-usersinvvalue" render={() => (isPermittedRoute('/reporting') ? <ItemsByValueClient /> : <NoPageFound />)} />
                                            <Route exact path="/report-vendorinvvalue" render={() => (isPermittedRoute('/reporting') ? <VendorsForInventoryClient /> : <NoPageFound />)} />
                                            <Route exact path="/report-vendorassetvalue" render={() => (isPermittedRoute('/reporting') ? <VendorsForAssetClient /> : <NoPageFound />)} />
                                            <Route exact path="/report-replinisheditems" render={() => (isPermittedRoute('/reporting') ? <ReplinishedItems /> : <NoPageFound />)} />
                                        </>)
                                        : (roletypeid == 2 && usertype_id == 2 && warehouseid && ["/inventoryquantityreport", "/replenisheditemreport", "/inventoryvaluereport", "/report-registeredassets", "/report-registeredinventory", "/report-vendorinventory", "/report-vendorasset", "/report-vendorasset", "/report-borrowitems"]?.includes(location?.pathname) || roletypeid == 2 && usertype_id == 1 && warehouseid && ["/inventoryquantityreport", "/replenisheditemreport", "/inventoryvaluereport", "/report-registeredassets", "/report-registeredinventory", "/report-vendorinventory", "/report-vendorasset", "/report-vendorasset", "/report-borrowitems"]?.includes(location?.pathname))
                                            ?
                                            (<>
                                                {/* Reports Admin */}
                                                <Route exact path="/inventoryquantityreport" render={() => (isPermittedRoute('/reporting') ? <InventoryQuantityReport /> : <NoPageFound />)} />
                                                <Route exact path="/replenisheditemreport" render={() => (isPermittedRoute('/reporting') ? <ReplenishedItemReport /> : <NoPageFound />)} />
                                                <Route exact path="/inventoryvaluereport" render={() => (isPermittedRoute('/reporting') ? <InventoryValueReport /> : <NoPageFound />)} />
                                                <Route exact path="/report-registeredassets" render={() => (isPermittedRoute('/reporting') ? <TotalRegisteredAssets /> : <NoPageFound />)} />
                                                <Route exact path="/report-registeredinventory" render={() => (isPermittedRoute('/reporting') ? <TotalRegisteredInventory /> : <NoPageFound />)} />
                                                <Route exact path="/report-vendorinventory" render={() => (isPermittedRoute('/reporting') ? <VendorInventoryList /> : <NoPageFound />)} />
                                                <Route exact path="/report-vendorasset" render={() => (isPermittedRoute('/reporting') ? <VendorAssetList /> : <NoPageFound />)} />
                                                <Route exact path="/report-borrowitems" render={() => (isPermittedRoute('/reporting') ? <AssetBorrowItems /> : <NoPageFound />)} />
                                            </>)
                                            :
                                            <>
                                                <Route path="*" component={NoPageFound} />
                                            </>
                                    }
                                </Switch>
                            </div>
                            {/* <AppFooter layoutColorMode={layoutColorMode} /> */}
                        </div>
                    </>
                )
            }

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
            {/* </LoadingOverlay> */}
        </div>
    );
};


export default App;
