import { baseURL } from "../Config";
import axios from "axios";
import { toast } from "react-toastify";
import { loadingAction } from "../redux/actions/loadingAction";
import { logout } from "../redux/slices/logoutSlice";

export const handlePostRequest =
    (data, url, isShowLoad = false, isShowToast = false) =>
    async (dispatch) => {
        // data = await trimData(data);
        try {
            if (isShowLoad) dispatch(loadingAction(true));
            const response = await axios({
                method: "post",
                url: `${baseURL + url}`,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    Authorization:  `Bearer ${localStorage.getItem("token")}`,  
                },
            });
            if (isShowToast) toast.success(response?.data?.body || response?.data?.statusMessage || response?.data?.message);
            // if (isShowLoad) dispatch(loadingAction(false));
            return response?.data;
        } catch (error) {
            // if (isShowLoad) dispatch(loadingAction(false));
            if (error?.response?.data?.StatusCode == '403' && error?.response?.status == 401) {
                toast.warn(error?.response?.data?.StatusMessage || 'Authentication Failed ! Please login again');
                dispatch(logout());
            }
            else if (error?.response?.status === 400 || error?.response?.status === 500) {
                toast.warn(error?.response?.data?.statusMessage || "Something went wrong !!");
            } else {
                toast.warn(error?.response?.data?.statusMessage || "Something went wrong !!");
            }
            return error?.response;
        }
        
    };
