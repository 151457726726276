import React from 'react'
import printing from '../../assets/warehouse-assets/warehouse.png'
import qrcode from '../../assets/warehouse-assets/qr-code.png'
import { Button } from 'primereact/button'

const ManageRFID = () => {
    return (
        <div className='card-form mt-3'>
    
            <div class="grid flex flex-row">
                <div class="col-12 lg:col-4 md:col-4">
                    <div className='p-3 flex flex-column ml-3 mt-3'>
                        <img src={printing} style={{ width: '200px', height: '200px', borderRadius: "50%" }} />
                        <h2> RFID Label Printing </h2>
                        <h6 style={{ color: 'gray' }}> Printing Label for New Package </h6>
                    </div>
                    <div className='text-left mt-0 p-2 ml-6'>
                        <Button className="Generate-Button" label="Generate Label" type="submit" />
                    </div>
                </div>

                <div class="col-12 lg:col-8 md:col-8">
                    <div className='p-3 justify-content-center flex'>
                        <img src={qrcode}  className='rfid-qr-code'/>
                    </div>
                    <div className='col-12 d-flex flex-row text-center pb-2'>
                        <Button className="Cancel-Button" label="Cancel" type="button" onClick='' />
                        <Button className="Save-Button ml-4" label="Print" loading='' type="submit" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageRFID
