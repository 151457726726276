import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import LoadingOverlay from 'react-loading-overlay';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { handleGetRequest } from '../../../services/GetTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handlePutRequest } from '../../../services/PutTemplate';
import { useHistory, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { InputTextarea } from 'primereact/inputtextarea';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from "primereact/toast";
import { handleDeleteRequest } from '../../../services/DeleteTemplate';
import { SplitButton } from 'primereact/splitbutton';
import { setCustomerData } from '../../../redux/slices/checkoutUserSlice';
import { userAction } from '../../../redux/actions/checkoutAction';
import { Triangle } from 'react-loader-spinner';

const CheckOut = () => {

    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")

    const location = useLocation();
    const { isEditable, rowData } = location.state;

    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [permissions, setPermissions] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const showToast = useRef(null)
    const history = useHistory()
    const dispatch = useDispatch()
    const [allInventoryName, setAllInventoryName] = useState()
    const [showData, setshowData] = useState(false)
    const [totalQuantity, setTotalQuantity] = useState(0)

    const [allCheckOutData, setAllCheckOutData] = useState([]);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value.toLowerCase();
        setGlobalFilterValue(value);
    };

    const header = () => (
        <div className='flex justify-content-between align-items-center '>
            <h5 className='mb-0' style={{ fontStyle: "italic" }}> CheckOut Data List </h5>
            <div className='flex justify-content-between align-items-center '>
                <Button className=' desaturated_blue px-5 mr-3' type='buttons' disabled={!allCheckOutData?.length > 0} label='Clear All Data' onClick={(e) => deleteDialog(e)} />
                <InputText placeholder='Search' value={globalFilterValue} onChange={onGlobalFilterChange} />
            </div>
        </div>
    )

    const deleteDialog = (e) => {
        confirmDialog({
            message: 'Are you sure you want to clear all data?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleAllDeleteData(e),
            reject: null
        });
    }

    const handleAllDeleteData = async (e) => {
        e.preventDefault();
        let obj = {
            uid: user_id,
            wid: warehouseid
        }
        setIsActive(true);
        const response = await dispatch(handleDeleteRequest(obj, `/api/Inventory/DeleteStageCheckOut`, true, true));
        if (response?.statusCode == "200") {
            getAllInventoryData(false)
        }
        setIsActive(false);
    }

    const actionTemplate = (rowData) => {
        const items = [
            {
                label: 'Delete',
                command: () => confirm(rowData),
            },
        ];
        return (
            <div className="">
                <SplitButton label="" model={items} />
            </div>
        );
    };

    const confirm = (rowData) => {
        confirmDialog({
            message: 'Are you sure you want to Delete this checkout record?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleDeleteRecord(rowData),
            reject: null
        });
    }

    const handleDeleteRecord = async (rowData) => {
        // e.preventDefault();
        setIsActive(true);
        let obj = {
            p_id: rowData?.id
        }


        const response = await dispatch(handleDeleteRequest(obj, `/api/Inventory/DeleteStageCheckOutById`, true, true));
        if (response?.statusCode === "200") {
            getAllInventoryData(false)
        }
        setIsActive(false);
    }

    const validationSchema = Yup.object().shape({
        checkbox: Yup.string().required("This field is required.").nullable(),
        inventoryName: Yup.string().required("This field is required.").nullable(),
        rfidCode: Yup.string().required("This field is required.").nullable(),
        costPerUnit: Yup.string().required("This field is required.").nullable(),
        quantity: Yup.number().required("This field is required.").nullable(),
    });

    const formik = useFormik({

        validationSchema: validationSchema,
        initialValues: {
            checkbox: "",
            inventoryName: "",
            rfidCode: "",
            costPerUnit: "",
            quantity: "",
            addNotes: "",
        },

        onSubmit: async (data) => {

            setIsActive(true)
            let payload = {
                p_inventory_Type: data?.checkbox,
                p_inventory_id: data?.inventoryName,
                p_rfid: data?.rfidCode,
                p_cost: data?.costPerUnit,
                p_Notes: data?.addNotes,
                p_quantity: data?.quantity,
                p_roletypeid: roletypeid,
                p_usertypeid: usertype_id,
                p_warehouseid: warehouseid,
                p_createdby: user_id,
            }

            setshowData(true)
            dispatch(userAction(payload))

            const response = await dispatch(handlePostRequest(payload, '/api/Inventory/AddStageCheckOut'))
            setIsActive(false)
            if (response?.statusCode == "200") {
                getAllInventoryData(false)
                setTotalQuantity(0)
                ResetForm();
            }

        },
    });

    const ResetForm = () => {
        formik.resetForm();
        setTotalQuantity(0)
        formik.setFieldValue('addNotes', '')
        formik.setFieldValue('rfidCode', '')
        formik.setFieldValue('checkbox', '')
        formik.setFieldValue('inventoryName', '')
        formik.setFieldValue('costPerUnit', '')
        formik.setFieldValue('quantity', '')
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getInventoryLOVs = async () => {
        const response = await dispatch(handleGetRequest(`/api/Inventory/GetInventory?warehouse_id=${warehouseid}`))
        if (response) {
            setAllInventoryName(response)
        }
    }

    const getAllInventoryData = async (showAlert = true) => {
        setIsActive(true)
        const response = await dispatch(handleGetRequest(`/api/Inventory/GetStageCheckOutList?warehouseid=${warehouseid}&userid=${user_id}`))
        if (response) {
            if (response.length >= 1 && showAlert) {
                toast.info('Your unsaved checkout data has been restored ')
                setAllCheckOutData(response)
            } else {
                setAllCheckOutData(response)
            }
        }
        setIsActive(false)
    }

    useEffect(() => {
        const myPermissions = JSON.parse(localStorage.getItem("permissions"))
        const allPermModules = myPermissions?.find(module => module?.routepath?.includes("/inventory"));
        if (allPermModules) {
            const result = allPermModules?.submodules?.find(item => item?.routepath == "/checkout")
            setPermissions(result?.permissions?.map(item => item?.permission_id) || []);
        }

        if (warehouseid) {
            getInventoryLOVs();
            getAllInventoryData()
        }

    }, []);

    const handleCheckOutData = async () => {
        setIsActive(true)
        let obj = {
            uid: user_id,
            wid: warehouseid
        }
        const response = await dispatch(handlePostRequest(obj, "/api/Inventory/AddSubmitCheckOut", true, true));
        setIsActive(false)
        if (response?.statusCode == '200') {
            getAllInventoryData(false)
            ResetForm()
            history.push('./checkout')
        }
        ResetForm()
    }

    const handleGenerateData = async () => {

        setIsActive(true)
        const response = await dispatch(handleGetRequest(`/api/Inventory/GetCheckinByrfid?obj_id=${formik.values.rfidCode}`, true, true));
        const keyData = response[0]
        if (keyData === undefined) {
            toast.warn('No record found with provided RFID code')
            formik.setFieldValue('checkbox', '')
            formik.setFieldValue('inventoryName', '')
            formik.setFieldValue('costPerUnit', '')
        }
        else if (keyData?.quantity === 0) {
            toast.warn('Quantity has already been exceeded')
            formik.setFieldValue('checkbox', '')
            formik.setFieldValue('inventoryName', '')
            formik.setFieldValue('costPerUnit', '')
        }

        else if (keyData) {
            setTotalQuantity(keyData?.quantity)
            formik.setFieldValue('checkbox', keyData?.inventory_Type)
            formik.setFieldValue('inventoryName', keyData?.inventory_id)
            formik.setFieldValue('costPerUnit', keyData?.cost)
        }
        else {
            formik.setFieldValue('checkbox', '')
            formik.setFieldValue('inventoryName', '')
            formik.setFieldValue('costPerUnit', '')
        }
        setIsActive(false)
    }

    const getCheckOutLisyByIdEditable = async (rowData) => {

        const response = await dispatch(handleGetRequest(`/api/Inventory/GetCheckoutByid?obj_id=${rowData?.id}`));
        const keyData = response[0];
        if (keyData) {
            formik.setFieldValue("rfidCode", rowData?.rfid)
            formik.setFieldValue("inventoryName", rowData?.inventory_id)
            formik.setFieldValue("costPerUnit", rowData?.cost)
            formik.setFieldValue("addNotes", rowData?.notes)
            formik.setFieldValue("checkbox", rowData?.inventory_Type)
        }
    }

    useEffect(() => {
        if (rowData && isEditable) {
            getCheckOutLisyByIdEditable(rowData)
        }

    }, [rowData, isEditable])

    return (

        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgb(38 41 51 / 78%)',
                        width: '107.9%',
                        height: '125%',
                        top: '-27px',
                        left: '-35px'
                    })
                }}
            >
                <Toast ref={showToast} position="bottom-center" />

                <div className='main-form-card'>
                    <div className='form-header flex flex-row justify-content-between'>
                        <div className='form-header-content'>
                            <h4 style={{ fontWeight: '600', letterSpacing: '1px', color: 'black' }}> Check Out</h4>
                            <h6 style={{ color: 'gray' }}>{isEditable ? 'Edit' : 'Add'} Details</h6>
                        </div>
                        <div className='mt-4 mr-3'>
                            <Button className='Back-Button mr-3' icon="pi pi-arrow-left" label='Go Back' onClick={() => history.goBack()}>
                            </Button>
                        </div>
                    </div>
                    <div className='form-div'>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="card-form">
                                <div className="card-nested-form">
                                    <div className="grid">
                                        <div className="col-12 lg:col-4 md:col-4">
                                            <div className="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> RFID Entry Code </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <div style={{ position: 'relative' }}>
                                                    <InputText
                                                        type='text'
                                                        placeholder='Add RFID Code'
                                                        id='rfidCode'
                                                        name="rfidCode"
                                                        value={formik.values.rfidCode}
                                                        onChange={formik.handleChange}
                                                        className="p-inputtext-sm"
                                                        autoComplete="off"
                                                    />
                                                    <Button
                                                        disabled={formik.values.rfidCode ? false : true}
                                                        tooltip='Search via RFID Code'
                                                        tooltipOptions={{ position: "top" }}
                                                        type="button"
                                                        icon="pi pi-search"
                                                        className='auto-generate-button'
                                                        onClick={handleGenerateData}
                                                        style={{
                                                            background: '#8d7ad5',
                                                            borderColor: "#8d7ad5",
                                                            position: 'absolute',
                                                            top: '30%',
                                                            right: '8px',
                                                            transform: 'translateY(-50%)',
                                                        }}
                                                    />
                                                </div>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("rfidCode")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 lg:col-4 md:col-4">
                                            <div className="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label>Inventory Name</label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <Dropdown
                                                    disabled
                                                    placeholder='--Select Inventory--'
                                                    id="inventoryName"
                                                    name="inventoryName"
                                                    options={allInventoryName}
                                                    optionLabel='name'
                                                    optionValue='id'
                                                    value={formik?.values?.inventoryName}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                    autoComplete="off"
                                                ></Dropdown>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("inventoryName")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 lg:col-4 md:col-4 align-content-center ">
                                            <div className='flex align-items-center'>
                                                <RadioButton disabled value={1} name="checkbox" onChange={formik.handleChange} checked={formik?.values?.checkbox === 1} />
                                                <label className='ml-2 mr-4'>Complete Boxes</label>
                                                <RadioButton disabled value={2} name="checkbox" onChange={formik.handleChange} checked={formik?.values?.checkbox === 2} />
                                                <label className='ml-2'>Loose Pieces</label>
                                            </div>
                                            <div className='ml-2 mt-2'>
                                                {getFormErrorMessage("checkbox")}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mb-4' style={{ borderBottom: "1px solid #8d7ad5" }}> </div>

                                    <div className="grid">
                                        <div className="col-12 lg:col-4 md:col-4">
                                            <div className="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Cost </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText disabled className="p-inputtext-sm" id="costPerUnit" name="costPerUnit" placeholder='Enter Cost' value={formik.values.costPerUnit} onChange={formik.handleChange} type="text" />
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("costPerUnit")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 lg:col-4 md:col-4">
                                            <div className="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label>Quantity</label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText
                                                    disabled={formik.values.inventoryName === '' ? true : false} min={1} maxLength={10} max={totalQuantity} className="p-inputtext-sm" id="quantity" name="quantity" placeholder='Enter Quantity ' value={formik.values.quantity} onChange={(e) => {
                                                        const { value } = e.target;
                                                        if (value <= totalQuantity) {
                                                            formik.handleChange(e);
                                                        }
                                                    }}
                                                    type="number"
                                                />
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("quantity")}
                                                </div>
                                                {totalQuantity > 0
                                                    ?
                                                    <small style={{ color: "red", fontStyle: "italic" }} className='ml-2'>Available quantity: {totalQuantity}</small>
                                                    :
                                                    null
                                                }
                                            </div>

                                        </div>
                                    </div>

                                    <div className="grid">
                                        <div className="col-12 lg:col-4 md:col-4">
                                            <div className="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Notes </label>
                                                </div>
                                                <InputTextarea rows={4} maxLength={150} className="p-inputtext-sm" id="addNotes" name="addNotes" placeholder='Add Notes' value={formik.values.addNotes} onChange={formik.handleChange} type="text" />
                                            </div>
                                        </div>
                                    </div>

                                    {permissions.includes(2) ?
                                        <div className='col-12 d-flex flex-row text-center mt-4 pb-2'>
                                            <Button className="Cancel-Button" label="Reset" type="button" onClick={ResetForm} />
                                            <Button className="Save-Button ml-2" label={isEditable ? "Update" : "Check Out"} loading={saveBtnLoading} type="submit" />
                                        </div>
                                        :
                                        null
                                    }

                                </div>
                            </div>

                            <div className="card mt-3">
                               
                                    <DataTable
                                        responsive={true}
                                        globalFilter={globalFilterValue}
                                        header={header}
                                        responsiveLayout="scroll"
                                        stripedRows
                                        paginator
                                        rows={20}
                                        paginatorClassName="custom-paginator"
                                        value={allCheckOutData}
                                        emptyMessage="No records found"
                                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                        rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                    >
                                        <Column field="inventory_name" header="Product Name"></Column>
                                        <Column field="rfid" header="RFID Code"></Column>
                                        <Column field="category_name" header="Category"></Column>
                                        {/* <Column field='subcategory_name' body={(rowData) => rowData?.subcategory_name || 'N/A'} header="Sub Category"></Column> */}
                                        <Column field="brand_name" header="Brand"></Column>
                                        <Column field="vendoR_NAME" header="Vendor"></Column>
                                        {/* <Column field="salesDescription" header="Description"></Column> */}
                                        <Column field='unit' body={(rowData) => rowData?.unit || 'N/A'} header="Unit"></Column>
                                        <Column field="quantity" header="Quantity"></Column>
                                        <Column field="min_t_value" header="Min Threshold Value"></Column>
                                        <Column field="cost" header="Cost"></Column>
                                        {/* <Column field="expiry_Date" header="Check Out"></Column> */}
                                        <Column header="Action" body={actionTemplate} />
                                    </DataTable>
                            </div>
                        </form>
                        <Button className='block mx-auto Save-Button' disabled={!allCheckOutData?.length > 0} type='button' label='Submit' onClick={handleCheckOutData} />
                    </div>
                </div>

            </LoadingOverlay>
        </>
    )
}

export default CheckOut














