import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import { Ripple } from "primereact/ripple";
import { Badge } from "primereact/badge";

const AppSubmenu = (props) => {
    const [activeIndex, setActiveIndex] = useState(null);
    const location = useLocation();

    const onMenuItemClick = (event, item, index) => {
        if (item?.disabled) {
            event.preventDefault();
            return true;
        }

        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }

        if (index === activeIndex) setActiveIndex(null);
        else setActiveIndex(index);

        if (props?.onMenuItemClick) {
            props.onMenuItemClick({
                originalEvent: event,
                item: item,
            });
        }
    };

    const onKeyDown = (event) => {
        if (event?.code === "Enter" || event?.code === "Space") {
            event.preventDefault();
            event.target.click();
        }
    };

    const renderLinkContent = (item) => {
        let submenuIcon = item?.items && item?.items?.length > 0 && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>;
        let badge = item?.badge && <Badge value={item?.badge} />;

        return (
            <>
                <div style={{ display: "block", width: "2rem", height: "1.5rem" }}>
                    <i className={item?.icon}></i>
                </div>
                <span>{item?.label}</span>
                {submenuIcon}
                {badge}
                <Ripple />
            </>
        );
    };

    const renderLink = (item, i) => {
        let content = renderLinkContent(item);

        let className = classNames("p-ripple", {
            "router-link-active router-link-exact-active": item?.to && location.pathname === item?.to,
            "app-menuitem-logout": item?.label === 'Logout'
        });

        if (item?.to) {
            return (
                <NavLink
                    aria-label={item?.label}
                    onKeyDown={onKeyDown}
                    role="menuitem"
                    className={className}
                    to={item.to}
                    onClick={(e) => onMenuItemClick(e, item, i)}
                    exact
                    target={item?.target}
                >
                    {content}
                </NavLink>
            );
        } else if (item?.command) {
            return (
                <a
                    tabIndex="0"
                    aria-label={item?.label}
                    onKeyDown={onKeyDown}
                    role="menuitem"
                    className={className}
                    onClick={(e) => {
                        item.command({ originalEvent: e, item });
                        onMenuItemClick(e, item, i);
                    }}
                    href="#"
                    style={{ cursor: 'pointer' }}
                >
                    {content}
                </a>
            );
        } else if (item?.to === "") {
            return (
                <a
                    aria-label={item?.label}
                    onKeyDown={onKeyDown}
                    role="menuitem"
                    className={className}
                    to={item.to}
                    onClick={(e) => onMenuItemClick(e, item, i)}
                    exact
                    target={item?.target}
                >
                    {content}
                </a>
            )
        } else {
            return (
                <li className={className}>
                    {item.label !== null ? <> {content} </> : null}
                </li>
            );
        }
    };

    let items =
        props?.items &&
        props?.items.map((item, i) => {
            let active = activeIndex === i;
            let styleClass = classNames(item?.badgeStyleClass, { "layout-menuitem-category": props?.root, "active-menuitem": active && !item?.to });

            if (props?.root) {
                return (
                    <li className={styleClass} key={i} role="none">
                        {props?.root === true && (
                            <React.Fragment>
                                <div className="layout-menuitem-root-text" aria-label={item?.label}>
                                    {item?.label}
                                </div>
                                <AppSubmenu items={item?.items} onMenuItemClick={props?.onMenuItemClick} />
                            </React.Fragment>
                        )}
                    </li>
                );
            } else {
                return (
                    <li className={styleClass} key={i} role="none">
                        {renderLink(item, i)}
                        <CSSTransition classNames="layout-submenu-wrapper" timeout={{ enter: 1000, exit: 450 }} in={active} unmountOnExit>
                            <AppSubmenu items={item?.items} onMenuItemClick={props?.onMenuItemClick} />
                        </CSSTransition>
                    </li>
                );
            }
        });

    return items ? (
        <ul className={props?.className} role="menu">
            {items}
        </ul>
    ) : null;
};

export const AppMenu = (props) => {
    return (
        <div className="layout-menu-container">
            <AppSubmenu items={props?.model} className="layout-menu" onMenuItemClick={props?.onMenuItemClick} root={true} role="menu" />
        </div>
    );
};
