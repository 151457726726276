import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import loginImage from "../../../assets/warehouse-assets/warehouse.png"
import { handlePostRequest } from "../../../services/PostTemplate";
import { handleAuthRequest } from "../../../services/Auth";

const ForgotPassword = () => {

    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    const validationSchema = Yup.object().shape({
        email: Yup.mixed().required("This field is required."),
    });

    const formik = useFormik({

        validationSchema: validationSchema,
        initialValues: {
            email: "",
        },

        onSubmit: async (data) => {
            console.log(data)
            setSaveBtnLoading(true);
            let obj = {
                email: data?.email,
            }
            const response = await dispatch(handleAuthRequest(obj, "/api/Authentication/ForgotPasswordEmail", true, true));
            console.log("res", response)
            if (response?.statusCode == 200) {
                setSaveBtnLoading(false)
                }
                else {
                setSaveBtnLoading(false)
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

   
   

    return (

        <>
            <div className="login-page-container">
                <div className="left-section">
                    <img src={loginImage} alt="Image Alt Text" className="imgMainLogo" />
                </div>

                <div className="right-section mb-6">
                    <form onSubmit={formik.handleSubmit} className="login-form">
                        <div className="text-center justify-content-center">
                            <h1 className="mb-6" style={{ color: "black", letterSpacing: "1px", fontWeight: "700", color: "#6a4ed1" }}> WareHouse <span style={{ color: 'black' }} >  MS </span> </h1>
                            <h3 className="mt-7" style={{ color: "black", letterSpacing: "1px", fontWeight: "600" }}> Forgot Your Password </h3>
                            <h6 className="mb-6" style={{ color: "black", letterSpacing: "1px", fontWeight: "400", color: "#8c8c8c" }}> No Worries! Just enter your email and we'll assist you. </h6>
                        </div>

                        <div className="login-div mt-6">
                            <div class="input-card-login flex-column p-3">
                                <div className='flex-row'>
                                    <label> Email </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText className="p-inputtext-sm" id="email" name="email" placeholder='Enter your Email' value={formik.values.email} onChange={formik.handleChange} type="email" />
                                <span className="ml-2">
                                    {getFormErrorMessage("email")}
                                </span>
                            </div>
                            
                            <div className='col-12 d-flex flex-row text-center mt-5'>
                                <Button className="Login-Button" label="Send Link"  loading={saveBtnLoading} type="submit" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>

    );
};

export default ForgotPassword;
