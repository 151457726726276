import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import LoadingOverlay from 'react-loading-overlay';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { handleGetRequest } from '../../../services/GetTemplate';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handlePutRequest } from '../../../services/PutTemplate';
import { useHistory, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { FileUpload } from 'primereact/fileupload';
import photo from '../../../assets/warehouse-assets/photo.png'
import video from '../../../assets/warehouse-assets/video.png'
import { InputTextarea } from 'primereact/inputtextarea';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RadioButton } from 'primereact/radiobutton';
import { TreeSelect } from 'primereact/treeselect';
import { Calendar } from 'primereact/calendar';
import { Toast } from "primereact/toast";
import { handleDeleteRequest } from '../../../services/DeleteTemplate';
import { SplitButton } from 'primereact/splitbutton';
import moment from 'moment';
import { baseURL } from '../../../Config';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import Webcam from 'react-webcam';
import { Image } from 'primereact/image';
import { Triangle } from 'react-loader-spinner';

const CheckIn = () => {

    const location = useLocation();
    const { isEditable, rowData, isView, expireItemData } = location.state;
    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")

    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState([])
    const [selectedFiles2, setSelectedFiles2] = useState([])
    const [selectedDoc, setSelectedDoc] = useState({})
    const [permissions, setPermissions] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const showToast = useRef(null)
    const uploadDoc = useRef()
    const uploadImg = useRef()
    const uploadVideo = useRef()
    const history = useHistory()
    const dispatch = useDispatch()
    const [allInventoryName, setAllInventoryName] = useState()
    const [allCategoryName, setAllCategoryName] = useState()
    const [allSubcategoryName, setAllSubcategoryName] = useState()
    const [allBrandName, setAllBrandName] = useState()
    const [allUomName, setAllUomName] = useState()
    const [allVendorName, setAllvendorName] = useState()
    const [isDisabled, setIsDisabled] = useState(true)
    const [editableForStage, setIsEditableForStage] = useState(false)

    const maxFileSize = 10 * 1024 * 1024;
    const maxImageSize = 5 * 1024 * 1024;
    const maxVideoSize = 5 * 1024 * 1024;

    const [allCheckinData, setAllCheckinData] = useState();
    const [checkInUpdateId, setCheckInUpdateId] = useState('');
    const [visible, setVisible] = useState(false);
    const webcamRef = useRef(null)
    const [videoStream, setVideoStream] = useState();

    const handleCamera = () => {
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((state) => {
                setVideoStream(state)
                setVisible(true)
            })
            .catch(() => toast.warn("permission required"))
    }

    const capturePicture = useCallback(() => {
        if (webcamRef.current) {
            const base64Image = webcamRef.current.getScreenshot();
            const filtered_type = base64Image?.split(';')[0];
            const file_type = filtered_type?.split(":")[1]
            if (base64Image) {
                setSelectedFiles((prevFiles) =>
                    [
                        ...prevFiles,
                        { fileType: file_type, fileName: "WebCamImage", base64Data: base64Image, attachment_type: "images", path: null }
                    ]
                )
            }
        }
        setVisible(false)
        stopCamera()
    })

    const stopCamera = async () => {
        if (videoStream) {
            (videoStream).getTracks().forEach((track) => {
                if (track.readyState == 'live') {
                    track.stop()
                }
            })
        }
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value.toLowerCase();
        setGlobalFilterValue(value);
    };

    const header = () => (
        <div className='flex justify-content-between align-items-center '>
            <h5 className='mb-0'> CheckIn Data List </h5>
            <div className='flex justify-content-between align-items-center '>
                <div className={`flex justify-content-between align-items-center  desaturated_blue mr-3 w-18rem border-round ${!allCheckinData?.length > 0 ? "opacity-60" : ""} `} style={{ padding: ' 0.75rem 1.25rem ' }} >
                    <p className=' mb-0 text-500' style={{ width: "97%" }}>
                        {
                            selectedDoc && Object.keys(selectedDoc)?.length !== 0 ? (
                                <span className='flex justify-content-between align-items-center mb-0 text-500' >
                                    <span className='text-overflow-ellipsis overflow-hidden text-white white-space-nowrap'>{selectedDoc?.fileName ? selectedDoc?.fileName : selectedDoc?.path ? 'Document' : 'Upload'}</span>
                                    <div className='flex flex-row ml-4'>
                                        <i className='pi pi-times mr-2 text-white' style={{ cursor: "pointer" }} onClick={() => setSelectedDoc("")} ></i>
                                        <i className='pi pi-download mr-2 text-white' style={{ cursor: "pointer", pointerEvents: 'auto', opacity: 1 }} onClick={() => handleDownloadFile(selectedDoc)} ></i>
                                    </div>
                                </span>
                            ) : (
                                <>
                                    <span className='mb-0  w-full text-white '>
                                        Upload
                                    </span>
                                </>
                            )
                        }
                    </p>
                    <FileUpload className='text-white' ref={uploadDoc} multiple accept={".pdf"} onSelect={(e) => handleSelectedDoc(e)} style={{ display: "none" }} />
                    <i className='pi pi-paperclip cursor-pointer text-white' onClick={handleDocument}></i>
                </div>
                <Button className=' desaturated_blue px-5 mr-3' type='button' disabled={!allCheckinData?.length > 0} label='Clear All Data' onClick={(e) => deleteDialog(e)} />
                <InputText placeholder='Search' value={globalFilterValue} onChange={onGlobalFilterChange} />
            </div>
        </div>
    )



    const deleteDialog = (e) => {
        confirmDialog({
            message: 'Are you sure you want to clear all data?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleAllDeleteData(e),
            reject: null
        });
    }

    const handleAllDeleteData = async (e) => {
        e.preventDefault();
        let obj = {
            uid: user_id,
            wid: warehouseid
        }
        setIsActive(true);
        const response = await dispatch(handleDeleteRequest(obj, `/api/Inventory/DeleteStageCheckIn`, false, true));
        if (response?.statusCode === "200") {
            getAllInventoryData(false)
        }
        setIsActive(false);
    }

    const actionTemplate = (rowData) => {

        const items = [
            // {
            //     label: 'Edit',
            //     command: () => getCheckInLisyByIdEditable(rowData, false),
            // },
            {
                label: 'Delete',
                command: () => confirm(rowData),
            }
        ];

        return (
            <div>
                <SplitButton label="" model={items} />
            </div>
        );
    };

    const confirm = (rowData) => {
        confirmDialog({
            message: 'Are you sure you want to Delete this Checkin Data?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleDeleteRecord(rowData),
            reject: null
        });
    }

    const handleDeleteRecord = async (rowData) => {
        let obj = {
            p_id: rowData?.id
        }

        setIsActive(true);

        const response = await dispatch(handleDeleteRequest(obj, `/api/Inventory/DeleteStageCheckInByID`, false, true));
        if (response?.statusCode == "200") {
            getAllInventoryData(false)
        }
        setIsActive(false);
    }

    const validationSchema = Yup.object().shape({
        checkbox: Yup.number().required("This field is required.").nullable(),
        category: Yup.number().required("This field is required.").nullable(),
        inventoryName: Yup.number().required("This field is required.").nullable(),
        brand: Yup.number().required("This field is required.").nullable(),
        rfidCode: Yup.string().required("This field is required.").nullable(),
        skuCode: Yup.string().required("This field is required.").nullable(),
        vendorInformation: Yup.number().required("This field is required.").nullable(),
        costPerUnit: Yup.number()
            .min(1, "Value must be at least 1")
            .required("This field is required.")
            .nullable(),
        valueOfMeasurement: Yup.number()
            .min(1, "Value must be at least 1")
            .required("This field is required.")
            .nullable(),
        quantity: Yup.number()
            .min(1, "Value must be at least 1")
            .required("This field is required.")
            .nullable(),
    });

    const formik = useFormik({

        validationSchema: validationSchema,
        initialValues: {
            checkbox: "",
            category: "",
            subCategory: "",
            inventoryName: "",
            brand: "",
            rfidCode: "",
            quantity: "",
            description: "",
            uom: 0,
            skuCode: "",
            costPerUnit: "",
            vendorInformation: "",
            thresholdValue: "",
            expiryDate: "",
            valueOfMeasurement: "",
            addNotes: "",
        },

        onSubmit: async (data) => {

            if (selectedFiles?.length === 0) {
                toast.warn('Please add image')
            } else {
                setIsActive(true)
                let payload = {
                    p_id: checkInUpdateId,
                    p_inventory_Type: data?.checkbox,
                    p_category_id: data?.category,
                    p_subcategory_id: data?.subCategory,
                    p_inventory_id: data?.inventoryName,
                    p_brand_id: data?.brand,
                    p_rfid: data?.rfidCode,
                    p_quantity: data?.quantity,
                    p_SalesDescription: data?.description,
                    p_unit_of_measurement: data?.uom,
                    p_sku_code: data?.skuCode,
                    p_cost: data?.costPerUnit,
                    p_vendor_id: data?.vendorInformation,
                    p_min_t_value: data?.thresholdValue,
                    p_Expiry_Date: data?.expiryDate !== "" ? data?.expiryDate : null,
                    p_value_of_measurement: data?.valueOfMeasurement,
                    p_Notes: data?.addNotes,
                    p_roletypeid: roletypeid,
                    p_usertypeid: usertype_id,
                    p_warehouseid: warehouseid,
                    p_createdby: user_id,
                }

                const concatAllData = [selectedFiles, selectedFiles2]
                const allArrayData = concatAllData.flat();
                payload["attachments"] = allArrayData;

                if (isEditable === false && editableForStage === false) {
                    delete payload['p_id'];
                }

                let response;

                if (isEditable === false && editableForStage === false) {
                    response = await dispatch(handlePostRequest(payload, '/api/Inventory/AddStageCheckIn', true, true));
                } else if (isEditable === true && editableForStage === false || isEditable === true && editableForStage === true) {
                    if (selectedDoc && Object.keys(selectedDoc)?.length !== 0) {
                        payload['attachments'] = [...allArrayData, selectedDoc]
                        response = await dispatch(handlePutRequest(payload, '/api/Inventory/UpdateCheckIn', true, true));
                    } else {
                        toast.warn('Please Upload Attachment')
                    }
                } else if (isEditable === false && editableForStage === true) {
                    response = await dispatch(handlePutRequest(payload, '/api/Inventory/UpdateStageCheckIn', true, true));
                } else {
                    response = null;
                }

                setIsActive(false)
                if ((isEditable === true && editableForStage === false && response?.statusCode == "200") || (isEditable === true && editableForStage === true && response?.statusCode == "200")) {
                    history.push('/checkin')
                }
                else if ((isEditable === false && editableForStage === true) && (response?.statusCode == "200")) {
                    console.log('here')
                    getAllInventoryData(false)
                    formik.resetForm();
                    formik.setFieldValue("checkbox", 1)
                    setSelectedDoc()
                    setSelectedFiles([])
                    setSelectedFiles2([])
                }
                //First Time Add Data for Stage Check In
                else if ((isEditable === false && editableForStage === false) && (response?.statusCode == "200")) {
                    getAllInventoryData(false)
                    formik.resetForm();
                    formik.setFieldValue("checkbox", 1)
                    setSelectedDoc()
                    setSelectedFiles([])
                    setSelectedFiles2([])
                    // await labelPrint()
                    // labelPrint().then(() => {});
                }
                else {
                    return null
                }
            }
        }
    });

    const ResetForm = () => {
        formik.resetForm();
        selectedFiles([]);
        selectedFiles2([]);
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const labelPrint = async () => {
        const obj = {}
        const response = await dispatch(handlePostRequest(obj, '/api/ZebraPrinter/PrintLabelContent/PrintLabelContent', true, true));
        console.log('response labelPrint:', response)
    }

    //File Handling
    const handleFileUpload = (event) => {
        const files = Array.from(event.files);
        const updatedFiles = files.map((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                if (file?.size > maxImageSize) {
                    toast.warn("Attached image size is greater then 5MB")
                }
                else if (selectedFiles?.length > 4) {
                    toast.warn("A maximum of 5 images can be added.");
                }
                else {
                    setSelectedFiles((prevFiles) => [
                        ...prevFiles,
                        { fileType: file.type, fileName: file.name, base64Data: base64String, attachment_type: "images", path: null }
                    ]);
                }
            };
            reader.readAsDataURL(file);
            return file;
        });
    };

    const handleVideo = () => {
        uploadVideo.current.choose();
    };

    const handleFileUpload2 = (event) => {
        const files = Array.from(event.files);
        const updatedFiles = files.map((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                if (file?.size > maxVideoSize) {
                    toast.warn("Attached video size is greater then 5MB")
                } else {
                    setSelectedFiles2((prevFiles) => [
                        { fileType: file.type, fileName: file.name, base64Data: base64String, attachment_type: "video", path: null }

                    ]);
                }
            };
            reader.readAsDataURL(file);
            return file;
        });
    };

    const handleDocument = () => {
        if (allCheckinData?.length > 0 || isEditable) {
            uploadDoc.current.choose();
        }
    };

    const handleImages = () => {
        uploadImg.current.choose();
    };

    const handleSelectedDoc = (e) => {
        if (e.files && e.files.length > 0) {
            let selectedFile = e.files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Doc = reader.result
                if (selectedFile?.size > maxFileSize) {
                    toast.warn("Attached file size is greater then 10MB")
                } else {
                    setSelectedDoc({ fileType: selectedFile?.type, fileName: selectedFile?.name, base64Data: base64Doc, attachment_type: "file", path: null })
                }
            }
            reader.readAsDataURL(selectedFile);
            return selectedFile
        } else {
        }
    };

    //For Deleting File
    const handleCancelFile = (file) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
    };

    const handleCancelFile2 = (file) => {
        setSelectedFiles2((prevFiles) => prevFiles.filter((f) => f !== file));
    };

    const getAllApplicationDetail = async (e) => {
        setIsActive(true)
        if (formik?.values?.inventoryName) {
            const response = await dispatch(handleGetRequest(`/api/Inventory/GetInventoryByid?id=${formik?.values?.inventoryName}`));
            if (response[0]?.name) {
                getSubcategoryLOVs(response[0]?.category_id);
                formik.setFieldValue('category', response[0]?.category_id)
                formik.setFieldValue('subCategory', response[0]?.subcategory_id)
                formik.setFieldValue('brand', response[0]?.brand_id)
                // setAllvendorName(response[0]?.vendor_name_string)
                formik.setFieldValue('vendorInformation', response[0]?.vendor_id)
                formik.setFieldValue('uom', response[0]?.unit_of_measurement)
                formik.setFieldValue('skuCode', response[0]?.sku)
                formik.setFieldValue('description', response[0]?.description)
                formik.setFieldValue('thresholdValue', response[0]?.min_t_value)
                getVendorByInvId(formik?.values?.inventoryName)
            }
        }
        setIsActive(false)
    }

    const getVendorByInvId = async (invId) => {
        const response = await dispatch(handleGetRequest(`/api/Inventory/GetVendorByInventoryid?whid=${warehouseid || 0}&id=${invId}`))
        if (response?.statusCode == 200) {
            setAllvendorName(response?.body)
        }
    }

    const getInventoryLOVs = async () => {
        const response = await dispatch(handleGetRequest(`/api/Inventory/GetInventory?warehouse_id=${warehouseid || 0}`))
        if (response) {
            setAllInventoryName(response)
        }
    }

    const getCategoryLOVs = async () => {
        const response = await dispatch(handleGetRequest(`/api/Inventory/GetAllCategorioesByWarehouse?warehouse_id=${warehouseid || 0}`))
        if (response) {
            setAllCategoryName(response)

        }
    }

    const getSubcategoryLOVs = async (category_id) => {
        const response = await dispatch(handleGetRequest(`/api/Inventory/GetAllSubCategoriesByCatid?Category_id=${category_id}&warehouse_id=${warehouseid || 0}`))
        if (response) {
            setAllSubcategoryName(response)
        }
    }

    const getBrandLOVs = async () => {
        const response = await dispatch(handleGetRequest(`/api/Setups/GetAllBrand?warehouse_id=${warehouseid || 0}`))
        if (response) {
            setAllBrandName(response)
        }
    }

    const getUomLOVs = async () => {
        const response = await dispatch(handleGetRequest(`/api/Inventory/GetUnitOfMeasurement`))
        if (response) {
            setAllUomName(response)
        }
    }

    const getVendorLOVs = async () => {
        const response = await dispatch(handleGetRequest(`/api/Setups/GetAllVendor?warehouse_id=${warehouseid || 0}`))
        if (response) {
            setAllvendorName(response)
        }
    }

    const getAllInventoryData = async (showAlert = true) => {
        setIsActive(true)
        const response = await dispatch(handleGetRequest(`/api/Inventory/GetStageCheckinList?warehouseid=${warehouseid || 0}&userid=${user_id}`))
        setIsActive(false)
        if (response) {
            if (response.length >= 1 && showAlert && isEditable === false) {
                toast.info('Your unsaved checkin data has been restored ')
                setAllCheckinData(response)
            } else {
                setAllCheckinData(response)
            }
        }
    }

    const handleCheckinData = async () => {
        setIsActive(true)
        let obj = {
            uid: user_id,
            wid: warehouseid,
            attachments: [selectedDoc]
        }
        if (selectedDoc && Object.keys(selectedDoc)?.length !== 0) {
            const response = await dispatch(handlePostRequest(obj, "/api/Inventory/AddSubmitCheckIn"));
            if (response.statusCode == "200") {
                getAllInventoryData(false)
                history.push('./checkin')
            }
        } else {
            toast.warn('Please Upload Attachment')
        }
        setIsActive(false)
    }

    const getCheckInLisyByIdEditable = async (rowData, isEditable) => {

        setIsEditableForStage(true)
        let keyData;
        if (isEditable) {
            const response = await dispatch(handleGetRequest(`/api/Inventory/GetCheckinByid?obj_id=${rowData?.id}`));
            keyData = response?.length ? response[0] : null;
        } else {
            const response = await dispatch(handleGetRequest(`/api/Inventory/GetStageCheckinByid?obj_id=${rowData?.id}`));
            keyData = response?.length ? response[0] : null;
        }
        if (keyData) {
            setCheckInUpdateId(keyData?.id)
            getSubcategoryLOVs(keyData?.category_id)
            await getVendorByInvId(parseInt(rowData?.inventory_id))
            formik.setFieldValue("checkbox", keyData?.inventory_Type)
            formik.setFieldValue("category", keyData?.category_id)
            formik.setFieldValue("subCategory", keyData?.subcategory_id)
            formik.setFieldValue("inventoryName", keyData?.inventory_id)
            formik.setFieldValue("brand", keyData?.brand_id)
            formik.setFieldValue("rfidCode", keyData?.rfid)
            formik.setFieldValue("quantity", keyData?.quantity)
            formik.setFieldValue("description", keyData?.salesDescription)
            formik.setFieldValue("uom", keyData?.unit_of_measurement)
            formik.setFieldValue("skuCode", keyData?.sku_code)
            formik.setFieldValue("costPerUnit", keyData?.cost)
            formik.setFieldValue("thresholdValue", keyData?.min_t_value)
            formik.setFieldValue("expiryDate", moment(keyData?.expiry_Date).toDate())
            formik.setFieldValue("valueOfMeasurement", keyData?.value_of_measurement)
            formik.setFieldValue("addNotes", keyData?.notes)
            formik.setFieldValue("vendorInformation", keyData?.vendor_id)

            let selectedDoc = {};
            const selectedFiles = [];
            const selectedFiles2 = [];

            // Split the keydata.link by commas to get individual entries
            const entries = keyData?.link?.split(',');

            // Iterate through each entry and split by $$ to check the type
            entries && entries.forEach(entry => {
                const [path, type] = entry.trim().split('$$');  // Trim the entry to remove leading/trailing spaces

                if (type === 'file') {
                    const obj = { fileType: null, fileName: null, base64Data: null, attachment_type: null, path: `${path}$$file` }
                    selectedDoc = obj;
                } else if (type === 'images') {
                    selectedFiles.push({ fileType: null, fileName: null, base64Data: null, attachment_type: null, path: `${path}$$images` });
                } else if (type === 'video') {
                    selectedFiles2.push({ fileType: null, fileName: null, base64Data: null, attachment_type: null, path: `${path}$$video` });
                }
            });

            // Assuming setSelectedDoc, setSelectedFiles, and setSelectedFiles2 are your state setters
            setSelectedDoc(selectedDoc);
            setSelectedFiles(selectedFiles);
            setSelectedFiles2(selectedFiles2);

        }
    }

    useEffect(() => {

        if (formik?.values?.checkbox == 2) {
            formik.setFieldValue('quantity', 1)
        }
        else {
            formik.setFieldValue('quantity', "")
        }

    }, [formik?.values?.checkbox])

    useEffect(() => {
        const myPermissions = JSON.parse(localStorage.getItem("permissions"))
        const allPermModules = myPermissions?.find(module => module?.routepath?.includes("/inventory"));
        if (allPermModules) {
            const result = allPermModules?.submodules?.find(item => item?.routepath == "/checkin")
            setPermissions(result?.permissions?.map(item => item?.permission_id) || []);
        }
        formik.setFieldValue("checkbox", 1)
        getInventoryLOVs();
        getCategoryLOVs();
        getBrandLOVs();
        getUomLOVs();
        //getVendorLOVs();
        getAllInventoryData()

    }, []);

    useEffect(() => {
        if (rowData && isEditable) {
            getCheckInLisyByIdEditable(rowData, isEditable)
        }

    }, [rowData, isEditable])

    //Disable Form while Viewing
    const formStyle = {
        pointerEvents: isView ? 'none' : 'auto',
        opacity: isView ? 0.7 : 1,
    };

    const formStyle2 = {
        pointerEvents: 'auto',
        opacity: 1,
    };

    const handleGenerateRfidCode = () => {
        const newRfidCode = generateRandomRfidCode();
        formik.setFieldValue('rfidCode', newRfidCode);
    };

    const generateRandomRfidCode = () => {
        const prefix = "Inv-";
        const length = 4;
        const charset = "0123456789";
        let userId = "";

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            userId += charset[randomIndex];
        }

        return prefix + userId;
    };

    const handleDownloadFile = async (file) => {

        let filePath;

        if (file?.path) {
            filePath = `${baseURL}/${file?.path?.split('$$')[0].replace(/\\/g, '/')}`;
        }
        else {
            filePath = file?.base64Data;
        }

        try {
            let response;

            if (file?.path) {
                response = await axios.get('https://dev-wms.appinsnap.com/api/Inventory/DownloadFile?url=' + filePath, {
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
            } else {
                response = await axios.get(filePath);
            }

            const blob = new Blob([response.data])
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Document.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.log(error)
        }
    };

    return (

        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgb(38 41 51 / 78%)',
                        width: '107.9%',
                        height: '125%',
                        top: '-27px',
                        left: '-35px'
                    })
                }}
            >
                <Toast ref={showToast} position="bottom-center" />

                <div className='main-form-card'>
                    <div className='form-header flex flex-row justify-content-between'>

                        {
                            isView ?
                                <div className='form-header-content'>
                                    <h4 style={{ fontWeight: '600', letterSpacing: '1px', color: 'black' }}>
                                        View Details
                                    </h4>
                                </div>
                                :
                                <div className='form-header-content'>
                                    <h4 style={{ fontWeight: '600', letterSpacing: '1px', color: 'black' }}>
                                        {isEditable ? 'Update Check In' : editableForStage ? 'Update Details' : 'Check In'}
                                    </h4>
                                    <h6 style={{ color: 'gray' }}> {isEditable ? '' : editableForStage ? '' : 'Add Details'}</h6>
                                </div>
                        }

                        <div className='mt-4 mr-3'>
                            <Button className='Back-Button mr-3' icon="pi pi-arrow-left" label='Go Back' onClick={() => history.goBack()}>
                            </Button>
                        </div>
                    </div>
                    <div className='form-div'>
                        <form onSubmit={formik.handleSubmit} style={formStyle}>
                            <div className="card-form">
                                <div className="card-nested-form">
                                    <div class="grid">
                                        <div class="col-12 lg:col-4 md:col-4 align-content-center ">
                                            <div className='flex align-items-center'>
                                                <RadioButton value={1} name="checkbox" onChange={formik.handleChange} checked={formik?.values?.checkbox === 1} />
                                                <label className='ml-2 mr-4'>Complete Boxes</label>
                                                <RadioButton value={2} name="checkbox" onChange={formik.handleChange} checked={formik?.values?.checkbox === 2} />
                                                <label className='ml-2'>Loose Pieces</label>
                                            </div>
                                            <div className='ml-2 mt-2'>
                                                {getFormErrorMessage("checkbox")}
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label>Inventory Name</label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <Dropdown
                                                    placeholder='--Select Inventory Name--'
                                                    id="inventoryName"
                                                    name="inventoryName"
                                                    options={allInventoryName}
                                                    optionLabel='name'
                                                    optionValue='id'
                                                    value={formik?.values?.inventoryName}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                    autoComplete="off"
                                                    onHide={(e) => getAllApplicationDetail(e)}
                                                ></Dropdown>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("inventoryName")}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Category </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <Dropdown
                                                    placeholder='--Select Category--'
                                                    id="category"
                                                    name="category"
                                                    disabled={isDisabled}
                                                    options={allCategoryName}
                                                    optionLabel='name'
                                                    optionValue='id'
                                                    value={formik.values.category}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                    autoComplete="off"
                                                ></Dropdown>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("category")}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Sub Category </label>
                                                </div>
                                                <TreeSelect
                                                    placeholder='--Select Sub Category--'
                                                    id="subCategory"
                                                    name="subCategory"
                                                    disabled={isDisabled}
                                                    options={allSubcategoryName}
                                                    optionLabel='label'
                                                    optionValue='key'
                                                    value={formik.values.subCategory}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm text-sm text-grey-500"
                                                // autoComplete="off"
                                                ></TreeSelect>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Supplier/ Vendor Information </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <Dropdown
                                                    placeholder='--Select--'
                                                    id="vendorInformation"
                                                    name="vendorInformation"
                                                    // disabled={isDisabled}
                                                    options={allVendorName}
                                                    optionLabel='obj_Name'
                                                    optionValue='obj_id'
                                                    value={formik.values.vendorInformation}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                ></Dropdown>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("vendorInformation")}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> RFID Code </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <div style={{ position: 'relative' }}>
                                                    <InputText
                                                        disabled
                                                        type='text'
                                                        placeholder='Auto Generate Rfid Code'
                                                        id='rfidCode'
                                                        name="rfidCode"
                                                        value={formik.values.rfidCode}
                                                        onChange={formik.handleChange}
                                                        className="p-inputtext-sm"
                                                        autoComplete="off"
                                                    />
                                                    <Button
                                                        tooltip='Auto Generate Rfid Code'
                                                        tooltipOptions={{ position: "top" }}
                                                        type="button"
                                                        icon="pi pi-refresh"
                                                        className='auto-generate-button'
                                                        onClick={handleGenerateRfidCode}
                                                        style={{
                                                            background: '#8d7ad5',
                                                            borderColor: "#8d7ad5",
                                                            position: 'absolute',
                                                            top: '30%',
                                                            right: '8px',
                                                            transform: 'translateY(-50%)',
                                                        }}
                                                    />
                                                </div>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("rfidCode")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mb-4' style={{ borderBottom: "1px solid #8d7ad5" }}> </div>

                                    <div class="grid">
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label>Brand</label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <Dropdown
                                                    placeholder='--Select Brand--'
                                                    id="brand"
                                                    name="brand"
                                                    disabled={isDisabled}
                                                    options={allBrandName}
                                                    optionLabel='obj_Name'
                                                    optionValue='obj_id'
                                                    value={formik.values.brand}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                    autoComplete="off"
                                                ></Dropdown>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("brand")}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Unit of Measurement </label>
                                                </div>
                                                <Dropdown
                                                    placeholder='--Select Unit of Measurement--'
                                                    id="uom"
                                                    name="uom"
                                                    disabled={isDisabled}
                                                    options={allUomName}
                                                    optionLabel='name'
                                                    optionValue='id'
                                                    value={formik?.values?.uom}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                    autoComplete="off"
                                                ></Dropdown>
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label>SKU</label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText maxLength={10} className="p-inputtext-sm" id="skuCode" name="skuCode" disabled={isDisabled} placeholder='Enter SKU Code' value={formik.values?.skuCode} onChange={formik.handleChange} type="text" />
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("skuCode")}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">

                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Min Threshold Value</label>
                                                </div>
                                                <InputText maxLength={10} disabled={isDisabled} className="p-inputtext-sm" id="thresholdValue" name="thresholdValue" placeholder='Enter Threshold Value' value={formik.values?.thresholdValue} onChange={formik.handleChange} type="number" />
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label>Quantity</label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText disabled={formik?.values?.checkbox == 2 ? true : false} min={1} className="p-inputtext-sm" id="quantity" name="quantity" placeholder='Enter Quantity' value={formik.values.quantity} onChange={formik.handleChange} maxLength={6}
                                                    type="number"
                                                />
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("quantity")}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Value of Measurement </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText min={1} maxLength={5} className="p-inputtext-sm" id="valueOfMeasurement" name="valueOfMeasurement" placeholder='Enter Value of Measurement' value={formik.values.valueOfMeasurement} onChange={formik.handleChange}
                                                    type="number" />
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("valueOfMeasurement")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="grid">
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Cost per Unit </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText min={1} className="p-inputtext-sm" id="costPerUnit" name="costPerUnit" placeholder='Enter Unit Price' value={formik.values.costPerUnit} onChange={(e) => {
                                                    const { value } = e.target;
                                                    if (value.length <= 10) {
                                                        formik.handleChange(e);
                                                    }
                                                }} type="number" />
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("costPerUnit")}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label>Expiry Date</label>
                                                </div>
                                                <Calendar className="p-calendar-sm" minDate={new Date()} id="expiryDate" name="expiryDate" placeholder='Enter Expiry Date' value={formik.values?.expiryDate} onChange={formik.handleChange} type="text" />
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Description </label>
                                                </div>
                                                <InputTextarea rows={4} maxLength={150} className="p-inputtext-sm" id="description" name="description" disabled={isDisabled} placeholder='Enter Description' value={formik.values.description} onChange={formik.handleChange} type="text" />
                                            </div>
                                        </div>

                                    </div>

                                    <div class="grid">
                                        <div class="col-12 lg:col-8 md:col-8">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Add Product Images </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <div className="flex flex-column mt-2">
                                                    <div className="mb-2 flex flex-column">
                                                        <div className='flex flex-row ml-2'>
                                                            <div className='flex flex-column'>
                                                                <img
                                                                    src={photo}
                                                                    width="100px" height="100px"
                                                                    alt="Profile Image"
                                                                    className="ml-2 photo-bg "
                                                                />
                                                                <FileUpload
                                                                    className="mt-2 p-0 hidden"
                                                                    onSelect={handleFileUpload}
                                                                    accept="image/*"
                                                                    ref={uploadImg}
                                                                />
                                                                <div className='p-fileupload-choose cursor-pointer flex justify-content-center align-items-center mt-3' onClick={() => handleImages()}>
                                                                    <i className='pi pi-plus text-lg' style={{ marginLeft: "1.3rem" }}></i>
                                                                    <span className='p-button-label  ml-2 text-sm'>Choose File</span>
                                                                </div>
                                                                <div onClick={() => handleCamera()} className='p-fileupload-choose cursor-pointer flex justify-content-center align-items-center mt-3'>
                                                                    <i className='pi pi-camera text-lg' style={{ marginLeft: "1.3rem" }}></i>
                                                                    <span className='p-button-label  ml-2 text-sm'>Take a Picture</span>
                                                                </div>
                                                                <Dialog header="Photo" visible={visible} style={{ width: '50vw', height: '50vw' }}
                                                                    onHide={() => {
                                                                        if (!visible) return;
                                                                        setVisible(false)
                                                                        stopCamera();
                                                                    }}>
                                                                    <div className='flex justify-content-center w-full' >
                                                                        <Webcam width={"80%"} ref={webcamRef} />
                                                                    </div>
                                                                    <div className='mt-5'>
                                                                        <Button className='desaturated_blue px-5  block m-auto' onClick={capturePicture} >Take picture</Button>
                                                                    </div>
                                                                </Dialog>
                                                            </div>

                                                            <div className="flex flex-row ">
                                                                {selectedFiles && selectedFiles.length ?
                                                                    <div className='flex flex-row'>
                                                                        {selectedFiles.length === 0 && selectedFiles.length === 0 ? (
                                                                            <p style={{ fontSize: "14px", fontWeight: "600", fontStyle: "italic", color: "gray" }}></p>
                                                                        ) : (
                                                                            selectedFiles && selectedFiles.map((file, index) => (
                                                                                <span key={index} className="" style={{ position: "relative", marginLeft: "8px", marginRight: "8px" }}>
                                                                                    <Image
                                                                                        style={formStyle2}
                                                                                        preview
                                                                                        src={file?.base64Data || `${baseURL}/${file?.path?.split('$$')[0]}`}
                                                                                        zoomSrc={file?.base64Data || `${baseURL}/${file?.path?.split('$$')[0]}`}
                                                                                        alt={`Attachment ${index + 1}`}
                                                                                        width="100"
                                                                                        height="100"
                                                                                        imageStyle={{ borderRadius: "10px", border: '2px solid rgb(172 172 173)' }}
                                                                                    />
                                                                                    <Button
                                                                                        tooltip='Remove Image'
                                                                                        tooltipOptions={{ position: "top" }}
                                                                                        type='button'
                                                                                        icon="pi pi-times"
                                                                                        className='remove-file-button'
                                                                                        style={{
                                                                                            position: "absolute",
                                                                                            top: '0px',
                                                                                            right: '0px',
                                                                                            zIndex: 1,
                                                                                            fontSize: '10px'
                                                                                        }}
                                                                                        onClick={() => handleCancelFile(file)}
                                                                                    />
                                                                                </span>
                                                                            ))
                                                                        )}
                                                                    </div>
                                                                    :
                                                                    <div className='mt-4 flex'>
                                                                        <p style={{ fontSize: "14px", fontWeight: "600", fontStyle: "italic", color: "gray" }}></p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Add Video </label>
                                                </div>
                                                <div className="flex flex-column mt-2">
                                                    <div className="mb-2 flex flex-column">
                                                        <div className='flex flex-row ml-2'>
                                                            <div className='flex flex-column'>
                                                                <img
                                                                    src={video}
                                                                    width="100px" height="100px"
                                                                    alt="Video"
                                                                    className="ml-4 video-bg"
                                                                />
                                                                <FileUpload ref={uploadVideo} accept="video/*" onSelect={(e) => handleFileUpload2(e)} style={{ display: "none" }} />

                                                                <div className='p-fileupload-choose cursor-pointer flex justify-content-center align-items-center mt-3' onClick={handleVideo}>
                                                                    <i className='pi pi-plus text-lg' style={{ marginLeft: "1.3rem" }}></i>
                                                                    <span className='p-button-label  ml-2 text-sm'>Choose File</span>
                                                                </div>
                                                            </div>

                                                            <div className="flex flex-row ml-4">
                                                                {selectedFiles2 && selectedFiles2.length ?
                                                                    <div className='flex flex-row'>
                                                                        {selectedFiles2.length === 0 && selectedFiles2.length === 0 ? (
                                                                            <p style={{ fontSize: "14px", fontWeight: "600", fontStyle: "italic", color: "gray" }}></p>
                                                                        ) : (
                                                                            selectedFiles2 && selectedFiles2.map((file, index) => (
                                                                                <span key={index} className="" style={{ position: "relative", marginLeft: "10px" }}>
                                                                                    <video
                                                                                        src={file?.base64Data || `${baseURL}/${file?.path?.split('$$')[0]}`}
                                                                                        alt='Video'
                                                                                        style={{
                                                                                            width: '130px',
                                                                                            height: '100px',
                                                                                            border: '2px solid rgb(172 172 173)',
                                                                                            borderRadius: '10px',
                                                                                            pointerEvents: 'auto',
                                                                                            opacity: 1
                                                                                        }}
                                                                                        controls
                                                                                    />
                                                                                    <Button
                                                                                        tooltip='Remove Video'
                                                                                        tooltipOptions={{ position: "top" }}
                                                                                        type='button'
                                                                                        icon="pi pi-times"
                                                                                        className='remove-file-button'
                                                                                        style={{
                                                                                            position: "absolute",
                                                                                            top: '0px',
                                                                                            right: '0px',
                                                                                            zIndex: 1,
                                                                                            fontSize: '10px'
                                                                                        }}
                                                                                        onClick={() => handleCancelFile2(file)}
                                                                                    />
                                                                                </span>
                                                                            ))
                                                                        )}
                                                                    </div>
                                                                    :
                                                                    <div className='mt-4 flex'>
                                                                        <p style={{ fontSize: "14px", fontWeight: "600", fontStyle: "italic", color: "gray" }}></p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="grid">
                                        <div className="col-12 lg:col-4 md:col-4">
                                            <div className="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Add Notes </label>
                                                </div>
                                                <InputTextarea maxLength={150} rows={5} className="p-inputtext-sm" id="addNotes" name="addNotes" placeholder='Enter Notes' value={formik.values.addNotes} onChange={formik.handleChange} type="text" />
                                            </div>
                                        </div>
                                        {
                                            isEditable ?
                                                (
                                                    <div className="col-12 lg:col-4 md:col-4">
                                                        <div className="input-card flex-column p-3">
                                                            <div className='flex-row'>
                                                                <label>Purchase Order </label>
                                                                <span className="Label__Required">*</span>
                                                            </div>
                                                            <div className='flex justify-content-between align-items-center px-2 mt-2 ' >
                                                                <p className=' mb-0  text-500' style={{ width: "97%" }}>
                                                                    {
                                                                        selectedDoc && Object.keys(selectedDoc)?.length !== 0 ? (
                                                                            <span className='flex justify-content-between align-items-center mb-0 text-500' >
                                                                                <span className='text-overflow-ellipsis overflow-hidden'>{selectedDoc?.fileName ? selectedDoc?.fileName : selectedDoc?.path ? 'Document' : 'Upload'}</span>
                                                                                <div className='flex flex-row'>
                                                                                    <i className='pi pi-times mr-2' style={{ cursor: "pointer" }} onClick={() => setSelectedDoc("")} ></i>
                                                                                    <i className='pi pi-download mr-2' style={{ cursor: "pointer", pointerEvents: 'auto', opacity: 1 }} onClick={() => handleDownloadFile(selectedDoc)} ></i>
                                                                                </div>
                                                                            </span>
                                                                        ) : (
                                                                            <>
                                                                                <span className='mb-0 text-500 w-full '>
                                                                                    Upload
                                                                                </span>
                                                                            </>
                                                                        )
                                                                    }
                                                                </p>
                                                                <FileUpload ref={uploadDoc} multiple accept={".pdf"} onSelect={(e) => handleSelectedDoc(e)} style={{ display: "none" }} />
                                                                <i className='pi pi-paperclip cursor-pointer' onClick={handleDocument}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                                :
                                                null
                                        }
                                        <div className="col-12 lg:col-4 md:col-4">
                                        </div>
                                    </div>

                                    {permissions.includes(2) ?
                                        isView ? null : (
                                            <div className='col-12 d-flex flex-row text-center mt-4 pb-2'>
                                                <Button className="Cancel-Button" label="Reset" type="button" onClick={ResetForm} />
                                                <Button className="Save-Button ml-2" label={isEditable || editableForStage ? "Update" : 'Check In'} loading={saveBtnLoading} type="submit" />
                                            </div>)
                                        :
                                        null
                                    }

                                </div>
                            </div>

                            {isEditable === false ?
                                <div className="card mt-3">

                                    <DataTable
                                        responsive={true}
                                        globalFilter={globalFilterValue}
                                        header={header}
                                        responsiveLayout="scroll"
                                        stripedRows
                                        paginator
                                        rows={20}
                                        value={allCheckinData}
                                        paginatorClassName="custom-paginator"
                                        emptyMessage="No records found"
                                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                        rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                    >
                                        <Column field="inventory_name" header="Product Name"></Column>
                                        <Column field="rfid" header="RFID Code"></Column>
                                        <Column field="category_name" header="Category"></Column>
                                        <Column field="vendor_name" header="Vendor"></Column>
                                        {/* <Column field='subcategory_name' body={(rowData) => rowData?.subcategory_name || 'N/A'} header="Sub Category"></Column> */}
                                        <Column field="brand_name" header="Brand"></Column>
                                        <Column field='unit' body={(rowData) => rowData?.unit || 'N/A'} header="Unit"></Column>
                                        {/* <Column field='salesDescription' body={(rowData) => rowData?.salesDescription || 'N/A'} header="Description"></Column> */}
                                        <Column field="quantity" header="Quantity"></Column>
                                        <Column field="value_of_measurement" header="Value"></Column>
                                        <Column field="cost" header="Cost"></Column>
                                        <Column
                                            body={(rowData) => rowData?.expiry_Date ? moment(rowData.expiry_Date).format('DD-MM-YYYY') : 'N/A'}
                                            header="Expiry Date"
                                        />
                                        <Column header="Action" body={actionTemplate} />
                                    </DataTable>
                                </div>
                                :
                                null}
                        </form>

                        {isView || isEditable ?
                            null
                            :
                            <Button className='block mx-auto  Save-Button' disabled={!allCheckinData?.length > 0} type='button' label='Submit' onClick={() => handleCheckinData()} />
                        }

                    </div>
                </div>

            </LoadingOverlay>
        </>
    )
}

export default CheckIn














