import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { confirmDialog } from "primereact/confirmdialog";
import { useHistory } from "react-router-dom";
import { SplitButton } from "primereact/splitbutton";
import { handleGetRequest } from "../../../services/GetTemplate";
import moment from "moment";
import { TabView, TabPanel } from 'primereact/tabview';
import { handleDeleteRequest } from "../../../services/DeleteTemplate";

const CheckInAssetGrid = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const [isActive, setIsActive] = useState(false)
    const [checkInList, setCheckInList] = useState([]);
    const [firstTimeCheckInList, setFirstTimeCheckInList] = useState([]);
    const [borrowItemsCheckInList, setBorrowItemsCheckInList] = useState([]);
    const [acceptButtonClass, setAcceptButtonClass] = useState('p-button-danger');

    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")

    const [permissions, setPermissions] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const actionTemplate = (rowData) => {

        const items = [];

        if ((roletypeid == 1) || (roletypeid == 2 && usertype_id == 1 && !warehouseid)) {
            items.push({
                label: 'View',
                command: () => handleViewCheckIn(rowData),
            });
        } else {
            items.push(
                {
                    label: 'Delete',
                    command: () => confirm(rowData),
                },

            );
        }

        return (
            <div>
                <SplitButton label="" model={items} />
            </div>
        );
    };

    const confirm = (rowData) => {

        confirmDialog({
            message: 'Are you sure you want to delete this CheckIn data?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: acceptButtonClass,
            acceptLabel: 'Yes',
            accept: () => {
                handleDeleteData(rowData);
            },
            reject: () => {
                return null;
            },
        });

    };

    const handleDeleteData = async (rowData) => {

        const obj = {
            p_id: rowData?.id,
        };

        setIsActive(true);

        const response = await dispatch(handleDeleteRequest(obj, `/api/Asset/DeleteAssetCheckInById`, true, true));
        if (response?.statusCode == 200) {
            await getCheckInList();
        }
        setIsActive(false);
    }

    const handleViewCheckIn = (rowData) => {

        const item = true;
        const view = true;

        history.push({
            pathname: './checkinassetaddedit',
            state: {
                isEditable: item,
                rowData: rowData,
                isView: view,
            }
        });
    };

    //Get Inventory List
    const getCheckInList = async () => {

        setIsActive(true);
        const response = await dispatch(handleGetRequest(`/api/Asset/GetAssetCheckInList?warehouseid=${warehouseid || 0}`, true));
        if (response) {
            setCheckInList(response);
            setFirstTimeCheckInList(response?.filter(item => item?.check_In_type_code == 1));
            setBorrowItemsCheckInList(response?.filter(item => item?.check_In_type_code == 2));
        }
        setIsActive(false);
    };

    useEffect(() => {
        getCheckInList()
    }, [])

    useEffect(() => {

        const myPermissions = JSON.parse(localStorage.getItem("permissions"))
        const allPermModules = myPermissions?.find(module => module?.routepath?.includes("/asset"));
        if (allPermModules) {
            const result = allPermModules?.submodules?.find(item => item?.routepath == "/checkinasset")
            setPermissions(result?.permissions?.map(item => item?.permission_id) || []);
        }

    }, []);

    return (

        <>
            <div className="card col-12" style={{ background: "transparent", borderColor: "#38373d" }}>
                <TabView className="">
                    <TabPanel header="All Records">
                        <div className="flex flex-column align-items-start md:flex-row  md:justify-content-between md:align-items-center ">
                            <h4 className="mb-0" style={{ fontWeight: '600', letterSpacing: '0.5px', color: 'black' }}>
                                All Records
                            </h4>
                            <div className="flex flex-column align-items-start  md:flex-row md:align-items-center">
                                <span className="p-input-icon-left mr-3 resp-margin my-3 md:my-0">
                                    <i className="pi pi-search" />
                                    <InputText
                                        placeholder="Search"
                                        value={globalFilterValue}
                                        onChange={onGlobalFilterChange}
                                        className="search-input"
                                    />
                                </span>

                                {(roletypeid == 1) || (roletypeid == 2 && usertype_id == 1 && !warehouseid) ?
                                    null
                                    :
                                    permissions.includes(2) ?
                                        <div className="">
                                            <Button
                                                label="Checkin Asset"
                                                className="Add__New-Button ml-0 mt-1 md:ml-3 md:mt-0"
                                                icon="pi pi-plus"
                                                onClick={() => {
                                                    const item = false;
                                                    history.push({
                                                        pathname: './checkinassetaddedit',
                                                        state: {
                                                            isEditable: item,
                                                        }
                                                    });
                                                }}
                                            />
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        <div className="card mt-5">
                            <LoadingOverlay
                                active={isActive}
                                spinner={<Triangle
                                    height="120"
                                    width="120"
                                    color="#755fc8"
                                    ariaLabel="triangle-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />}

                                // text='Loading your content...'
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        background: 'rgb(38 41 51 / 78%)',
                                    })
                                }}
                            >

                                <DataTable
                                    responsive={true}
                                    filters={filters}
                                    globalFilterFields={[
                                        "asset_name",
                                        "inventory_Type_Name",
                                        "check_In_type",
                                        "category_name",
                                        "subcategory_name",
                                        "brand_name",
                                        "sku_code",
                                        "rfid",
                                        "vendoR_NAME",
                                        "createddatetime",
                                    ]}
                                    responsiveLayout="scroll"
                                    stripedRows
                                    paginator
                                    rows={20}
                                    value={checkInList}
                                    emptyMessage="No records found"
                                    paginatorClassName="custom-paginator"
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                >
                                    <Column sortable field='asset_name' body={(rowData) => rowData?.asset_name || 'N/A'} header="Asset Name"></Column>
                                    {/* <Column sortable field='inventory_Type_Name' body={(rowData) => rowData?.inventory_Type_Name || 'N/A'} header="Asset Type"></Column> */}
                                    <Column sortable field='check_In_type' body={(rowData) => rowData?.check_In_type === 'Borrow Items' ? 'Return Items' : rowData?.check_In_type} header="Check In Type"></Column>
                                    <Column sortable field='category_name' body={(rowData) => rowData?.category_name || 'N/A'} header="Category"></Column>
                                    <Column sortable field='subcategory_name' body={(rowData) => rowData?.subcategory_name || 'N/A'} header="Sub Category"></Column>
                                    <Column sortable field='brand_name' body={(rowData) => rowData?.brand_name || 'N/A'} header="Brand"></Column>
                                    <Column
                                        field="sku_code"
                                        sortable
                                        body={(rowData) => (
                                            <div>
                                                {<div style={{ whiteSpace: 'pre-wrap' }}>
                                                    {rowData?.sku_code?.replace(/(.{25})/g, '$1\n')}
                                                </div> || 'N/A'}
                                            </div>
                                        )}
                                        header="SKU"
                                    ></Column>
                                    <Column sortable field='rfid' body={(rowData) => rowData?.rfid || 'N/A'} header="RFID"></Column>
                                    <Column sortable field='vendoR_NAME' body={(rowData) => rowData?.vendoR_NAME || 'N/A'} header="Vendor"></Column>
                                    <Column
                                        body={(rowData) => rowData?.createddatetime ? moment(rowData?.createddatetime).format('DD-MM-YYYY HH:mm A') : 'N/A'}
                                        header="Created Date"
                                    />
                                    {permissions.includes(2) ?
                                        <Column header="Action" body={actionTemplate} />
                                        :
                                        null
                                    }
                                </DataTable>
                            </LoadingOverlay>
                        </div>
                    </TabPanel>
                    <TabPanel header="First time CheckIn">
                        <div className="flex flex-column align-items-start md:flex-row  md:justify-content-between md:align-items-center ">
                            <h4 className="mb-0" style={{ fontWeight: '600', letterSpacing: '0.5px', color: 'black' }}>
                                Check In Items
                            </h4>
                            <div className="flex flex-column align-items-start  md:flex-row md:align-items-center">
                                <span className="p-input-icon-left mr-3 resp-margin my-3 md:my-0">
                                    <i className="pi pi-search" />
                                    <InputText
                                        placeholder="Search"
                                        value={globalFilterValue}
                                        onChange={onGlobalFilterChange}
                                        className="search-input"
                                    />
                                </span>

                                {(roletypeid == 1) || (roletypeid == 2 && usertype_id == 1 && !warehouseid) ?
                                    null
                                    :
                                    permissions.includes(2) ?
                                        <div className="">
                                            <Button
                                                label="Checkin Asset"
                                                className="Add__New-Button ml-0 mt-1 md:ml-3 md:mt-0"
                                                icon="pi pi-plus"
                                                onClick={() => {
                                                    const item = false;
                                                    history.push({
                                                        pathname: './checkinassetaddedit',
                                                        state: {
                                                            isEditable: item,
                                                        }
                                                    });
                                                }}
                                            />
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        <div className="card mt-5">
                            <LoadingOverlay
                                active={isActive}
                                spinner={<Triangle
                                    height="120"
                                    width="120"
                                    color="#755fc8"
                                    ariaLabel="triangle-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />}

                                // text='Loading your content...'
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        background: 'rgb(38 41 51 / 78%)',
                                    })
                                }}
                            >

                                <DataTable
                                    responsive={true}
                                    filters={filters}
                                    globalFilterFields={[
                                        "asset_name",
                                        "inventory_Type_Name",
                                        "check_In_type",
                                        "category_name",
                                        "subcategory_name",
                                        "brand_name",
                                        "sku_code",
                                        "rfid",
                                        "vendoR_NAME",
                                        "createddatetime",
                                    ]}
                                    responsiveLayout="scroll"
                                    stripedRows
                                    paginator
                                    rows={20}
                                    value={firstTimeCheckInList}
                                    emptyMessage="No records found"
                                    paginatorClassName="custom-paginator"
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                >
                                    <Column sortable field='asset_name' body={(rowData) => rowData?.asset_name || 'N/A'} header="Asset Name"></Column>
                                    {/* <Column sortable field='inventory_Type_Name' body={(rowData) => rowData?.inventory_Type_Name || 'N/A'} header="Asset Type"></Column> */}
                                    <Column sortable field='check_In_type' body={(rowData) => rowData?.check_In_type === 'Borrow Items' ? 'Return Items' : rowData?.check_In_type} header="Check In Type"></Column>
                                    <Column sortable field='category_name' body={(rowData) => rowData?.category_name || 'N/A'} header="Category"></Column>
                                    <Column sortable field='subcategory_name' body={(rowData) => rowData?.subcategory_name || 'N/A'} header="Sub Category"></Column>
                                    <Column sortable field='brand_name' body={(rowData) => rowData?.brand_name || 'N/A'} header="Brand"></Column>
                                    <Column
                                        field="sku_code"
                                        sortable
                                        body={(rowData) => (
                                            <div>
                                                {<div style={{ whiteSpace: 'pre-wrap' }}>
                                                    {rowData?.sku_code.replace(/(.{25})/g, '$1\n')}
                                                </div> || 'N/A'}
                                            </div>
                                        )}
                                        header="SKU"
                                    ></Column>
                                    <Column sortable field='rfid' body={(rowData) => rowData?.rfid || 'N/A'} header="RFID"></Column>
                                    <Column sortable field='vendoR_NAME' body={(rowData) => rowData?.vendoR_NAME || 'N/A'} header="Vendor"></Column>
                                    <Column
                                        body={(rowData) => rowData?.createddatetime ? moment(rowData?.createddatetime).format('DD-MM-YYYY HH:mm A') : 'N/A'}
                                        header="Created Date"
                                    />
                                    {permissions.includes(2) ?
                                        <Column header="Action" body={actionTemplate} />
                                        :
                                        null
                                    }
                                </DataTable>
                            </LoadingOverlay>
                        </div>
                    </TabPanel>
                    <TabPanel header="Return Items">
                        <div className="flex flex-column align-items-start md:flex-row  md:justify-content-between md:align-items-center ">
                            <h4 className="mb-0" style={{ fontWeight: '600', letterSpacing: '0.5px', color: 'black' }}>
                                Return Items
                            </h4>
                            <div className="flex flex-column align-items-start  md:flex-row md:align-items-center">
                                <span className="p-input-icon-left mr-3 resp-margin my-3 md:my-0">
                                    <i className="pi pi-search" />
                                    <InputText
                                        placeholder="Search"
                                        value={globalFilterValue}
                                        onChange={onGlobalFilterChange}
                                        className="search-input"
                                    />
                                </span>

                                {(roletypeid == 1) || (roletypeid == 2 && usertype_id == 1 && !warehouseid) ?
                                    null
                                    :
                                    permissions.includes(2) ?
                                        <div className="">
                                            <Button
                                                label="Checkin Asset"
                                                className="Add__New-Button ml-0 mt-1 md:ml-3 md:mt-0"
                                                icon="pi pi-plus"
                                                onClick={() => {
                                                    const item = false;
                                                    history.push({
                                                        pathname: './checkinassetaddedit',
                                                        state: {
                                                            isEditable: item,
                                                        }
                                                    });
                                                }}
                                            />
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        <div className="card mt-5">
                            <LoadingOverlay
                                active={isActive}
                                spinner={<Triangle
                                    height="120"
                                    width="120"
                                    color="#755fc8"
                                    ariaLabel="triangle-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />}

                                // text='Loading your content...'
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        background: 'rgb(38 41 51 / 78%)',
                                    })
                                }}
                            >

                                <DataTable
                                    responsive={true}
                                    filters={filters}
                                    globalFilterFields={[
                                        "asset_name",
                                        "inventory_Type_Name",
                                        "check_In_type",
                                        "category_name",
                                        "subcategory_name",
                                        "brand_name",
                                        "sku_code",
                                        "rfid",
                                        "vendoR_NAME",
                                        "createddatetime",
                                    ]}
                                    responsiveLayout="scroll"
                                    stripedRows
                                    paginator
                                    rows={20}
                                    value={borrowItemsCheckInList}
                                    emptyMessage="No records found"
                                    paginatorClassName="custom-paginator"
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                >
                                    <Column sortable field='asset_name' body={(rowData) => rowData?.asset_name || 'N/A'} header="Asset Name"></Column>
                                    {/* <Column sortable field='inventory_Type_Name' body={(rowData) => rowData?.inventory_Type_Name || 'N/A'} header="Asset Type"></Column> */}
                                    <Column sortable field='check_In_type' body={(rowData) => rowData?.check_In_type === 'Borrow Items' ? 'Return Items' : rowData?.check_In_type} header="Check In Type"></Column>
                                    <Column sortable field='category_name' body={(rowData) => rowData?.category_name || 'N/A'} header="Category"></Column>
                                    <Column sortable field='subcategory_name' body={(rowData) => rowData?.subcategory_name || 'N/A'} header="Sub Category"></Column>
                                    <Column sortable field='brand_name' body={(rowData) => rowData?.brand_name || 'N/A'} header="Brand"></Column>

                                    <Column
                                        field="sku_code"
                                        sortable
                                        body={(rowData) => (
                                            <div>
                                                {<div style={{ whiteSpace: 'pre-wrap' }}>
                                                    {rowData?.sku_code.replace(/(.{25})/g, '$1\n')}
                                                </div> || 'N/A'}
                                            </div>
                                        )}
                                        header="SKU"
                                    ></Column>
                                    <Column sortable field='rfid' body={(rowData) => rowData?.rfid || 'N/A'} header="RFID"></Column>
                                    <Column sortable field='vendoR_NAME' body={(rowData) => rowData?.vendoR_NAME || 'N/A'} header="Vendor"></Column>
                                    <Column
                                        body={(rowData) => rowData?.createddatetime ? moment(rowData?.createddatetime).format('DD-MM-YYYY HH:mm A') : 'N/A'}
                                        header="Created Date"
                                    />
                                    {permissions.includes(2) ?
                                        <Column header="Action" body={actionTemplate} />
                                        :
                                        null
                                    }
                                </DataTable>
                            </LoadingOverlay>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </>
    );
};

export default CheckInAssetGrid