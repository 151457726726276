import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import "./AppTopbar.scss";
import dp from '../src/assets/warehouse-assets/dp.jpeg'
import { baseURL } from "./Config";
import { Image } from "primereact/image";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

export const AppTopbar = (props) => {

    const history = useHistory();

    const user_name = localStorage.getItem("user_name")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")
    const warehouse_name = localStorage.getItem("warehouse_name")
    const logo_img = localStorage.getItem("logo_img")
    const img = localStorage.getItem("img")

    const accept = () => {
        localStorage.clear();
        localStorage.removeItem('lastActive');
        localStorage.removeItem("permissions");
        localStorage.removeItem("login");
        localStorage.removeItem("token");
        window.localStorage.clear();
        window.sessionStorage.clear();
        history.push("/");
    };

    const reject = () => {
        return null
    };

    const confirm1 = () => {
        confirmDialog({
            message: 'Are you sure you want to Logout?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept,
            reject
        });
    };

    return (

        <>
            <div className="layout-topbar">
                <ConfirmDialog style={{ width: '500px' }} />
                <button type="button" className="p-link layout-menu-button layout-topbar-button ml-0" onClick={props.onToggleMenuClick}>
                    <i className="pi pi-bars" />
                </button>
                <div className="flex flex-column ml-2 text-center topbar-heading">
                    {warehouse_name ?
                        <div className="flex flex-row" style={{ alignItems: "center" }}>
                            <span className="ml-2">
                                <Image
                                    src={logo_img ? `${baseURL}/${logo_img}` : dp}
                                    preview
                                    zoomSrc={logo_img ? `${baseURL}/${logo_img}` : dp}
                                    className=''
                                    alt='dp'
                                    width="45"
                                    height="45"
                                    imageStyle={{ borderRadius: "50%" }}
                                />
                            </span>
                            <span className='ml-4' style={{ fontWeight: "bolder", fontSize: "21px", letterSpacing: '2px', color: "#5465df" }}>{warehouse_name}  </span>
                        </div>
                        :
                        <span style={{ fontWeight: "bolder", fontSize: "21px", letterSpacing: '2px', color: "#5465df" }} className="ml-2">WareHouse <span style={{ color: 'black' }}> MS </span></span>
                    }
                </div>

                <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                    <i className="pi pi-ellipsis-v" />
                </button>

                <ul className={classNames("layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
                    <li className="flex" style={{ alignItems: "center" }}>
                        <span className="pi pi-cog mr-3" style={{ fontSize: '2rem', color: 'gray', cursor: 'pointer' }} onClick={() => history.push('/settings')}></span>
                        {/* <span className="pi pi-bell mr-3" style={{ fontSize: '2rem', color: 'gray', cursor: 'pointer' }} onClick={() => history.push('/settings')}></span> */}

                        <span className="ml-2">
                            <Image
                                src={img ? `${baseURL}/${img}` : dp}
                                preview
                                zoomSrc={img ? `${baseURL}/${img}` : dp}
                                className=''
                                alt='dp'
                                width="45"
                                height="45"
                                imageStyle={{ borderRadius: "50%" }}
                            />
                        </span>

                        <div className="flex flex-column ml-4">
                            <span style={{ color: "black", fontWeight: 'bold' }}> {user_name} </span>
                            <span style={{ color: "gray" }}>{roletypeid == '1' && !warehouseid ? 'Super Admin' : roletypeid == '2' && usertype_id == '1' ? 'System User' : roletypeid == '2' && warehouseid ? 'Admin' : null} </span>
                        </div>
                        <i className="pi pi-sign-out logout-red-color ml-4" onClick={confirm1} title="Logout" />
                    </li>
                </ul>

            </div>
        </>
    );
};
