import React, { useState, useEffect, useContext, useRef } from "react";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import './Dashboard.scss'
import { Chart } from 'primereact/chart';
import 'react-circular-progressbar/dist/styles.css';
import { Calendar } from 'primereact/calendar';
import { DataTable } from "primereact/datatable";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { Column } from "jspdf-autotable";
import Registered from "../../assets/imgs/Registered.svg"
import activeUsers from "../../assets/warehouse-assets/report-icons/svg/active.png"
import inActiveUsers from "../../assets/warehouse-assets/report-icons/svg/inactive.png"
import moment from "moment";
import { Dropdown } from "primereact/dropdown";

const SuperAdminDashboard = () => {

    const history = useHistory()
    const dispatch = useDispatch()
    const user_id = localStorage.getItem("user_id")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")

    const [isActive, setIsActive] = useState(false);
    const [warehouseid, setWarehouseId] = useState('')
    const [allWH, setAllWH] = useState([])
    const [totalRegisteredUser, setTotalRegisteredUser] = useState('')
    const [activeMonthsUsers, setActiveMonthsUsers] = useState('')
    const [deActiveMonthsUsers, setDeActiveMonthsUsers] = useState()

    const [lowLevelData, setLowLevelData] = useState([])

    const [valueInventoryData, setValueInventoryData] = useState({});
    const [valueInventoryOptions, setValueInventoryOptions] = useState({});
    const [valueAssetData, setValueAssetData] = useState({});
    const [valueAssetOptions, setValueAssetOptions] = useState({});
    const [userValueOfInventoryData, setUserValueOfInventoryData] = useState({});
    const [userValueOfInventoryOptions, setUserValueOfInventoryOptions] = useState({});

    const [valueInvFromDate, setValueInvFromDate] = useState(moment().subtract(30, 'days').toDate())
    const [valueInvToDate, setValueInvToDate] = useState(moment().toDate())

    const [valueAssetFromDate, setValueAssetFromDate] = useState(moment().subtract(30, 'days').toDate())
    const [valueAssetToDate, setValueAssetToDate] = useState(moment().toDate())

    const [userInventoryFromDate, setUserInventoryFromDate] = useState(moment().subtract(30, 'days').toDate())
    const [userInventoryToDate, setUserInventoryToDate] = useState(moment().toDate())

    const getTotalActiveUser = async () => {
        const response = await dispatch(handleGetRequest(`/api/Dashboard/GetTotalActiveUsers?warehouseid=${warehouseid?.obj_id || 0}`))
        if (response?.statusCode === "200") {
            setTotalRegisteredUser(response?.body[0]?.activeUserCount)
        }
    }

    const getActivatedUsersByMonth = async () => {
        const response = await dispatch(handleGetRequest(`/api/Dashboard/GetTotalActiveUsersLast6Month?warehouseid=${warehouseid?.obj_id || 0}`))
        if (response?.statusCode === "200") {
            setActiveMonthsUsers(response?.body[0]?.activeUserCount)
        }
    }

    const getDeActivatedUsersByMonth = async () => {
        const response = await dispatch(handleGetRequest(`/api/Dashboard/GetTotalDeactivateUsersLast6month?warehouseid=${warehouseid?.obj_id || 0}`))
        if (response?.statusCode === "200") {
            setDeActiveMonthsUsers(response?.body[0]?.activeUserCount)
        }
    }

    const getValueInventorySupply = async () => {
        setIsActive(true);
        const fromDate = valueInvFromDate ? moment(valueInvFromDate).format('YYYY-MM-DD') : null;
        const toDate = valueInvToDate ? moment(valueInvToDate).format('YYYY-MM-DD') : null;
        const response = await dispatch(handleGetRequest(`/api/Dashboard/GetTopVendorsByInventoryValue?fromdate=${fromDate}&todate=${toDate}&warehouse_id=${warehouseid?.obj_id || 0}`));
        setIsActive(false);

        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

        const data = {
            labels: response?.statusCode === "200" ? response?.body?.labels : [],
            datasets: [
                {
                    label: 'Top Vendors by Inventory Supply',
                    backgroundColor: 'rgba(106, 78, 209, 0.6)',
                    borderColor: '#2d8eff',
                    data: response?.statusCode === "200" ? response?.body?.data : [],
                    barThickness: 50,
                },
            ]
        };

        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        fontColor: textColor,
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: "rgba(40, 75, 117, 1)",
                        font: {
                            weight: 500
                        },
                        callback: function (value) {
                            if (this.getLabelForValue(value).length > 10) {
                                return this.getLabelForValue(value).substr(0, 10) + "... "
                            } else {
                                return this.getLabelForValue(value).substr(0, 10)
                            }
                        }
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    type: 'logarithmic',
                    ticks: {
                        color: "rgba(40, 75, 117, 1)",
                        callback: function (value) {
                            return value;
                    }
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            }

        }
    };

    setValueInventoryData(data);
    setValueInventoryOptions(options);
};

const getValueAssetSupply = async () => {
    setIsActive(true)
    const fromDate = valueAssetFromDate ? moment(valueAssetFromDate).format('YYYY-MM-DD') : null;
    const toDate = valueAssetToDate ? moment(valueAssetToDate).format('YYYY-MM-DD') : null;
    const response = await dispatch(handleGetRequest(`/api/Dashboard/GetTopVendorsByAssetValue?fromdate=${fromDate}&todate=${toDate}&warehouse_id=${warehouseid?.obj_id || 0}`))
    setIsActive(false)

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    const data = {
        labels: response?.statusCode === "200" ? response?.body?.labels : [],
        datasets: [
            {
                label: 'Top Vendors by Asset Supply',
                backgroundColor: 'rgba(106, 78, 209, 0.6)',
                borderColor: '#2d8eff',
                data: response?.statusCode === "200" ? response?.body?.data : [],
                barThickness: 50,
            },
        ]
    };
    const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                labels: {
                    fontColor: textColor,
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: "rgba(40, 75, 117, 1)",
                    font: {
                        weight: 500
                    },
                    callback: function (value) {
                        if (this.getLabelForValue(value).length > 10) {
                            return this.getLabelForValue(value).substr(0, 10) + "... "
                        } else {
                            return this.getLabelForValue(value).substr(0, 10)
                        }
                    }
                },
                grid: {
                    display: false,
                    drawBorder: false
                },

            },
            y: {
                ticks: {
                    color: "rgba(40, 75, 117, 1)"
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            }
        }
    };

    setValueAssetData(data);
    setValueAssetOptions(options);
};

const getUserValueOfInventory = async () => {
    setIsActive(true)
    const fromDate = userInventoryFromDate ? moment(userInventoryFromDate).format('YYYY-MM-DD') : null;
    const toDate = userInventoryToDate ? moment(userInventoryToDate).format('YYYY-MM-DD') : null;
    const response = await dispatch(handleGetRequest(`/api/Dashboard/GetTopUsersByInventoryValue?fromdate=${fromDate}&todate=${toDate}&warehouse_id=${warehouseid?.obj_id || 0}`))
    setIsActive(false)
    const documentStyle = getComputedStyle(document.documentElement);

    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    const data = {
        labels: response?.statusCode === "200" ? response?.body?.labels : [],
        datasets: [
            {
                label: 'User Value of Inventory',
                backgroundColor: 'rgba(141, 122, 213, 0.6)',
                data: response?.statusCode === "200" ? response?.body?.data : [],
                barThickness: 50
            },
        ]
    };
    const options = {
        indexAxis: 'y',
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                labels: {
                    fontColor: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: "rgba(40, 75, 117, 1)",
                    font: {
                        weight: 500
                    }
                },
                grid: {
                    display: false,
                    drawBorder: false
                }
            },
            y: {
                ticks: {
                    color: "rgba(40, 75, 117, 1)",
                    callback: function (value) {
                        if (this.getLabelForValue(value).length > 10) {
                            return this.getLabelForValue(value).substr(0, 10) + "... "
                        } else {
                            return this.getLabelForValue(value).substr(0, 10)
                        }
                    }
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            }
        }
    };
    setUserValueOfInventoryData(data);
    setUserValueOfInventoryOptions(options);
}

//Get WH List
const getWHList = async () => {

    setIsActive(true);
    const response = await dispatch(handleGetRequest(`/api/Setups/GetAllWarehouse`, true));
    if (response) {
        setAllWH(response);
    }
    setIsActive(false);
};

//Low Level List
const getLowLevelNew = async () => {

    setIsActive(true)
    const response = await dispatch(handleGetRequest(`/api/Dashboard/GetLowLevelitems?warehouse_id=${warehouseid?.obj_id || 0}`))
    if (response?.statusCode == 200) {
        setLowLevelData(response?.body);
    }
    setIsActive(false)
};

const lowLevelItemHeader = () => (
    <>
        <div className="block bg-grey-500">
            <h6 className=" mb-0 p-2 text-lg font-semibold">Low Level Items</h6>
        </div>
    </>
)

const handleLowLevelData = () => {
    history.push({
        pathname: './lowlevellist',
        state: { warehouseId: warehouseid?.obj_id }
    });
};

useEffect(() => {

    getTotalActiveUser()
    getDeActivatedUsersByMonth()
    getActivatedUsersByMonth()
    getWHList()
    getLowLevelNew()

}, [])

useEffect(() => {
    if (warehouseid) {
        getTotalActiveUser()
        getActivatedUsersByMonth()
        getDeActivatedUsersByMonth()
        getLowLevelNew()
    }
}, [warehouseid])

useEffect(() => {
    if (valueInvFromDate && valueInvToDate && warehouseid === undefined || (warehouseid === undefined)) {
        getTotalActiveUser()
        getActivatedUsersByMonth()
        getDeActivatedUsersByMonth()
        getLowLevelNew()
    }
}, [valueInvFromDate, valueInvToDate, warehouseid])

// getValueInventorySupply 
useEffect(() => {
    if ((valueInvFromDate && valueInvToDate) || warehouseid) {
        getValueInventorySupply()
    }
}, [valueInvFromDate, valueInvToDate, warehouseid])

// getValueAssetSupply
useEffect(() => {
    if ((valueAssetFromDate && valueAssetToDate) || warehouseid) {
        getValueAssetSupply()
    }
}, [valueAssetFromDate, valueAssetToDate, warehouseid])

// getUserValueOfInventory
useEffect(() => {
    if ((userInventoryFromDate && userInventoryToDate) || warehouseid) {
        getUserValueOfInventory()
    }
}, [userInventoryFromDate, userInventoryToDate, warehouseid])

return (
    <>
        <LoadingOverlay
            active={isActive}
            spinner={<Triangle
                height="120"
                width="120"
                color="#755fc8"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            />}

            // text='Loading your content...'
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: 'rgb(38 41 51 / 78%)',
                })
            }}
        >
            <div className="dashboard-padding">
                <div className="grid">
                    <div className="col-12 p-2 xl:col-12 md:col-12 ">
                        <div className="card superDashboard-resp" style={{ justifyContent: "space-between" }} >
                            <span style={{ lineHeight: "40px", fontWeight: "600", fontSize: "18px", color: "gray" }}>
                                Super Admin Dashboard
                            </span>
                            <span className="flex flex-row">
                                <Dropdown
                                    showClear
                                    filter
                                    filterBy="obj_Name"
                                    placeholder='-- Select Warehouse --'
                                    id="warehouseId"
                                    name="warehouseId"
                                    options={allWH}
                                    optionLabel='obj_Name'
                                    optionValue='obj_id'
                                    value={warehouseid?.obj_id}
                                    onChange={(e) => {
                                        const selectedWarehouse = allWH?.find(wh => wh?.obj_id === e?.value);
                                        setWarehouseId(selectedWarehouse);
                                    }}
                                    className="custom-dropdown"
                                ></Dropdown>
                            </span>
                        </div>
                    </div>
                </div>

                <div className="grid ">
                    <div className="col-12 xl:col-4 md:col-6">
                        <div className="card card-gradient p-5">
                            <div>
                                <div className="h-3rem w-6rem" >
                                    <img className="h-full" src={Registered} />
                                </div>
                                <div className="flex justify-content-between align-items-end">
                                    <h5 className="card-text mt-4 mb-1 text-lg ">Total Registered Users</h5>
                                    <h2 className="font-bold mb-0 text-2xl">{totalRegisteredUser}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 xl:col-4 md:col-6 h-full">
                        <div className="card card-gradient p-5">
                            <div>
                                <div className="h-3rem w-6rem" >
                                    <img className="h-full" src={activeUsers} />
                                </div>
                                <div className="flex justify-content-between align-items-end">
                                    <h5 className="card-text mt-4 mb-1 text-lg">Activated Users <small style={{ fontSize: "12px" }}>(Last 6 months)</small></h5>
                                    <h2 className="font-bold mb-0 text-2xl">{activeMonthsUsers}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 xl:col-4 md:col-6">
                        <div className="card card-gradient p-5">
                            <div>
                                <div className="h-3rem w-6rem" >
                                    <img className="h-full" src={inActiveUsers} />
                                </div>
                                <div className="flex justify-content-between align-items-end">
                                    <h5 className="card-text mt-4 mb-1 text-lg">Deactivated Users <small style={{ fontSize: "12px" }}>(Last 6 months)</small></h5>
                                    <h2 className="font-bold mb-0 text-2xl">{deActiveMonthsUsers}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid mt-2">

                    {/* Inventory value of vendors*/}
                    <div className="col-12 lg:col-6 md:col-6">
                        <div className="card p-3 h-full">
                            <div className="flex flex-column xl:flex-row justify-content-between xl:align-items-center  ">
                                <div className="flex flex-column">
                                    <div className="py-2 text-lg" style={{ color: "#284b75", fontWeight: '600' }}>Top 5 Vendors <small>{warehouseid ? `(${warehouseid?.obj_Name})` : null}</small></div>
                                    <small style={{ color: "#284b75", fontWeight: '600' }}>(Value supplying inventory)</small>
                                </div>
                                <div className="flex  justify-content-end align-items-center w-max-content">
                                    <div className="w-9rem my-3 xl:my-0 mr-4">
                                        <label>From Date</label>
                                        <Calendar className="" placeholder="mm/dd/yyyy" onChange={(e) => setValueInvFromDate(e?.target?.value)} value={valueInvFromDate} maxDate={valueInvToDate} />
                                    </div>
                                    <div className="w-9rem">
                                        <label id="name">To Date</label>
                                        <Calendar className="" placeholder="mm/dd/yyyy" onChange={(e) => setValueInvToDate(e?.target?.value)} value={valueInvToDate} maxDate={new Date()} minDate={valueInvFromDate} />
                                    </div>
                                </div>
                            </div>
                            <Chart type="bar" className='mt-5' data={valueInventoryData} options={valueInventoryOptions} />
                        </div>
                    </div>

                    {/* Assets Value of Vendors*/}
                    <div className="col-12 lg:col-6 md:col-6 ">
                        <div className="card p-3 h-full">
                            <div className="flex flex-column xl:flex-row justify-content-between xl:align-items-center  ">
                                <div className="flex flex-column">
                                    <div className="py-2 text-lg" style={{ color: "#284b75", fontWeight: '600' }}>Top 5 Vendors <small>{warehouseid ? `(${warehouseid?.obj_Name})` : null}</small></div>
                                    <small style={{ color: "#284b75", fontWeight: '600' }}>(Total assets quantity)</small>
                                </div>
                                <div className="flex  justify-content-end align-items-center w-max-content">
                                    <div className="w-9rem my-3 xl:my-0 mr-4">
                                        <label>From Date</label>
                                        <Calendar className="" placeholder="mm/dd/yyyy" onChange={(e) => setValueAssetFromDate(e?.target?.value)} value={valueAssetFromDate} maxDate={valueAssetToDate} />
                                    </div>
                                    <div className="w-9rem">
                                        <label id="name">To Date</label>
                                        <Calendar className="" placeholder="mm/dd/yyyy" onChange={(e) => setValueAssetToDate(e?.target?.value)} value={valueAssetToDate} maxDate={new Date()} minDate={valueAssetFromDate} />
                                    </div>
                                </div>
                            </div>
                            <Chart type="bar" className='mt-5' data={valueAssetData} options={valueAssetOptions} />
                        </div>
                    </div>
                </div>

                <div className="grid mt-2">
                    <div className="col-12 lg:col-6 md:col-6 ">
                        <div className="card p-3 h-full">
                            <div className="flex flex-column xl:flex-row justify-content-between xl:align-items-center  ">
                                <div className="flex flex-column">
                                    <div className="py-2 text-lg" style={{ color: "#284b75", fontWeight: '600' }}>Top 5 Users <small>{warehouseid ? `(${warehouseid?.obj_Name})` : null}</small></div>
                                    <small style={{ color: "#284b75", fontWeight: '600' }}>(Value of inventory)</small>
                                </div>
                                <div className="flex  justify-content-end align-items-center w-max-content">
                                    <div className="w-9rem my-3 xl:my-0 mr-4">
                                        <label>From Date</label>
                                        <Calendar className="" placeholder="mm/dd/yyyy" onChange={(e) => setUserInventoryFromDate(e?.target?.value)} value={userInventoryFromDate} maxDate={userInventoryToDate} />
                                    </div>
                                    <div className="w-9rem">
                                        <label id="name">To Date</label>
                                        <Calendar className="" placeholder="mm/dd/yyyy" onChange={(e) => setUserInventoryToDate(e?.target?.value)} maxDate={new Date()} value={userInventoryToDate} minDate={userInventoryFromDate} />
                                    </div>
                                </div>
                            </div>
                            <Chart type="bar" className='mt-5' data={userValueOfInventoryData} options={userValueOfInventoryOptions} />
                        </div>
                    </div>

                    {/* Low level Items*/}
                    <div className="col-12 lg:col-6 md:col-6 ">
                        <div className="card flex flex-column justify-content-between align-items-center p-3 h-full">
                            <DataTable
                                header={lowLevelItemHeader}
                                value={lowLevelData?.slice(0, 6)}
                                style={{ border: "1px solid lightgrey" }}
                                className="dashboardListItem "
                                emptyMessage="No data found"
                                stripedRows
                            >
                                <Column className="justify-content-around" header="Warehouse" field="warehouse" ></Column>
                                <Column className="justify-content-around" header="Item Name" field="name" ></Column>
                                <Column className="justify-content-around" header="Min Threshold Value" field="min_t_value" ></Column>
                                <Column className="justify-content-around" header="Balance Quantity" field="bal_qty" ></Column>
                            </DataTable>
                            {lowLevelData?.length
                                ?
                                <span className="flex mb-4" style={{ alignSelf: "end", fontWeight: "500", color: "black", fontSize: "16px", cursor: 'pointer', }} onClick={() => handleLowLevelData()}> Show More ...</span>
                                :
                                null}

                        </div>
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    </>
);
};

export default SuperAdminDashboard