import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import LoadingOverlay from 'react-loading-overlay';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { handleGetRequest } from '../../services/GetTemplate';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../services/PostTemplate';
import { handlePutRequest } from '../../services/PutTemplate';
import { useHistory, useLocation } from "react-router-dom";

const AddEditRoles = () => {

    const location = useLocation();
    const { isEditable, rowData } = location?.state;
    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const [isActive, setIsActive] = useState(false)

    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")

    const history = useHistory()
    const dispatch = useDispatch()

    const validationSchema = Yup.object().shape({
        name: Yup.mixed().required("This field is required."),
        description: Yup.mixed().required("This field is required."),

    });

    const formik = useFormik({

        validationSchema: validationSchema,
        initialValues: {
            name: "",
            description: "",

        },

        onSubmit: async (data) => {

            if (isEditable) {
                setSaveBtnLoading(true);

                let obj = {
                    role_id: parseInt(rowData?.id),
                    RoleName: formik.values.name,
                    RoleDescription: formik.values.description,                 
                }

                const response = await dispatch(handlePutRequest(obj, "/api/Authentication/UpdateRole", true, true));
                if (response?.statusCode == 200) {
                    history.push('./roles')
                    setSaveBtnLoading(false);
                }
            }
            else {
                setSaveBtnLoading(true);

                let obj = {
                    roleName: formik.values.name,
                    roleDescription: formik.values.description,
                    mRoletype_id: parseInt(roletypeid),
                    mUsertype_id: parseInt(usertype_id),
                    mWarehouse_id: parseInt(warehouseid) || 0,
                }

                const response = await dispatch(handlePostRequest(obj, "/api/Authentication/Addnewrole", false, true));
                if (response?.statusCode == 200) {
                    history.push('./roles')
                    setSaveBtnLoading(false);
                }

                setSaveBtnLoading(false);
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    //Get Role By Id
    const getRoleById = async (rowData) => {
        setIsActive(true)
        const response = await dispatch(handleGetRequest(`/api/Authentication/Getrolebyid?role_id=${rowData?.id}`, false));
        const keyData = response[0]
        if (keyData) {
            formik.setFieldValue('name', keyData?.roleName)
            formik.setFieldValue('description', keyData?.roleDescription)
        }
        setIsActive(false)
    };

    useEffect(() => {
        if (isEditable, rowData) {
            getRoleById(rowData);
        }
    }, [isEditable, rowData]);

    const ResetForm = () => {
        formik.setFieldValue('name', '')
        formik.setFieldValue('description', '')
    }

    return (

        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgb(38 41 51 / 78%)',
                        width: '107.9%',
                        height: '125%',
                        top: '-27px',
                        left: '-35px'
                    })
                }}
            >

                <div className='main-form-card'>
                    <div className='form-header flex flex-row justify-content-between'>
                        <div className='form-header-content'>
                            <h4 style={{ fontWeight: '600', letterSpacing: '1px', color: 'black' }}>{isEditable ? 'Edit Role' : 'Add New Role'}</h4>
                            <h6 style={{ color: 'gray' }}> Add Details</h6>
                        </div>
                        <div className='mt-4 mr-3'>
                            <Button className='Back-Button mr-3' icon="pi pi-arrow-left" label='Go Back' onClick={() => history.goBack()}>
                            </Button>
                        </div>
                    </div>

                    <div className='form-div'>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="card-form">
                                <div className="card-nested-form">
                                    <div class="grid">
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Role Name </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText
                                                    maxLength={25}
                                                    className="p-inputtext-sm" id="name" name="name" placeholder='Enter Role Name' value={formik.values.name} onChange={formik.handleChange} type="text" />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("name")}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-12 lg:col-8 md:col-8">
                                            <div className="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Description </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText maxLength={40} className="p-inputtext-sm" id="description" name="description" placeholder='Enter Description' value={formik.values.description} onChange={formik.handleChange} type="text" />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("description")}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-12 lg:col-4 md:col-4">
                                        </div>

                                    </div>

                                    <div className='col-12 d-flex flex-row text-center mt-4 pb-2'>
                                        <Button className="Cancel-Button" label="Reset" type="button" onClick={ResetForm} />
                                        <Button className="Save-Button ml-2" label={isEditable ? "Update" : "Add"} loading={saveBtnLoading} type="submit" />
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </LoadingOverlay>
        </>
    )
}

export default AddEditRoles