import React, { useState, useEffect } from "react";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import './Dashboard.scss'
import { Chart } from 'primereact/chart';
import 'react-circular-progressbar/dist/styles.css';
import { Calendar } from 'primereact/calendar';
import { DataTable } from "primereact/datatable";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { Column } from "jspdf-autotable";
import Inventory from "../../assets/imgs/inventory.svg"
import Assets from "../../assets/imgs/Assets.svg"
import Registered from "../../assets/imgs/Registered.svg"
import activeUsers from "../../assets/warehouse-assets/report-icons/svg/active.png"
import inActiveUsers from "../../assets/warehouse-assets/report-icons/svg/inactive.png"
import moment from "moment";
import SuperAdminDashboard from "./SuperAdminDashboard";

const Dashboard = () => {

    const history = useHistory()
    const dispatch = useDispatch()
    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")

    const [totalInventory, setTotalInventory] = useState('')
    const [totalAsset, setTotalAsset] = useState('')
    const [totalRegisteredUser, setTotalRegisteredUser] = useState('')
    const [totalActiveUser, setTotalActiveUser] = useState('')
    const [totalInactiveUser, setTotalInactiveUser] = useState('')

    const [consumableFromDate, setConsumableFromDate] = useState(moment().subtract(30, 'days').toDate())
    const [consumableToDate, setConsumableToDate] = useState(moment().toDate())
    const [userConsumableData, setUserConsumableData] = useState({});
    const [userConsumableOptions, setUserConsumableOptions] = useState({});

    const [borrowAssetFromDate, setBorrowAssetFromDate] = useState(moment().subtract(30, 'days').toDate())
    const [borrowAssetToDate, setBorrowAssetToDate] = useState(moment().toDate())
    const [borrowAssetData, setBorrowAssetData] = useState({})
    const [borrowAssetOption, setBorrowAssetOption] = useState({})

    const [returnItemFromDate, setReturnItemFromDate] = useState(moment().subtract(30, 'days').toDate());
    const [returnItemToDate, setReturnItemToDate] = useState(moment().toDate());
    const [returnItemData, setReturnItemData] = useState({});
    const [returnItemOptions, setReturnItemOptions] = useState({});

    const [replenishedItem, setReplenishedItem] = useState([])
    const [expiryItems, setExpiryItems] = useState([]);

    const [frequentUserFromDate, setFrequentUserFromDate] = useState(moment().subtract(30, 'days').toDate())
    const [frequentUserToDate, setFrequentUserToDate] = useState(moment().toDate())
    const [frequentUserData, setFrequentUserData] = useState({})
    const [frequentUserOptions, setFrequentUserOptions] = useState({})

    const [checkoutFromDate, setCheckoutFromDate] = useState(moment().subtract(30, 'days').toDate())
    const [checkoutToDate, setCheckoutToDate] = useState(moment().toDate())
    const [checkoutData, setcheckoutData] = useState({})
    const [checkoutOption, setcheckoutOption] = useState({})

    const [isActive, setIsActive] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState()

    const getTotalInventory = async () => {
        const response = await dispatch(handleGetRequest(`/api/Dashboard/GetTotalInventory?warehouse_id=${warehouseid || 0}`))
        if (response?.statusCode === "200") {
            setTotalInventory(response?.body[0]?.totalInventory)
        }
    }

    const getTotalAssets = async () => {
        const response = await dispatch(handleGetRequest(`/api/Dashboard/GetTotalAsset?warehouse_id=${warehouseid || 0}`))
        if (response?.statusCode === "200") {
            setTotalAsset(response?.body[0]?.totalassets)
        }
    }

    const getTotalRegisteredUser = async () => {
        const response = await dispatch(handleGetRequest(`/api/Dashboard/GetTotalUsers?warehouseid=${warehouseid || 0}`))
        if (response?.statusCode === "200") {
            setTotalRegisteredUser(response?.body[0]?.totalUsers)
            setTotalActiveUser(response?.body[0]?.activeUsers)
            setTotalInactiveUser(response?.body[0]?.inactiveUsers)
        }
    }

    const getuserConsumableData = async () => {
        setIsActive(true)
        const fromDate = consumableFromDate ? moment(consumableFromDate).format('YYYY-MM-DD') : null;
        const toDate = consumableToDate ? moment(consumableToDate).format('YYYY-MM-DD') : null;
        const response = await dispatch(handleGetRequest(`/api/Dashboard/GetTop5ConsumablesUsers?fromdate=${fromDate}&todate=${toDate}&warehouse_id=${warehouseid || 0}`))
        setIsActive(false)
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: response?.statusCode === "200" ? response?.body?.labels : [],
            datasets: [
                {
                    label: 'Users for Consumables',
                    backgroundColor: 'rgba(106, 78, 209, 0.6)',
                    borderColor: '#2d8eff',
                    data: response?.statusCode === "200" ? response?.body?.data : [],
                    barThickness: 50,
                },
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        fontColor: textColor,
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: "rgba(40, 75, 117, 1)",
                        font: {
                            weight: 500
                        },
                        callback: function (value) {
                            if (this.getLabelForValue(value).length > 9) {
                                return this.getLabelForValue(value).substr(0, 9) + "... ";
                            } else {
                                return this.getLabelForValue(value).substr(0, 9);
                            }
                        }
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    ticks: {
                        color: "rgba(40, 75, 117, 1)",
                        callback: function (value) {
                            return Number.isInteger(value) ? value : '';
                        }
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        };
        

        setUserConsumableData(data);
        setUserConsumableOptions(options);
    };

    const getExpiryItems = async () => {
        setIsActive(true)
        const response = await dispatch(handleGetRequest(`/api/Dashboard/GetTopNearExpiryitems?warehouse_id=${warehouseid || 0}`))
        if (response?.statusCode === "200") {
            setExpiryItems(response?.body)
        }
        setIsActive(false)
    }

    const getBorrowAssetData = async () => {
        setIsActive(true)
        const fromDate = borrowAssetFromDate ? moment(borrowAssetFromDate).format('YYYY-MM-DD') : null;
        const toDate = borrowAssetToDate ? moment(borrowAssetToDate).format('YYYY-MM-DD') : null;
        const response = await dispatch(handleGetRequest(`/api/Dashboard/GetTop5Borrowers?fromdate=${fromDate}&todate=${toDate}&warehouse_id=${warehouseid || 0}`))
        setIsActive(false)
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

        const data = {
            labels: response?.statusCode === "200" ? response?.body?.labels : [],
            datasets: [
                {
                    label: 'Borrow Assets',
                    backgroundColor: 'rgba(141, 122, 213, 0.6)',
                    data: response?.statusCode === "200" ? response?.body?.data : [],
                    barThickness: 50
                },
                // {
                //     label: 'My Second dataset',
                //     backgroundColor: 'rgba(106, 78, 209, 0.7)',
                //     // borderColor: documentStyle.getPropertyValue('--pink-500'),
                //     data: [28, 48, 40, 19, 86, 27, 90]
                // }
            ]
        };
        const options = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        fontColor: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: "rgba(40, 75, 117, 1)",
                        font: {
                            weight: 500
                        }
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    ticks: {
                        color: "rgba(40, 75, 117, 1)",
                        callback: function (value) {
                            if (this.getLabelForValue(value).length > 10) {
                                return this.getLabelForValue(value).substr(0, 10) + "... "
                            } else {
                                return this.getLabelForValue(value).substr(0, 10)
                            }
                        }
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        };
        setBorrowAssetData(data);
        setBorrowAssetOption(options);
    }

    const getReplenishedItem = async () => {
        setIsActive(true)
        const response = await dispatch(handleGetRequest(`/api/Dashboard/GetReplenisheditems?warehouse_id=${warehouseid || 0}`))
        if (response?.statusCode === "200") {
            setReplenishedItem(response?.body)
        }
        setIsActive(false)
    }

    const getReturnItemData = async () => {
        setIsActive(true)
        const fromDate = returnItemFromDate ? moment(returnItemFromDate).format('YYYY-MM-DD') : null;
        const toDate = returnItemToDate ? moment(returnItemToDate).format('YYYY-MM-DD') : null;
        const response = await dispatch(handleGetRequest(`/api/Dashboard/GetTop5ReturnItems?fromdate=${fromDate}&todate=${toDate}&warehouse_id=${warehouseid || 0}`))
        setIsActive(false)

        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: response?.statusCode === "200" ? response?.body?.labels : [],
            datasets: [
                {
                    label: 'Return Items',
                    backgroundColor: 'rgba(107, 79, 209, 0.35)',
                    borderColor: '#2d8eff',
                    data: response?.statusCode === "200" ? response?.body?.data : [],
                    barThickness: 50,
                },
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        fontColor: textColor,
                    }
                }
            },
            scales: {
                x: {

                    ticks: {
                        color: "rgba(40, 75, 117, 1)",
                        font: {
                            weight: 500
                        },
                        callback: function (value) {
                            if (this.getLabelForValue(value).length > 10) {
                                return this.getLabelForValue(value).substr(0, 10) + "... "
                            } else {
                                return this.getLabelForValue(value).substr(0, 10)
                            }
                        }
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    ticks: {
                        color: "rgba(40, 75, 117, 1)"
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        };

        setReturnItemData(data);
        setReturnItemOptions(options);
    }

    const getCheckoutData = async () => {
        setIsActive(true)
        const fromDate = checkoutFromDate ? moment(checkoutFromDate).format('YYYY-MM-DD') : null;
        const toDate = checkoutToDate ? moment(checkoutToDate).format('YYYY-MM-DD') : null;
        const response = await dispatch(handleGetRequest(`/api/Dashboard/GetTop10CheckOutitems?fromdate=${fromDate}&todate=${toDate}&warehouse_id=${warehouseid || 0}`))
        setIsActive(false)
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: response?.statusCode === "200" ? response?.body?.labels : [],
            datasets: [
                {
                    type: 'bar',
                    label: 'Checkout Inventory',
                    backgroundColor: 'rgba(106, 78, 209, 0.5)',
                    data: response?.statusCode === "200" ? response?.body?.data : [],
                    barThickness: 50
                },

            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary,
                        callback: function (value) {
                            if (this.getLabelForValue(value).length > 15) {
                                return this.getLabelForValue(value).substr(0, 15) + "... "
                            } else {
                                return this.getLabelForValue(value).substr(0, 15)
                            }
                        }
                    },

                    grid: {
                        color: surfaceBorder
                    },

                },
                y: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };


        setcheckoutData(data);
        setcheckoutOption(options);
    }

    const getFrequentUserData = async () => {
        setIsActive(true)
        const fromDate = frequentUserFromDate ? moment(frequentUserFromDate).format('YYYY-MM-DD') : null;
        const toDate = frequentUserToDate ? moment(frequentUserToDate).format('YYYY-MM-DD') : null;
        const response = await dispatch(handleGetRequest(`/api/Dashboard/GetTop5FrequentUsers?fromdate=${fromDate}&todate=${toDate}&warehouse_id=${warehouseid || 0}`))
        setIsActive(false)
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');


        const data = {
            labels: response?.statusCode === "200" ? response?.body?.labels : [],
            datasets: [
                {
                    label: 'Frequent Users',
                    backgroundColor: 'rgba(141, 122, 213, 0.7)',
                    data: response?.statusCode === "200" ? response?.body?.data : [],
                    barThickness: 50
                },
            ]
        };

        const options = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        fontColor: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: "rgba(40, 75, 117, 1)",
                        font: {
                            weight: 500
                        },

                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    ticks: {
                        color: "rgba(40, 75, 117, 1)",
                        callback: function (value) {
                            if (this.getLabelForValue(value).length > 10) {
                                return this.getLabelForValue(value).substr(0, 10) + "... "
                            } else {
                                return this.getLabelForValue(value).substr(0, 10)
                            }
                        }
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        };

        setFrequentUserData(data)
        setFrequentUserOptions(options)
    }

    useEffect(() => {
        if ((roletypeid == 1) || (roletypeid == 2 && usertype_id == 1 && !warehouseid)) {
            setIsSuperAdmin(true)
        } else {
            setIsSuperAdmin(false)
        }

        getTotalInventory()
        getTotalAssets()
        getTotalRegisteredUser()
        getExpiryItems()
        getReplenishedItem()

    }, [])

    const replenishedItemTemplates = (item) => {
        return (
            <>
                <div className=" flex align-items-center" style={{ gap: "0.5rem" }}>
                    <i className="pi pi-sort-amount-down-alt text-md" style={{ color: "red" }}></i>
                    <span className="font-semibold" style={{ color: "red" }}>{item?.bal_qty}</span>
                </div>
            </>

        );
    };

    const expireDaysTemplate = (day) => {
        return (
            <>
                <div className=" flex justify-content-center align-items-center" style={{ gap: "0.5rem" }}>
                    <i className="pi pi-sort-amount-down-alt text-md" style={{ color: "red" }}></i>
                    <span className="font-semibold" style={{ color: "red" }}>{day?.days_until_expiry == 0 ? 'Today' : day?.days_until_expiry}</span>
                </div>
            </>
        );
    };

    const handleExpireData = () => {
        history.push({
            pathname: './checkin',
            state: {
                expireItemData: true,
            }
        });
    }

    const handleReplenishedData = () => {
        history.push({
            pathname: './replenisheditemreport',
            state: {
                replenishedItemsData: false
            }
        });
    }

    const expireItemHeader = () => (
        <>
            <div className="block bg-grey-500">
                <h6 className=" mb-0 p-2 text-lg font-semibold">Near Expiry Inventory</h6>
                <small className="pl-2" style={{ color: "#284b75", fontWeight: '600' }}>(Expiry in 20 days)</small>
            </div>
        </>
    )

    const replenishedItemHeader = () => (
        <>
            <div className="block bg-grey-500">
                <h6 className=" mb-0 p-2 text-lg font-semibold">Inventory Replenished Items</h6>
            </div>
        </>
    )

    // User of Consumables  
    useEffect(() => {
        if ((consumableFromDate && consumableToDate) || (!consumableFromDate && !consumableToDate)) {
            getuserConsumableData()
        }
    }, [consumableFromDate, consumableToDate])


    // Borrow assets
    useEffect(() => {
        if ((borrowAssetFromDate && borrowAssetToDate) || (!borrowAssetFromDate && !borrowAssetToDate)) {
            getBorrowAssetData()
        }
    }, [borrowAssetFromDate, borrowAssetToDate])

    // Checkout Inventory 
    useEffect(() => {
        if ((checkoutFromDate && checkoutToDate) || (!checkoutFromDate && !checkoutToDate)) {
            getCheckoutData()
        }
    }, [checkoutFromDate, checkoutToDate])

    // Return Items
    useEffect(() => {
        if ((returnItemFromDate && returnItemToDate) || (!returnItemFromDate && !returnItemToDate)) {
            getReturnItemData()
        }
    }, [returnItemFromDate, returnItemToDate])

    // Frequent User
    useEffect(() => {
        if ((frequentUserFromDate && frequentUserToDate) || (!frequentUserFromDate && !frequentUserToDate)) {
            getFrequentUserData()
        }
    }, [frequentUserFromDate, frequentUserToDate])

    function formatNumber(num) {
        if (num >= 1000000000) {
            return (num / 1000000000)?.toFixed(1) + 'B';
        } else if (num >= 1000000) {
            return (num / 1000000)?.toFixed(1) + 'M';
        } else if (num >= 1000) {
            return (num / 1000)?.toFixed(1) + 'K';
        } else {
            return num?.toString();
        }
    }

    return (
        <>
            <LoadingOverlay
                active={isActive}
                spinner={<Triangle
                    height="120"
                    width="120"
                    color="#755fc8"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />}

                // text='Loading your content...'
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgb(38 41 51 / 78%)',
                    })
                }}
            >
                {isSuperAdmin ?
                    <SuperAdminDashboard />
                    :
                    <div className="dashboarsd-padding">
                        <div className="grid ">
                            <div className="col-12 xl:col-3 lg:col-6 h-full">
                                <div className="card card-gradient p-5">
                                    <div>
                                        <div className="" style={{ width: "5rem", height: "3rem" }} >
                                            <img className="h-full" src={Inventory} />
                                        </div>
                                        <div className="flex justify-content-between align-items-end">
                                            <h5 className="card-text mt-4 mb-1 text-lg">Total Inventory</h5>
                                            <h2 className="font-bold mb-0 text-2xl" title={totalInventory}>{formatNumber(totalInventory) || 0}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 xl:col-3 lg:col-6 h-full">
                                <div className="card card-gradient p-5">
                                    <div>
                                        <div className="" style={{ width: "5rem", height: "3rem" }} >
                                            <img className="h-full" src={Assets} />
                                        </div>
                                        <div className="flex justify-content-between align-items-end">
                                            <h5 className="card-text mt-4 mb-1 text-lg">Total Assets</h5>
                                            <h2 className="font-bold mb-0 text-2xl">{totalAsset || 0}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 xl:col-3 lg:col-6 h-full">
                                <div className="card card-gradient p-5">
                                    <div>
                                        <div className="" style={{ width: "5rem", height: "3rem" }} >
                                            <img className="h-full" src={Registered} />
                                        </div>
                                        <div className="flex justify-content-between align-items-end">
                                            <h5 className="card-text mt-4 mb-1 text-lg">Total Users</h5>
                                            <h2 className="font-bold mb-0 text-2xl">{totalRegisteredUser || 0}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 xl:col-3 lg:col-6 h-full">
                                <div className="card card-gradient p-5">
                                    <div>
                                        <div className="flex flex-row" style={{ width: "5rem", height: "3rem" }} >
                                            <img className="h-full" src={activeUsers} />
                                            <span style={{ fontSize: "35px", color: "gray" }} className="ml-2 mr-2">/</span>
                                            <img className="h-full" src={inActiveUsers} />
                                        </div>
                                        <div className="flex justify-content-between align-items-end">
                                            <h5 className="card-text mt-4 mb-1 text-lg"><span style={{ color: "green" }}>Active</span> / <span style={{ color: "red" }}>Inactive</span></h5>
                                            <h2 className="font-bold mb-0 text-2xl"><span style={{ color: "green" }}>{totalActiveUser}</span> / <span style={{ color: "red" }}>{totalInactiveUser}</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="grid mt-4">

                            {/*Frequent Users*/}
                            <div className="col-12 lg:col-7 md:col-12">
                                <div className="card p-3 h-full">
                                    <div className="flex flex-column xl:flex-row justify-content-between xl:align-items-center  ">
                                        <div className="py-2 text-lg" style={{ color: "#284b75", fontWeight: '600' }}>Top 5 Frequent Users</div>
                                        <div className="flex  justify-content-end align-items-center w-max-content">
                                            <div className="w-9rem my-3 xl:my-0 mr-4">
                                                <label name="frequentUserFromDate">From Date</label>
                                                <Calendar className="" value={frequentUserFromDate} name="frequentUserFromDate" placeholder="mm/dd/yyyy" maxDate={frequentUserToDate} onChange={(e) => setFrequentUserFromDate(e?.target?.value)} />
                                            </div>
                                            <div className="w-9rem">
                                                <label name="frequentUserToDate">To Date</label>
                                                <Calendar className="" value={frequentUserToDate} name="frequentUserToDate" placeholder="mm/dd/yyyy" minDate={frequentUserFromDate} maxDate={new Date()} onChange={(e) => setFrequentUserToDate(e?.target?.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <Chart type="bar" data={frequentUserData} options={frequentUserOptions} />
                                    </div>

                                </div>
                            </div>

                            {/* Expiry Items*/}
                            <div className=" col-12 lg:col-5 md:col-12">
                                <div className="card flex flex-column justify-content-between align-items-center p-3 h-full">
                                    <DataTable
                                        header={expireItemHeader}
                                        value={expiryItems.slice(0, 5)}
                                        style={{ border: "1px solid lightgrey" }}
                                        className="dashboardListItem "
                                        emptyMessage="No data found"
                                        stripedRows
                                    >
                                        <Column className="justify-content-around" header="Item Name" field="inventory_name"></Column>
                                        <Column className="justify-content-around" header="Expire Date" body={(rowData) => moment(rowData?.expiry_date).format('YYYY-MM-DD')} field="expiry_date" ></Column>
                                        <Column className="justify-content-around" header="Days Until Expiry" body={expireDaysTemplate} field="days_until_expiry" ></Column>
                                    </DataTable>
                                    {
                                        expiryItems?.length > 8 ?
                                            <span className="flex mb-2" style={{ alignSelf: "end", fontWeight: "500", color: "black", fontSize: "15px", cursor: 'pointer', }} onClick={() => handleExpireData()}> Show More ...</span>
                                            : null
                                    }
                                </div>
                            </div>

                        </div>
                        <div className="grid mt-3">

                            {/* Users for consumables */}
                            <div className="col-12 lg:col-7 md:col-12">
                                <div className="card p-3 h-full">
                                    <div className="flex flex-column xl:flex-row justify-content-between xl:align-items-center  ">
                                        <div className="py-2 text-lg" style={{ color: "#284b75", fontWeight: '600' }}>Top 5 Users for Inventory Consumables</div>
                                        <div className="flex  justify-content-end align-items-center w-max-content">
                                            <div className="w-9rem my-3 xl:my-0 mr-4">
                                                <label name="replenistedItemFromDate">From Date</label>
                                                <Calendar className="" value={consumableFromDate} name="replenistedItemFromDate" placeholder="mm/dd/yyyy" maxDate={consumableToDate} onChange={(e) => setConsumableFromDate(e?.target?.value)} />
                                            </div>
                                            <div className="w-9rem">
                                                <label name="replenistedItemFromDate">To Date</label>
                                                <Calendar className="" value={consumableToDate} name="replenistedItemFromDate" placeholder="mm/dd/yyyy" minDate={consumableFromDate} maxDate={new Date()} onChange={(e) => setConsumableToDate(e?.target?.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <Chart type="bar" className='mt-5' data={userConsumableData} options={userConsumableOptions} />
                                </div>
                            </div>

                            {/* Replenished Items*/}
                            <div className=" col-12 lg:col-5 md:col-12">
                                <div className="card flex flex-column justify-content-between align-items-center p-3 h-full">
                                    <DataTable
                                        header={replenishedItemHeader}
                                        value={replenishedItem.slice(0, 5)}
                                        style={{ border: "1px solid lightgrey" }}
                                        className="dashboardListItem "
                                        emptyMessage="No data found"
                                        stripedRows
                                    >
                                        <Column className="justify-content-around" header="Item Name" field="name" ></Column>
                                        <Column className="justify-content-around" header="Min Threshold Value" field="min_t_value" ></Column>
                                        <Column className="justify-content-around" header="Replenished Quantity" field="replenishQty" ></Column>
                                        <Column className="justify-content-around" header="Stock Quantity" body={replenishedItemTemplates} field="quantity" ></Column>
                                    </DataTable>
                                    {
                                        replenishedItem.length > 8 ?
                                            <span className="flex mb-2" style={{ alignSelf: "end", fontWeight: "500", color: "black", fontSize: "15px", cursor: 'pointer', }} onClick={() => handleReplenishedData()}> Show More ...</span>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="grid mt-3">
                            {/* Checkout Inventory*/}
                            <div className="col-12 lg:col-12 md:col-12">
                                <div className="card p-3 h-full">
                                    <div className="flex flex-column xl:flex-row justify-content-between xl:align-items-center  ">
                                        <div className="py-2 text-lg" style={{ color: "#284b75", fontWeight: '600' }}>Top 10 Checkout Inventory Items</div>

                                        <div className="flex  justify-content-end align-items-center w-max-content">
                                            <div className="w-9rem my-3 xl:my-0 mr-4">
                                                <label>From Date</label>
                                                <Calendar className="" value={checkoutFromDate} name="checkoutFromDate" placeholder="mm/dd/yyyy" maxDate={checkoutToDate} onChange={(e) => setCheckoutFromDate(e?.target?.value)} />
                                            </div>
                                            <div className="w-9rem">
                                                <label id="name">To Date</label>
                                                <Calendar className="" value={checkoutToDate} name="checkoutToDate" placeholder="mm/dd/yyyy" minDate={checkoutFromDate} maxDate={new Date()} onChange={(e) => setCheckoutToDate(e?.target?.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <Chart type="line" data={checkoutData} options={checkoutOption} />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="grid mt-3">

                            {/* Borrow Assets */}
                            <div className="col-12 lg:col-6 md:col-12">
                                <div className="card p-3 h-full">
                                    <div className="flex flex-column xl:flex-row justify-content-between xl:align-items-center  ">
                                        <div className="py-2 text-lg" style={{ color: "#284b75", fontWeight: '600' }}>Top 5 Borrower Of the Assets</div>

                                        <div className="flex  justify-content-end align-items-center w-max-content">
                                            <div className="w-9rem my-3 xl:my-0 mr-4">
                                                <label name="borrowAssetFromDate">From Date</label>
                                                <Calendar className="" value={borrowAssetFromDate} name="borrowAssetFromDate" maxDate={borrowAssetToDate} placeholder="mm/dd/yyyy" onChange={(e) => { setBorrowAssetFromDate(e?.target?.value) }} />
                                            </div>
                                            <div className="w-9rem">
                                                <label name="borrowAssetToDate">To Date</label>
                                                <Calendar className="" value={borrowAssetToDate} name="borrowAssetToDate" minDate={borrowAssetFromDate} maxDate={new Date()} placeholder="mm/dd/yyyy" onChange={(e) => { setBorrowAssetToDate(e?.target?.value) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <Chart type="bar" data={borrowAssetData} options={borrowAssetOption} />
                                    </div>

                                </div>
                            </div>

                            {/*Return Items*/}
                            <div className="col-12 lg:col-6 md:col-12">
                                <div className="card p-3 h-full">
                                    <div className="flex flex-column xl:flex-row justify-content-between xl:align-items-center  ">
                                        <div className="py-2 text-lg" style={{ color: "#284b75", fontWeight: '600' }}>Top 5 Return Asset Items</div>

                                        <div className="flex  justify-content-end align-items-center w-max-content">
                                            <div className="w-9rem my-3 xl:my-0 mr-4">
                                                <label name="returnItemFromDate">From Date</label>
                                                <Calendar className="" value={returnItemFromDate} name="returnItemFromDate" placeholder="mm/dd/yyyy" maxDate={returnItemToDate} onChange={(e) => setReturnItemFromDate(e?.target?.value)} />
                                            </div>
                                            <div className="w-9rem">
                                                <label name="returnItemToDate">To Date</label>
                                                <Calendar className="" value={returnItemToDate} name="returnItemToDate" maxDate={new Date()} placeholder="mm/dd/yyyy" minDate={returnItemFromDate} onChange={(e) => setReturnItemToDate(e?.target?.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <Chart type="bar" data={returnItemData} options={returnItemOptions} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                }
            </LoadingOverlay>
        </>
    );
};

export default Dashboard