import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import LoadingOverlay from 'react-loading-overlay';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { handleGetRequest } from '../../../services/GetTemplate';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handlePutRequest } from '../../../services/PutTemplate';
import { useHistory, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { FileUpload } from 'primereact/fileupload';
import photo from '../../../assets/warehouse-assets/photo.png'
import video from '../../../assets/warehouse-assets/video.png'
import { InputTextarea } from 'primereact/inputtextarea';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RadioButton } from 'primereact/radiobutton';
import { TreeSelect } from 'primereact/treeselect';
import { Toast } from "primereact/toast";
import { handleDeleteRequest } from '../../../services/DeleteTemplate';
import { SplitButton } from 'primereact/splitbutton';
import moment from 'moment';
import { baseURL } from '../../../Config';
import { Triangle } from 'react-loader-spinner';
import { Dialog } from 'primereact/dialog';
import Webcam from 'react-webcam';
import { Image } from 'primereact/image';
import axios from "axios"

const BorrowAsset = () => {

    const location = useLocation();
    const { isEditable, rowData, isView } = location.state;

    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")

    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState([])
    const [selectedFiles2, setSelectedFiles2] = useState([])
    const [selectedDoc, setSelectedDoc] = useState({})
    const [permissions, setPermissions] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const showToast = useRef(null)
    const uploadDoc = useRef()
    const uploadImg = useRef()
    const uploadVideo = useRef()
    const history = useHistory()
    const dispatch = useDispatch()
    const [allCategoryName, setAllCategoryName] = useState()
    const [allSubcategoryName, setAllSubcategoryName] = useState()
    const [allBrandName, setAllBrandName] = useState()
    const [allUomName, setAllUomName] = useState()
    const [allVendorName, setAllvendorName] = useState()
    const [assetList, setAssetList] = useState([]);
    const webcamRef = useRef(null)
    const [visible, setVisible] = useState(false);
    const [videoStream, setVideoStream] = useState();

    const [editableForStage, setIsEditableForStage] = useState(false)
    const maxFileSize = 10 * 1024 * 1024;
    const maxImageSize = 5 * 1024 * 1024;
    const maxVideoSize = 5 * 1024 * 1024;
    const [checkOutStageData, setCheckOutStageData] = useState();
    const [checkOutUpdateId, setCheckOutUpdateId] = useState('');

    const handleCamera = () => {
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((state) => {
                setVideoStream(state)
                setVisible(true)
            })
            .catch(() => toast.warn("permission required"))
    }

    const capturePicture = useCallback(() => {
        if (webcamRef.current) {
            const base64Image = webcamRef.current.getScreenshot();
            const filtered_type = base64Image?.split(';')[0];
            const file_type = filtered_type?.split(":")[1]
            if (base64Image) {
                setSelectedFiles((prevFiles) =>
                    [
                        ...prevFiles,
                        { fileType: file_type, fileName: "WebCamImage", base64Data: base64Image, attachment_type: "images", path: null }
                    ]
                )
            }
        }
        setVisible(false)
        stopCamera()
    })

    const stopCamera = async () => {
        if (videoStream) {
            (videoStream).getTracks().forEach((track) => {
                if (track.readyState == 'live') {
                    track.stop()
                }
            })
            console.log(videoStream)
        }
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value.toLowerCase();
        setGlobalFilterValue(value);
    };

    const header = () => (
        <div className='flex justify-content-between align-items-center '>
            <h5 className='mb-0'> Check out/ Borrow data List </h5>
            <div className='flex justify-content-between align-items-center '>
                <div className={`flex justify-content-between align-items-center  desaturated_blue mr-3 w-18rem border-round ${!checkOutStageData?.length > 0 ? "opacity-60" : ""} `} style={{ padding: ' 0.75rem 1.25rem ' }} >
                    <p className=' mb-0 text-500' style={{ width: "97%" }}>
                        {
                            selectedDoc && Object.keys(selectedDoc)?.length !== 0 ? (
                                <span className='flex justify-content-between align-items-center mb-0 text-500' >
                                    <span className='text-overflow-ellipsis overflow-hidden font-medium text-white white-space-nowrap'>{selectedDoc?.fileName ? selectedDoc?.fileName : selectedDoc?.path ? 'Document' : 'Upload'}</span>
                                    <div className='flex flex-row ml-4'>
                                        <i className='pi pi-times mr-2 text-white' style={{ cursor: "pointer" }} onClick={() => setSelectedDoc("")} ></i>
                                        <i className='pi pi-download mr-2 text-white' style={{ cursor: "pointer", pointerEvents: 'auto', opacity: 1 }} onClick={() => handleDownloadFile(selectedDoc)} ></i>
                                    </div>
                                </span>
                            ) : (
                                <>
                                    <span className='mb-0  w-full text-white font-medium '>
                                        Upload
                                    </span>
                                </>
                            )
                        }
                    </p>
                    <FileUpload className='text-white' ref={uploadDoc} multiple accept={".pdf"} onSelect={(e) => handleSelectedDoc(e)} style={{ display: "none" }} />
                    <i className='pi pi-paperclip cursor-pointer text-white' onClick={handleDocument}></i>
                </div>
                <Button className=' desaturated_blue px-5 mr-3' type='button' disabled={!checkOutStageData?.length > 0} label='Clear All Data' onClick={(e) => deleteDialog(e)} />
                <InputText placeholder='Search' value={globalFilterValue} onChange={onGlobalFilterChange} />
            </div>
        </div>
    )

    const deleteDialog = (e) => {
        confirmDialog({
            message: 'Are you sure you want to clear all data?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleAllDeleteData(e),
            reject: null
        });
    }

    const handleAllDeleteData = async (e) => {

        e.preventDefault();
        let obj = {
            uid: user_id,
            wid: warehouseid
        }
        setIsActive(true);
        const response = await dispatch(handleDeleteRequest(obj, `/api/Asset/DeleteStageAssetBorrow`, true, true));
        if (response?.statusCode === "200") {
            getAllStageAssetData(false)
        }
        setIsActive(false);
    }


    const actionTemplate = (rowData) => {

        const items = [
            {
                label: 'Delete',
                command: () => confirm(rowData),
            }
        ];

        return (
            <div>
                <SplitButton label="" model={items} />
            </div>
        );
    };


    const confirm = (rowData) => {
        confirmDialog({
            message: 'Are you sure you want to Delete this Checkout data?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleDeleteRecord(rowData),
            reject: null
        });
    }

    const handleDeleteRecord = async (rowData) => {

        let obj = {
            p_id: rowData?.id
        }

        setIsActive(true);

        const response = await dispatch(handleDeleteRequest(obj, `/api/Asset/DeleteStageAssetBorrowByID`, true, true));
        if (response?.statusCode == "200") {
            getAllStageAssetData(false)
        }
        setIsActive(false);
    }

    const validationSchema = Yup.object().shape({
        asset_type: Yup.string().required("This field is required.").nullable(),
        category: Yup.string().required("This field is required.").nullable(),
        asset_name: Yup.string().required("This field is required.").nullable(),
        brand: Yup.string().required("This field is required.").nullable(),
        rfidCode: Yup.string().required("This field is required.").nullable(),
        sku: Yup.string().required("This field is required.").nullable(),
        vendor: Yup.string().required("This field is required.").nullable(),
        current_date: Yup.string().required("This field is required.").nullable(),

    });

    const formik = useFormik({

        validationSchema: validationSchema,
        initialValues: {

            rfidCode: "",
            asset_type: "",
            asset_name: "",
            category: "",
            subCategory: "",
            brand: "",
            quantity: "",
            sku: "",
            uom: "",
            description: "",
            vendor: "",
            notes: "",
            current_date: isEditable ? "" : new Date().toISOString().split('T')[0],

        },

        onSubmit: async (data) => {


            if (selectedFiles?.length === 0) {
                toast.warn('Please add image')
            } else {

                setIsActive(true)

                if (isEditable) {
                    let payload = {
                        p_id: checkOutUpdateId,
                        p_inventory_Type: data?.asset_type,
                        p_category_id: data?.category,
                        p_subcategory_id: data?.subCategory === "" ? 0 : data?.subCategory,
                        p_inventory_id: data?.asset_name,
                        p_brand_id: data?.brand,
                        p_rfid: data?.rfidCode,
                        p_quantity: data?.quantity,
                        p_SalesDescription: data?.description,
                        p_unit_of_measurement: data?.uom,
                        p_sku_code: data?.skuCode,
                        p_cost: data?.costPerUnit,
                        p_vendor_id: data?.vendorInformation,
                        p_min_t_value: data?.thresholdValue,
                        p_Expiry_Date: data?.expiryDate,
                        p_value_of_measurement: data?.valueOfMeasurement,
                        p_Notes: data?.addNotes,
                        p_roletypeid: roletypeid,
                        p_usertypeid: usertype_id,
                        p_warehouseid: warehouseid,
                        p_createdby: user_id,
                    }

                    const concatAllData = [selectedFiles, selectedFiles2]
                    const allArrayData = concatAllData.flat();
                    payload["attachments"] = allArrayData;

                    const response = await dispatch(handlePutRequest(payload, '/api/Inventory/UpdateCheckIn', true, true));

                    if (response?.statusCode == "200") {
                        formik.resetForm();
                        setSelectedDoc()
                        setSelectedFiles([])
                        setSelectedFiles2([])
                    }
                }

                else {

                    let payload = {
                        p_inventory_Type: data?.asset_type,
                        p_category_id: data?.category,
                        p_subcategory_id: data?.subCategory === "" ? 0 : data?.subCategory,
                        p_asset_id: data?.asset_name,
                        p_brand_id: data?.brand,
                        p_rfid: data?.rfidCode,
                        p_quantity: 1,
                        p_SalesDescription: data?.description,
                        p_unit_of_measurement: data?.uom,
                        p_sku_code: data?.sku,
                        p_cost: 0,
                        p_vendor_id: data?.vendor,
                        p_min_t_value: 1,
                        p_value_of_measurement: 0,
                        p_borrow_Date: data?.current_date,
                        p_Notes: data?.notes,

                        p_roletypeid: roletypeid,
                        p_usertypeid: usertype_id,
                        p_warehouseid: warehouseid,
                        p_createdby: user_id,

                    }

                    const concatAllData = [selectedFiles, selectedFiles2]
                    const allArrayData = concatAllData.flat();
                    payload["attachments"] = allArrayData;

                    const response = await dispatch(handlePostRequest(payload, '/api/Asset/AddStageBorrowCheckOut', true, true));

                    if (response?.statusCode == "200") {
                        getAllStageAssetData(false)
                        formik.resetForm();
                        setSelectedDoc()
                        setSelectedFiles([])
                        setSelectedFiles2([])
                    }

                }

                setIsActive(false)
            }
        },
    });


    const ResetForm = () => {
        formik.resetForm();
        setSelectedFiles([])
        setSelectedFiles2([])
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    //File Handling
    const handleFileUpload = (event) => {
        const files = Array.from(event.files);
        const updatedFiles = files.map((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                if (file?.size > maxImageSize) {
                    toast.warn("Attached image size is greater then 5MB")
                }
                else if (selectedFiles?.length > 4) {
                    toast.warn("A maximum of 5 images can be added.");
                }
                else {
                    setSelectedFiles((prevFiles) => [
                        ...prevFiles,
                        { fileType: file.type, fileName: file.name, base64Data: base64String, attachment_type: "images", path: null }
                    ]);
                }
            };
            reader.readAsDataURL(file);
            return file;
        });
    };

    const handleFileUpload2 = (event) => {
        const files = Array.from(event.files);
        const updatedFiles = files.map((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                if (file?.size > maxVideoSize) {
                    toast.warn("Attached video size is greater then 5MB")
                } else {
                    setSelectedFiles2((prevFiles) => [
                        { fileType: file.type, fileName: file.name, base64Data: base64String, attachment_type: "video", path: null }

                    ]);
                }
            };
            reader.readAsDataURL(file);
            return file;
        });
    };

    const handleDocument = () => {
        if (checkOutStageData?.length > 0) {
            uploadDoc.current.choose();
        }
    };
    const handleVideo = () => {
        uploadVideo.current.choose();
    };
    const handleImages = () => {
        uploadImg.current.choose();
    };
    const handleSelectedDoc = (e) => {
        if (e.files && e.files.length > 0) {
            let selectedFile = e.files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Doc = reader.result
                if (selectedFile?.size > maxFileSize) {
                    toast.warn("Attached file size is greater then 10MB")
                } else {
                    setSelectedDoc({ fileType: selectedFile?.type, fileName: selectedFile?.name, base64Data: base64Doc, attachment_type: "file", path: null })
                }
            }
            reader.readAsDataURL(selectedFile);
            return selectedFile
        } else {
            console.log('No files selected');
        }
    };


    //For Deleting File
    const handleCancelFile = (file) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
    };


    const handleCancelFile2 = (file) => {
        setSelectedFiles2((prevFiles) => prevFiles.filter((f) => f !== file));
    };

    useEffect(() => {
        if (formik.values.category) {
            getAllApplicationDetail(formik.values.category)
        }
    }, [formik.values.category])

    const getAllApplicationDetail = async (category_id) => {

        if (category_id) {
            const response = await dispatch(handleGetRequest(`/api/Inventory/GetAllSubCategoriesByCatid?Category_id=${category_id}&warehouse_id=${warehouseid || 0}`))
            if (response) {
                setAllSubcategoryName(response)
            }
        }
    }


    const getCategoryLOVs = async () => {
        const response = await dispatch(handleGetRequest(`/api/Inventory/GetAllCategorioesByWarehouse?warehouse_id=${warehouseid || 0}`))
        if (response) {
            setAllCategoryName(response)

        }
    }

    const getBrandLOVs = async () => {
        const response = await dispatch(handleGetRequest(`/api/Setups/GetAllBrand?warehouse_id=${warehouseid || 0}`))
        if (response) {
            setAllBrandName(response)
        }
    }
    const getUomLOVs = async () => {
        const response = await dispatch(handleGetRequest(`/api/Inventory/GetUnitOfMeasurement`))
        if (response) {
            setAllUomName(response)
        }
    }
    const getVendorLOVs = async () => {
        const response = await dispatch(handleGetRequest(`/api/Setups/GetAllVendor?warehouse_id=${warehouseid || 0}`))
        if (response) {
            setAllvendorName(response)
        }
    }

    //Get Asset List
    const getAssetList = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest(`/api/Asset/GetAsset?roletype_id=${roletypeid}&usertype_id=${usertype_id}&warehouse_id=${warehouseid || 0}`, true));
        if (response) {
            setAssetList(response)
        };
        setIsActive(false);
    }

    const getAllStageAssetData = async (showAlert) => {

        const response = await dispatch(handleGetRequest(`/api/Asset/GetStageAssetBorrowList?warehouseid=${warehouseid || 0}&userid=${user_id}`))
        if (response) {
            if (response.length >= 1 && showAlert && isEditable === false) {
                toast.info('Your unsaved borrow data has been restored ')
                setCheckOutStageData(response)
            } else {
                setCheckOutStageData(response)
            }
        }
        setIsActive(false)
    }

    useEffect(() => {

        const myPermissions = JSON.parse(localStorage.getItem("permissions"))
        const allPermModules = myPermissions?.find(module => module?.routepath?.includes("/inventory"));
        if (allPermModules) {
            const result = allPermModules?.submodules?.find(item => item?.routepath == "/checkin")
            setPermissions(result?.permissions?.map(item => item?.permission_id) || []);
        }

        getCategoryLOVs();
        getBrandLOVs();
        getUomLOVs();
        getVendorLOVs();
        getAllStageAssetData(true)
        getAssetList()

    }, []);

    const handleCheckOutData = async () => {
        setIsActive(true)
        let obj = {
            uid: user_id,
            wid: warehouseid,
            attachments: [selectedDoc]
        }
        if (selectedDoc && Object.keys(selectedDoc)?.length !== 0) {
            const response = await dispatch(handlePostRequest(obj, "/api/Asset/AddSubmitBorrow", true, true));
            if (response.statusCode == "200") {
                history.push('./borrowasset')
            }
        } else {
            toast.warn('Please Upload Attachment')
        }
        setIsActive(false)
    }

    const handleGenerateData = async () => {

        setIsActive(true)
        const response = await dispatch(handleGetRequest(`/api/Asset/GetAssetByrfidForBorrow?obj_id=${formik.values.rfidCode}`, true, true));
        const keyData = response[0]

        if (keyData == undefined) {
            toast.warn('No record found with provided RFID code')
            formik.setFieldValue('asset_type', '')
            formik.setFieldValue('asset_name', '')
            formik.setFieldValue('category', '')
            formik.setFieldValue('subCategory', '')
            formik.setFieldValue('brand', '')
            formik.setFieldValue('sku', '')
            formik.setFieldValue('uom', '')
            formik.setFieldValue('quantity', 1)
            formik.setFieldValue('description', '')
            formik.setFieldValue('vendor', '')
            formik.setFieldValue('notes', '')
        }
        else if (keyData?.quantity == 0) {
            toast.warn('Asset already being checkout with provided RFID code')
            formik.setFieldValue('asset_type', '')
            formik.setFieldValue('asset_name', '')
            formik.setFieldValue('category', '')
            formik.setFieldValue('subCategory', '')
            formik.setFieldValue('brand', '')
            formik.setFieldValue('sku', '')
            formik.setFieldValue('uom', '')
            formik.setFieldValue('quantity', 1)
            formik.setFieldValue('description', '')
            formik.setFieldValue('vendor', '')
            formik.setFieldValue('notes', '')
        }
        else if (keyData?.quantity !== 0) {
            formik.setFieldValue('asset_type', keyData?.inventory_Type)
            formik.setFieldValue('asset_name', parseInt(keyData?.asset_id))
            formik.setFieldValue('category', keyData?.category_id)
            formik.setFieldValue('subCategory', keyData?.subcategory_id)
            formik.setFieldValue('brand', keyData?.brand_id)
            formik.setFieldValue('sku', keyData?.sku)
            formik.setFieldValue('uom', keyData?.unit_of_measurement)
            formik.setFieldValue('quantity', 1)
            formik.setFieldValue('description', keyData?.description)
            formik.setFieldValue('vendor', keyData?.vendor_id)
        }
        else {
            formik.setFieldValue('asset_type', '')
            formik.setFieldValue('asset_name', '')
            formik.setFieldValue('category', '')
            formik.setFieldValue('subCategory', '')
            formik.setFieldValue('brand', '')
            formik.setFieldValue('sku', '')
            formik.setFieldValue('uom', '')
            formik.setFieldValue('quantity', 1)
            formik.setFieldValue('description', '')
            formik.setFieldValue('vendor', '')
            formik.setFieldValue('notes', '')
        }
        setIsActive(false)
    }

    const getBorrowAssetListByIdEditable = async (rowData, isEditable) => {

        setIsActive(true)
        const response = await dispatch(handleGetRequest(`/api/Asset/GetBorrowByid?obj_id=${rowData?.id}`));
        const keyData = response?.length ? response[0] : null;

        if (keyData) {

            formik.setFieldValue("rfidCode", keyData?.rfid)
            formik.setFieldValue("asset_type", keyData?.inventory_Type)
            formik.setFieldValue("asset_name", parseInt(keyData?.asset_id))
            formik.setFieldValue("category", keyData?.category_id)
            formik.setFieldValue("subCategory", keyData?.subcategory_id)
            formik.setFieldValue("brand", keyData?.brand_id)
            formik.setFieldValue("uom", keyData?.unit_of_measurement)
            formik.setFieldValue("vendor", keyData?.vendor_id)
            formik.setFieldValue("sku", keyData?.sku_code)
            formik.setFieldValue("description", keyData?.salesDescription)
            formik.setFieldValue("notes", keyData?.notes)
            formik.setFieldValue("current_date", moment(keyData?.borrow_date).format('YYYY-MM-DD'));

            let selectedDoc = {};
            const selectedFiles = [];
            const selectedFiles2 = [];

            // Split the keydata.link by commas to get individual entries
            const entries = keyData?.link?.split(',');

            // Iterate through each entry and split by $$ to check the type
            entries && entries.forEach(entry => {
                const [path, type] = entry.trim().split('$$');  // Trim the entry to remove leading/trailing spaces
                if (type === 'file') {
                    const obj = { fileType: null, fileName: null, base64Data: null, attachment_type: null, path: `${path}$$file` }
                    selectedDoc = obj;
                } else if (type === 'images') {
                    selectedFiles.push({ fileType: null, fileName: null, base64Data: null, attachment_type: null, path: `${path}$$images` });
                } else if (type === 'video') {
                    selectedFiles2.push({ fileType: null, fileName: null, base64Data: null, attachment_type: null, path: `${path}$$video` });
                }
            });

            setSelectedDoc(selectedDoc);
            setSelectedFiles(selectedFiles);
            setSelectedFiles2(selectedFiles2);

        }

        setIsActive(false)

    }

    useEffect(() => {
        if (rowData && isEditable) {
            getBorrowAssetListByIdEditable(rowData, isEditable)
        }

    }, [rowData, isEditable])

    //Disable Form while Viewing
    const formStyle = {
        pointerEvents: isView ? 'none' : 'auto',
        opacity: isView ? 0.7 : 1,
    };

    const formStyle2 = {
        pointerEvents: 'auto',
        opacity: 1,
    };

    const handleDownloadFile = async (file) => {

        let filePath;

        if (file?.path) {
            filePath = `${baseURL}/${file?.path?.split('$$')[0].replace(/\\/g, '/')}`;
        }
        else {
            filePath = file?.base64Data;
        }

        try {
            let response;

            if (file?.path) {
                response = await axios.get('https://dev-wms.appinsnap.com/api/Inventory/DownloadFile?url=' + filePath, {
                    responseType: 'blob',
                    headers: {
                        Authorization:  `Bearer ${localStorage.getItem("token")}`,  
                    },
                });
            } else {
                response = await axios.get(filePath);
            }

            const blob = new Blob([response.data])
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Document.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.log(error)
        }



    };

    return (

        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgb(38 41 51 / 78%)',
                        width: '107.9%',
                        height: '125%',
                        top: '-27px',
                        left: '-35px'
                    })
                }}
            >
                <Toast ref={showToast} position="bottom-center" />

                <div className='main-form-card'>
                    <div className='form-header flex flex-row justify-content-between'>

                        {
                            isView ?
                                <div className='form-header-content'>
                                    <h4 style={{ fontWeight: '600', letterSpacing: '1px', color: 'black' }}>
                                        View Details
                                    </h4>
                                </div>
                                :
                                <div className='form-header-content'>
                                    <h4 style={{ fontWeight: '600', letterSpacing: '1px', color: 'black' }}>
                                        {isEditable ? 'Update Check In Asset' : editableForStage ? 'Update Details' : 'Borrow Asset'}
                                    </h4>
                                    <h6 style={{ color: 'gray' }}> {isEditable ? '' : editableForStage ? '' : 'Add Details'}</h6>
                                </div>
                        }

                        <div className='mt-4 mr-3'>
                            <Button className='Back-Button mr-3' icon="pi pi-arrow-left" label='Go Back' onClick={() => history.goBack()}>
                            </Button>
                        </div>
                    </div>
                    <div className='form-div'>
                        <form onSubmit={formik.handleSubmit} style={formStyle}>
                            <div className="card-form">
                                <div className="card-nested-form">
                                    <div class="grid">

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> RFID Entry Code </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <div style={{ position: 'relative' }}>
                                                    <InputText
                                                        type='text'
                                                        placeholder='Add RFID Code'
                                                        id='rfidCode'
                                                        name="rfidCode"
                                                        value={formik.values.rfidCode}
                                                        onChange={formik.handleChange}
                                                        className="p-inputtext-sm"
                                                        autoComplete="off"
                                                    />
                                                    <Button
                                                        disabled={formik.values.rfidCode ? false : true}
                                                        tooltip='Search via RFID Code'
                                                        tooltipOptions={{ position: "top" }}
                                                        type="button"
                                                        icon="pi pi-search"
                                                        className='auto-generate-button'
                                                        onClick={handleGenerateData}
                                                        style={{
                                                            background: '#8d7ad5',
                                                            borderColor: "#8d7ad5",
                                                            position: 'absolute',
                                                            top: '30%',
                                                            right: '8px',
                                                            transform: 'translateY(-50%)',
                                                        }}
                                                    />
                                                </div>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("rfidCode")}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label>Asset Name</label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <Dropdown
                                                    disabled
                                                    placeholder='--Select Asset--'
                                                    id="asset_name"
                                                    name="asset_name"
                                                    options={assetList}
                                                    optionLabel='name'
                                                    optionValue='id'
                                                    value={formik?.values?.asset_name}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                    autoComplete="off"
                                                ></Dropdown>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("asset_name")}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4 align-content-center ">
                                            <div className='flex align-items-center mb-3'>
                                                <RadioButton disabled value={1} name="asset_type" onChange={formik.handleChange} checked={formik?.values?.asset_type === 1} />
                                                <label className='ml-2 mr-4'>Complete Set</label>
                                                <RadioButton disabled value={2} name="asset_type" onChange={formik.handleChange} checked={formik?.values?.asset_type === 2} />
                                                <label className='ml-2'>Single Unit</label>
                                            </div>
                                            <div className='ml-2 mt-2'>
                                                {getFormErrorMessage("asset_type")}
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Category </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <Dropdown
                                                    disabled
                                                    placeholder='--Select Category--'
                                                    id="category"
                                                    name="category"
                                                    options={allCategoryName}
                                                    optionLabel='name'
                                                    optionValue='id'
                                                    value={formik.values.category}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                    autoComplete="off"
                                                ></Dropdown>

                                                <div className='ml-2'>
                                                    {getFormErrorMessage("category")}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Sub Category </label>
                                                </div>
                                                <TreeSelect
                                                    disabled
                                                    placeholder='--Select Sub Category--'
                                                    id="subCategory"
                                                    name="subCategory"
                                                    options={allSubcategoryName}
                                                    optionLabel='label'
                                                    optionValue='key'
                                                    value={formik.values.subCategory}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm text-sm text-grey-500"
                                                ></TreeSelect>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("subCategory")}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label>Brand</label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <Dropdown
                                                    placeholder='--Select Brand--'
                                                    id="brand"
                                                    name="brand"
                                                    disabled
                                                    options={allBrandName}
                                                    optionLabel='obj_Name'
                                                    optionValue='obj_id'
                                                    value={formik.values.brand}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                    autoComplete="off"
                                                ></Dropdown>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("brand")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mb-4' style={{ borderBottom: "1px solid #8d7ad5" }}> </div>

                                    <div class="grid">
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label>Quantity</label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText disabled className="p-inputtext-sm" id="quantity" name="quantity" placeholder='Enter Quantity ' value={formik.values.quantity} onChange={formik.handleChange} type="number" />
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("quantity")}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Current Date </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText
                                                    type='date'
                                                    id="current_date"
                                                    name="current_date"
                                                    disabled
                                                    value={formik.values.current_date}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                    autoComplete="off"
                                                ></InputText>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("current_date")}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Unit of Measurement </label>
                                                </div>
                                                <Dropdown
                                                    placeholder='--Select Unit of Measurement--'
                                                    id="uom"
                                                    name="uom"
                                                    disabled
                                                    options={allUomName}
                                                    optionLabel='name'
                                                    optionValue='id'
                                                    value={formik?.values?.uom}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                    autoComplete="off"
                                                ></Dropdown>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label>SKU</label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText className="p-inputtext-sm" id="sku" name="sku" disabled placeholder='Enter SKU Code' value={formik.values?.sku} onChange={formik.handleChange} type="text" />
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("sku")}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Supplier/ Vendor Information </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <Dropdown
                                                    placeholder='--Select--'
                                                    id="vendor"
                                                    name="vendor"
                                                    disabled
                                                    options={allVendorName}
                                                    optionLabel='obj_Name'
                                                    optionValue='obj_id'
                                                    value={formik.values.vendor}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                    autoComplete="off"
                                                ></Dropdown>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("vendor")}
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col-12 lg:col-4 md:col4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Description </label>
                                                </div>
                                                <InputTextarea rows={4} maxLength={150} disabled className="p-inputtext-sm" id="description" name="description" placeholder='Enter Description' value={formik.values.description} onChange={formik.handleChange} type="text" />
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("description")}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="grid">
                                        <div class="col-12 lg:col-8 md:col-8">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Add Product Images </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <div className="flex flex-column mt-2">
                                                    <div className="mb-2 flex flex-column">
                                                        <div className='flex flex-row ml-2'>
                                                            <div className='flex flex-column'>
                                                                <img
                                                                    src={photo}
                                                                    width="100px" height="100px"
                                                                    alt="Profile Image"
                                                                    className="ml-2 photo-bg"
                                                                />
                                                                <FileUpload
                                                                    className="mt-2 p-0 hidden"
                                                                    onSelect={handleFileUpload}
                                                                    accept="image/*"
                                                                    ref={uploadImg}
                                                                />
                                                                <div className='p-fileupload-choose cursor-pointer flex justify-content-center align-items-center mt-3' onClick={() => handleImages()}>
                                                                    <i className='pi pi-plus text-lg' style={{ marginLeft: "1.3rem" }}></i>
                                                                    <span className='p-button-label  ml-2 text-sm'>Choose File</span>
                                                                </div>
                                                                <div onClick={() => handleCamera()} className='p-fileupload-choose cursor-pointer flex justify-content-center align-items-center mt-3'>
                                                                    <i className='pi pi-camera text-lg' style={{ marginLeft: "1.3rem" }}></i>
                                                                    <span className='p-button-label  ml-2 text-sm'>Take a Picture</span>
                                                                </div>
                                                                <Dialog header="Photo" visible={visible} style={{ width: '50vw', height: '50vw' }}
                                                                    onHide={() => {
                                                                        if (!visible) return;
                                                                        setVisible(false)
                                                                        stopCamera();
                                                                    }}>
                                                                    <div className='flex justify-content-center w-full' >
                                                                        <Webcam width={"80%"} ref={webcamRef} />
                                                                    </div>
                                                                    <div className='mt-5'>
                                                                        <Button className='desaturated_blue px-5  block m-auto' onClick={capturePicture} >Take picture</Button>
                                                                    </div>
                                                                </Dialog>
                                                            </div>

                                                            <div className="flex flex-row ">
                                                                {selectedFiles && selectedFiles.length ?
                                                                    <div className='flex flex-row'>
                                                                        {selectedFiles.length === 0 && selectedFiles.length === 0 ? (
                                                                            <p style={{ fontSize: "14px", fontWeight: "600", fontStyle: "italic", color: "gray" }}></p>
                                                                        ) : (
                                                                            selectedFiles && selectedFiles.map((file, index) => (
                                                                                <span key={index} className="" style={{ position: "relative", marginLeft: "8px", marginRight: "8px" }}>
                                                                                    <Image
                                                                                        style={formStyle2}
                                                                                        preview
                                                                                        src={file?.base64Data || `${baseURL}/${file?.path?.split('$$')[0]}`}
                                                                                        zoomSrc={file?.base64Data || `${baseURL}/${file?.path?.split('$$')[0]}`}
                                                                                        alt={`Attachment ${index + 1}`}
                                                                                        width="100"
                                                                                        height="100"
                                                                                        imageStyle={{ borderRadius: "10px", border: '2px solid rgb(172 172 173)' }}
                                                                                    />
                                                                                    <Button
                                                                                        tooltip='Remove Image'
                                                                                        tooltipOptions={{ position: "top" }}
                                                                                        type='button'
                                                                                        icon="pi pi-times"
                                                                                        className='remove-file-button'
                                                                                        style={{
                                                                                            position: "absolute",
                                                                                            top: '0px',
                                                                                            right: '0px',
                                                                                            zIndex: 1,
                                                                                            fontSize: '10px'
                                                                                        }}
                                                                                        onClick={() => handleCancelFile(file)}
                                                                                    />
                                                                                </span>
                                                                            ))
                                                                        )}
                                                                    </div>
                                                                    :
                                                                    <div className='mt-4 flex'>
                                                                        <p style={{ fontSize: "14px", fontWeight: "600", fontStyle: "italic", color: "gray" }}></p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Add Video </label>
                                                </div>
                                                <div className="flex flex-column mt-2">
                                                    <div className="mb-2 flex flex-column">
                                                        <div className='flex flex-row ml-2'>
                                                            <div className='flex flex-column'>
                                                                <img
                                                                    src={video}
                                                                    width="100px" height="100px"
                                                                    alt="Video"
                                                                    className="ml-4 video-bg"
                                                                />
                                                                <FileUpload
                                                                    ref={uploadVideo}
                                                                    accept="video/*"
                                                                    onSelect={(e) => handleFileUpload2(e)}
                                                                    style={{ display: "none" }}
                                                                />
                                                                <div className='p-fileupload-choose cursor-pointer flex justify-content-center align-items-center mt-3' onClick={handleVideo}>
                                                                    <i className='pi pi-plus text-lg' style={{ marginLeft: "1.3rem" }}></i>
                                                                    <span className='p-button-label  ml-2 text-sm'>Choose File</span>
                                                                </div>
                                                            </div>

                                                            <div className="flex flex-row ml-4">
                                                                {selectedFiles2 && selectedFiles2.length ?
                                                                    <div className='flex flex-row'>
                                                                        {selectedFiles2.length === 0 && selectedFiles2.length === 0 ? (
                                                                            <p style={{ fontSize: "14px", fontWeight: "600", fontStyle: "italic", color: "gray" }}></p>
                                                                        ) : (
                                                                            selectedFiles2 && selectedFiles2.map((file, index) => (
                                                                                <span key={index} className="" style={{ position: "relative", marginLeft: "10px" }}>
                                                                                    <video
                                                                                        src={file?.base64Data || `${baseURL}/${file?.path?.split('$$')[0]}`}
                                                                                        alt='Video'
                                                                                        style={{
                                                                                            width: '130px',
                                                                                            height: '100px',
                                                                                            border: '2px solid rgb(172 172 173)',
                                                                                            borderRadius: '10px',
                                                                                            pointerEvents: 'auto',
                                                                                            opacity: 1
                                                                                        }}
                                                                                        controls
                                                                                    />
                                                                                    <Button
                                                                                        tooltip='Remove Video'
                                                                                        tooltipOptions={{ position: "top" }}
                                                                                        type='button'
                                                                                        icon="pi pi-times"
                                                                                        className='remove-file-button'
                                                                                        style={{
                                                                                            position: "absolute",
                                                                                            top: '0px',
                                                                                            right: '0px',
                                                                                            zIndex: 1,
                                                                                            fontSize: '10px'
                                                                                        }}
                                                                                        onClick={() => handleCancelFile2(file)}
                                                                                    />
                                                                                </span>
                                                                            ))
                                                                        )}
                                                                    </div>
                                                                    :
                                                                    <div className='mt-4 flex'>
                                                                        <p style={{ fontSize: "14px", fontWeight: "600", fontStyle: "italic", color: "gray" }}></p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="grid">
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Add Notes </label>
                                                </div>
                                                <InputTextarea maxLength={150} rows={5} className="p-inputtext-sm" id="notes" name="notes" placeholder='Enter Notes' value={formik.values.notes} onChange={formik.handleChange} type="text" />
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("notes")}
                                                </div>
                                            </div>
                                        </div>
                                        {isEditable ?
                                            (
                                                <div class="col-12 lg:col-4 md:col-4">
                                                    <div class="input-card flex-column p-3">
                                                        <div className='flex-row'>
                                                            <label>Purchase Order </label>
                                                            <span className="Label__Required">*</span>
                                                        </div>
                                                        <div className='flex justify-content-between align-items-center px-2 mt-2 ' >
                                                            <p className=' mb-0  text-500' style={{ width: "97%" }}>
                                                                {
                                                                    selectedDoc && Object.keys(selectedDoc)?.length !== 0 ? (
                                                                        <span className='flex justify-content-between align-items-center mb-0 text-500' >
                                                                            <span className='text-overflow-ellipsis overflow-hidden'>{selectedDoc?.fileName ? selectedDoc?.fileName : selectedDoc?.path ? 'Document' : 'Upload'}</span>
                                                                            <div className='flex flex-row'>
                                                                                <i className='pi pi-times mr-2' style={{ cursor: "pointer" }} onClick={() => setSelectedDoc("")} ></i>
                                                                                <i className='pi pi-download mr-2' style={{ cursor: "pointer", pointerEvents: 'auto', opacity: 1 }} onClick={() => handleDownloadFile(selectedDoc)} ></i>
                                                                            </div>
                                                                        </span>
                                                                    ) : (
                                                                        <>
                                                                            <span className='mb-0 text-500 w-full '>
                                                                                Upload
                                                                            </span>
                                                                        </>
                                                                    )
                                                                }
                                                            </p>
                                                            <FileUpload ref={uploadDoc} multiple accept={".pdf"} onSelect={(e) => handleSelectedDoc(e)} style={{ display: "none" }} />
                                                            <i className='pi pi-paperclip cursor-pointer' onClick={handleDocument}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            :
                                            null
                                        }


                                    </div>

                                    {permissions.includes(2) ?
                                        isView ? null : (
                                            <div className='col-12 d-flex flex-row text-center mt-4 pb-2'>
                                                <Button className="Cancel-Button" label="Reset" type="button" onClick={ResetForm} />
                                                <Button className="Save-Button ml-2" label={isEditable || editableForStage ? "Update" : 'Check Out'} loading={saveBtnLoading} type="submit" />
                                            </div>)
                                        :
                                        null
                                    }

                                </div>
                            </div>
                            {isEditable === false ?
                                <div className="card mt-3">
                                    
                                        <DataTable
                                            responsive={true}
                                            globalFilter={globalFilterValue}
                                            header={header}
                                            responsiveLayout="scroll"
                                            stripedRows
                                            paginator
                                            paginatorClassName="custom-paginator"
                                            rows={20}
                                            value={checkOutStageData}
                                            emptyMessage="No records found"
                                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                        >
                                            <Column field="asset_name" header="Asset Name"></Column>
                                            <Column field="rfid" header="RFID Code"></Column>
                                            <Column field="category_name" header="Category"></Column>
                                            <Column field='subcategory_name' body={(rowData) => rowData?.subcategory_name || 'N/A'} header="Sub Category"></Column>
                                            <Column field="brand_name" header="Brand"></Column>
                                            {/* <Column field='salesDescription' body={(rowData) => rowData?.salesDescription || 'N/A'} header="Description"></Column> */}
                                            <Column field='unit' body={(rowData) => rowData?.unit || 'N/A'} header="Unit"></Column>
                                            <Column
                                                body={(rowData) => rowData?.borrow_date ? moment(rowData.borrow_date).format('DD-MM-YYYY') : 'N/A'}
                                                header="Current Date"
                                            />
                                            <Column header="Action" body={actionTemplate} />
                                        </DataTable>
                                </div>
                                :
                                null}
                        </form>

                        {isView ?
                            null
                            :
                            <Button className='block mx-auto  Save-Button' disabled={!checkOutStageData?.length > 0} type='button' label='Submit' onClick={handleCheckOutData} />
                        }

                    </div>
                </div>

            </LoadingOverlay>
        </>
    )
}

export default BorrowAsset














