import { combineReducers } from "redux";
import authenticationSlice from "./slices/authenticationSlice";
import utilitySlice from "./slices/utilitySlice";
import checkoutUserSlice from "./slices/checkoutUserSlice";
import logoutSlice from "./slices/logoutSlice";

export default combineReducers({
    authenticationSlice,
    utilitySlice,
    checkoutUserSlice, 
    logoutSlice
});
