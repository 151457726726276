import React from 'react';
import img from '../../assets/warehouse-assets/PngItem.png'
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';

const NoPageFound = () => {

    const history = useHistory()

    const containerStyle2 = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    };

    const imgStyling = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        width: "550px",
        // height: "550px",
        marginTop: "40px"
    };

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
        flexDirection: 'column',
        textAlign: 'center',
    };

    const headingStyle = {
        color: '#8d7ad5',
        fontSize: '10rem',
        margin: '0',
        letterSpacing: "6px"
    };

    const subheadingStyle = {
        fontSize: '1.5rem',
        margin: '0',
    };

    return (

        <>
            <div className='showImg' style={containerStyle2}>
                <img style={imgStyling} src={img} />
            </div>
            <div className='showtext' style={containerStyle}>
                <h1 style={headingStyle}>404</h1>
                <p style={subheadingStyle}>No route found</p>
            </div>

            <div className='mt-6 col-12 text-center showImg'>
                <Button className='Back-Button' icon="pi pi-arrow-left" label='Go Back' onClick={() => history.goBack()}>
                </Button>
            </div>
        </>

    );
}

export default NoPageFound;
