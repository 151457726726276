import React, { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { Divider } from "primereact/divider";
import editIcon from "../../assets/warehouse-assets/colored/edit.png"
import deleteIcon from "../../assets/warehouse-assets/colored/dlet.png"
import { handleDeleteRequest } from "../../services/DeleteTemplate";
import { confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { SplitButton } from "primereact/splitbutton";
import { handlePutRequest } from "../../services/PutTemplate";
import { handleGetRequest } from "../../services/GetTemplate";

const ManageInventory = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const [isActive, setIsActive] = useState(false)
    const [inventoryList, setInventoryList] = useState([]);
    const [visible2, setVisible2] = useState(false);
    const [vendorList, setVendorList] = useState('');
    const [acceptButtonClass, setAcceptButtonClass] = useState('p-button-danger');

    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")


    const [permissions, setPermissions] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const actionTemplate = (rowData) => {


        const items = [];

        if ((roletypeid == 1) || (roletypeid == 2 && usertype_id == 1 && !warehouseid)) {
            items.push({
                label: 'View',
                command: () => handleViewInventory(rowData),
            });
        } else {
            items.push(
                {
                    label: 'Edit',
                    command: () => handleEditInventory(rowData),
                },
                // {
                //     label: rowData?.status === 1 ? 'Deactivate' : 'Activate',
                //     command: () => confirm(rowData),
                // }      
            );
        }

        return (
            <div>
                <SplitButton label="" model={items} />
            </div>
        );
    };

    const confirm = (rowData) => {

        confirmDialog({
            message: rowData?.status == 1 ? 'Are you sure you want to deactivate this Inventroy ?' : 'Are you sure you want to Activate this Inventory ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: acceptButtonClass,
            acceptLabel: 'Yes',
            accept: () => {
                if (rowData?.status == 1) {
                    handleDeactivateInventory(rowData);
                } else {
                    handleActivateInventory(rowData);
                }
            },
            reject: () => {
                return null;
            },
        });

    };

    const handleDeactivateInventory = async (rowData) => {

        const obj = {
            obj_id: rowData?.obj_id,
            Name: rowData?.obj_Name,
            Description: rowData?.obj_Description,
            IsActive: 0,
        };
        setIsActive(true);

        const response = await dispatch(handlePutRequest(obj, `/api/Setups/UpdateBrand`, true, true));
        if (response?.statusCode == 200) {
            await getInventoryList();
        }
        setIsActive(false);
    }

    const handleActivateInventory = async (rowData) => {

        const obj = {
            obj_id: rowData?.obj_id,
            Name: rowData?.obj_Name,
            Description: rowData?.obj_Description,
            IsActive: 1,
        };

        setIsActive(true);

        const response = await dispatch(handlePutRequest(obj, `/api/Setups/UpdateBrand`, true, true));
        if (response?.statusCode == 200) {
            await getInventoryList();
        }
        setIsActive(false);
    }

    const handleEditInventory = (rowData) => {
        const item = true;
        const view = false;
        history.push({
            pathname: './addeditinventory',
            state: {
                isEditable: item,
                rowData: rowData,
                isView: view,
            }
        });
    };

    const handleViewInventory = (rowData) => {
        const item = true;
        const view = true;
        history.push({
            pathname: './addeditinventory',
            state: {
                isEditable: item,
                isView: view,
                rowData: rowData,
            }
        });
    };

    const showVendor = (event, rowData) => {
        setVendorList( rowData?.vendorName)
        event.stopPropagation();
        setVisible2(true);
    }

    const vendorTemplate = (rowData) => (
        rowData?.vendorName?.includes(',') ?
            <div className='flex'>
                <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '11rem', cursor: 'pointer' }}>{rowData?.vendorName} </span>
                <i className='pi pi-external-link text-purple-500' style={{ cursor: "pointer" }} onClick={(event) => showVendor(event, rowData)} ></i>
            </div>
            :
            rowData?.vendorName ?
                <div style={{ whiteSpace: 'nowrap' }}>{rowData?.vendorName} </div>
                :
                <span>N/A</span>
    )

    //Get Inventory List
    const getInventoryList = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest(`/api/Inventory/GetInventory?warehouse_id=${warehouseid || 0}`, true));
        if (response) {
            setInventoryList(response);
        }
        setIsActive(false);
    };

    useEffect(() => {
        getInventoryList()
    }, [])

    useEffect(() => {

        const myPermissions = JSON.parse(localStorage.getItem("permissions"))
        const allPermModules = myPermissions?.find(module => module?.routepath?.includes("/inventory"));
        if (allPermModules) {
            setPermissions(allPermModules?.permissions?.map(item => item?.permission_id) || []);
        }
    }, []);

    return (

        <>
            <div className="flex flex-column align-items-start md:flex-row  md:justify-content-between md:align-items-center ">
                <h4 className="mb-0" style={{ fontWeight: '600', letterSpacing: '0.5px', color: 'black' }}>
                    Inventory Management
                </h4>
                <div className="flex flex-column align-items-start  md:flex-row md:align-items-center">
                    <span className="p-input-icon-left mr-3 resp-margin my-3 md:my-0">
                        <i className="pi pi-search" />
                        <InputText
                            placeholder="Search"
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            className="search-input"
                        />
                    </span>

                    {(roletypeid == 1) || (roletypeid == 2 && usertype_id == 1 && !warehouseid) ?
                        null
                        :
                        permissions.includes(2) ?
                            <div className="">
                                <Button
                                    label="Add New"
                                    className="Add__New-Button ml-0 mt-1 md:ml-3 md:mt-0 "
                                    icon="pi pi-plus"
                                    onClick={() => {
                                        const item = false;
                                        history.push({
                                            pathname: './addeditinventory',
                                            state: {
                                                isEditable: item,
                                            }
                                        });
                                    }}
                                />
                            </div>
                            :
                            null
                    }
                </div>
            </div>

            <div className="card mt-5">
                <LoadingOverlay
                    active={isActive}
                    spinner={<Triangle
                        height="120"
                        width="120"
                        color="#755fc8"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />}

                    // text='Loading your content...'
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: 'rgb(38 41 51 / 78%)',
                        })
                    }}
                >

                    <DataTable
                        responsive={true}
                        filters={filters}
                        globalFilterFields={[
                            "name",
                            "brandName",
                            "categoryName",
                            "sub_Category_Name",
                            "unitofmeasurementName",
                            "vendorName",
                            "sku",
                        ]}
                        responsiveLayout="scroll"
                        stripedRows
                        paginator
                        rows={20}
                        value={inventoryList}
                        emptyMessage="No records found"
                        paginatorClassName="custom-paginator"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    >
                        <Column sortable field='name' body={(rowData) => rowData?.name || 'N/A'} header="Inventory Name"></Column>
                        <Column sortable header='Vendor' body={vendorTemplate}></Column>
                        <Column sortable field='brandName' body={(rowData) => rowData?.brandName || 'N/A'} header="Brand"></Column>
                        <Column sortable field='categoryName' body={(rowData) => rowData?.categoryName || 'N/A'} header="Category"></Column>
                        <Column sortable field='sub_Category_Name' body={(rowData) => rowData?.sub_Category_Name || 'N/A'} header="Sub Category"></Column>
                        <Column sortable field='unitofmeasurementName' body={(rowData) => rowData?.unitofmeasurementName || 'N/A'} header="Unit"></Column>
                        {/* <Column sortable field='vendorName' body={(rowData) => rowData?.vendorName || 'N/A'} header="Vendor"></Column> */}
                        <Column sortable field='sku' body={(rowData) => rowData?.sku || 'N/A'} header="Sku"></Column>
                        {permissions.includes(2) ?
                            <Column header="Action" body={actionTemplate} />
                            :
                            null
                        }
                    </DataTable>
                    <Dialog header="Vendors List" visible={visible2} style={{ width: '30vw' }} onClick={(event) => event.stopPropagation()} onHide={() => { if (!visible2) return; setVisible2(false); }}>
                        <ul className='text-black-alpha-90'>
                            {vendorList?.split(',').map((vendor, index) => (
                                <li key={index}>
                                    {vendor.trim()}
                                </li>
                            ))}
                        </ul>
                    </Dialog>
                </LoadingOverlay>
            </div>
        </>
    );
};

export default ManageInventory