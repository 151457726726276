import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import LoadingOverlay from 'react-loading-overlay';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { handleGetRequest } from '../../services/GetTemplate';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../services/PostTemplate';
import { handlePutRequest } from '../../services/PutTemplate';
import { useHistory, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { FileUpload } from 'primereact/fileupload';
import photo from '../../assets/warehouse-assets/photo.png'
import video from '../../assets/warehouse-assets/video.png'
import { InputTextarea } from 'primereact/inputtextarea';
import { TreeSelect } from 'primereact/treeselect';
import { Toast } from "primereact/toast";
import { baseURL } from '../../Config';
import { RadioButton } from 'primereact/radiobutton';
import { Dialog } from 'primereact/dialog';
import Webcam from 'react-webcam';
import { Image } from 'primereact/image';
import { MultiSelect } from 'primereact/multiselect';
import axios from "axios"

const AddEditAsset = () => {

    const location = useLocation();
    const { isEditable, rowData, isView } = location.state;

    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")

    const showToast = useRef(null)
    const uploadDoc = useRef()
    const uploadImg = useRef()
    const uploadVideo = useRef()
    const history = useHistory()
    const dispatch = useDispatch()

    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState([])
    const [selectedFiles2, setSelectedFiles2] = useState([])
    const [selectedDoc, setSelectedDoc] = useState({})
    const [permissions, setPermissions] = useState([]);

    const [allCategoryName, setAllCategoryName] = useState([])
    const [allSubcategoryName, setAllSubcategoryName] = useState([])
    const [allBrandName, setAllBrandName] = useState([])
    const [allUomName, setAllUomName] = useState([])
    const [allVendorName, setAllvendorName] = useState([])
    const [editableForStage, setIsEditableForStage] = useState(false)
    const maxFileSize = 10 * 1024 * 1024;
    const maxImageSize = 5 * 1024 * 1024;
    const maxVideoSize = 5 * 1024 * 1024;
    const [visible, setVisible] = useState(false);
    const webcamRef = useRef(null)
    const [videoStream, setVideoStream] = useState();
    const [statusField, setStatusField] = useState();


    const handleCamera = () => {
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((state) => {
                setVideoStream(state)
                setVisible(true)
            })
            .catch(() => toast.warn("permission required"))
    }

    const capturePicture = useCallback(() => {
        if (webcamRef.current) {
            const base64Image = webcamRef.current.getScreenshot();
            const filtered_type = base64Image?.split(';')[0];
            const file_type = filtered_type?.split(":")[1]
            if (base64Image) {
                setSelectedFiles((prevFiles) =>
                    [
                        ...prevFiles,
                        { fileType: file_type, fileName: "WebCamImage", base64Data: base64Image, attachment_type: "images", path: null }
                    ]
                )
            }
        }
        setVisible(false)
        stopCamera()
    })

    const stopCamera = async () => {
        if (videoStream) {
            (videoStream).getTracks().forEach((track) => {
                if (track.readyState == 'live') {
                    track.stop()
                }
            })
            console.log(videoStream)
        }
    }

    const validationSchema = Yup.object().shape({
        checkbox: Yup.string().required("This field is required.").nullable(),
        asset_id: Yup.string().required("This field is required.").nullable(),
        category: Yup.string().required("This field is required.").nullable(),
        assetName: Yup.string().required("This field is required.").nullable(),
        brand: Yup.string().required("This field is required.").nullable(),
        vendor: Yup.mixed().required("This field is required.").nullable(),
        rfid_code: Yup.string().required("This field is required.").nullable(),
        sku: Yup.string().required("This field is required.").nullable(),
    });

    const formik = useFormik({

        validationSchema: validationSchema,
        initialValues: {
            checkbox: 1,
            asset_id: "",
            category: "",
            subCategory: "",
            assetName: "",
            brand: "",
            uom: 0,
            cost: "",
            vendor: "",
            rfid_code: "",
            sku: "",
            quantity: "",
            description: "",
            notes: "",
            thresholdValue: "",
            pieces_of_quantity: "",
        },

        onSubmit: async (data) => {

            if (selectedFiles?.length === 0) {
                toast.warn('Please add image')
            }

            if (!selectedDoc || Object.keys(selectedDoc)?.length === 0) {
                toast.warn('Please add file attachment');
            }

            else {
                setIsActive(true)

                if (isEditable) {

                    let payload = {
                        p_id: rowData?.id,
                        p_Name: data?.assetName,
                        p_description: data?.description,
                        p_asset_id: data?.asset_id,
                        p_inventory_type: data?.checkbox,

                        p_category_id: data?.category,
                        p_subcategory_id: data?.subCategory === "" ? 0 : data?.subCategory,
                        p_brand_id: data?.brand,
                        p_vendor_ids: data?.vendor,
                        p_unit_of_measurement: data?.uom,
                        p_SKU: data?.sku,
                        p_notes: data?.notes,
                        p_RFID: data?.rfid_code,
                        p_isactive: statusField,
                        p_Quantity: 1,
                        p_min_t_value: 0,
                        p_pieces_of_quantity: 0,

                        p_roletypeid: roletypeid,
                        p_usertypeid: usertype_id,
                        p_warehouseid: warehouseid,
                        p_updatedby: user_id,

                    }

                    const concatAllData = [selectedDoc, selectedFiles, selectedFiles2]
                    const allArrayData = concatAllData.flat();
                    payload["attachments"] = allArrayData;

                    const response = await dispatch(handlePutRequest(payload, '/api/Asset/UpdateAsset', true, true));

                    if (response?.statusCode == 200) {
                        history.push('./asset')
                        formik.resetForm();
                        setSelectedDoc({})
                        setSelectedFiles([])
                        setSelectedFiles2([])
                    }

                }
                else {

                    let payload = {

                        inventory_type: data?.checkbox,
                        asset_id: data?.asset_id,
                        name: data?.assetName,
                        description: data?.description,
                        category_id: data?.category,
                        sub_categoryid: data?.subCategory === "" ? 0 : data?.subCategory,
                        brand_id: data?.brand,
                        vendor_ids: data?.vendor,
                        unit_of_measurement: data?.uom,
                        sku: data?.sku,
                        notes: data?.notes,
                        rfid: data?.rfid_code,
                        quantity: 1,
                        min_t_value: 0,
                        pieces_of_quantity: 0,

                        roletype_id: roletypeid,
                        usertype_id: usertype_id,
                        warehouse_id: warehouseid,
                        createdby: user_id,

                    }

                    const concatAllData = [selectedDoc, selectedFiles, selectedFiles2]
                    const allArrayData = concatAllData.flat();
                    payload["attachments"] = allArrayData;

                    const response = await dispatch(handlePostRequest(payload, '/api/Asset/AddAsset', true, true));

                    if (response?.statusCode == 200) {
                        history.push('./asset')

                    }
                }

                setIsActive(false)
            }
        },
    });

    const ResetForm = () => {
        formik.resetForm();
        setSelectedFiles([])
        setSelectedFiles2([])
        setSelectedDoc({})
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    //File Handling
    const handleFileUpload = (event) => {
        const files = Array.from(event.files);
        const updatedFiles = files.map((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                if (file?.size > maxImageSize) {
                    toast.warn("Attached image size is greater then 5MB")
                }
                else if (selectedFiles?.length > 4) {
                    toast.warn("A maximum of 5 images can be added.");
                }
                else {
                    setSelectedFiles((prevFiles) => [
                        ...prevFiles,
                        { fileType: file.type, fileName: file.name, base64Data: base64String, attachment_type: "images", path: null }
                    ]);
                }
            };
            reader.readAsDataURL(file);
            return file;
        });
    };

    const handleFileUpload2 = (event) => {
        const files = Array.from(event.files);
        const updatedFiles = files.map((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                if (file?.size > maxVideoSize) {
                    toast.warn("Attached video size is greater then 5MB")
                } else {
                    setSelectedFiles2((prevFiles) => [
                        { fileType: file.type, fileName: file.name, base64Data: base64String, attachment_type: "video", path: null }

                    ]);
                }
            };
            reader.readAsDataURL(file);
            return file;
        });
    };

    const handleDocument = () => {
        uploadDoc.current.choose();
    };
    const handleVideo = () => {
        uploadVideo.current.choose();
    };
    const handleImages = () => {
        uploadImg.current.choose();
    };

    const handleSelectedDoc = (e) => {
        if (e.files && e.files.length > 0) {
            let selectedFile = e.files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Doc = reader.result
                if (selectedFile?.size > maxFileSize) {
                    toast.warn("Attached file size is greater then 10MB")
                } else {
                    setSelectedDoc({ fileType: selectedFile?.type, fileName: selectedFile?.name, base64Data: base64Doc, attachment_type: "file", path: null })
                }
            }
            reader.readAsDataURL(selectedFile);
            return selectedFile
        } else {
            console.log('No files selected');
        }
    };

    //For Deleting File
    const handleCancelFile = (file) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
    };


    const handleCancelFile2 = (file) => {
        setSelectedFiles2((prevFiles) => prevFiles.filter((f) => f !== file));
    };

    const getCategoryLOVs = async () => {
        const response = await dispatch(handleGetRequest(`/api/Inventory/GetAllCategorioesByWarehouse?warehouse_id=${warehouseid || 0}`))
        if (response) {
            setAllCategoryName(response)

        }
    }

    useEffect(() => {
        if (formik.values.category) {
            getSubCategoryList(formik.values.category)
        }
    }, [formik.values.category])

    const getSubCategoryList = async (category_id) => {
        const response = await dispatch(handleGetRequest(`/api/Inventory/GetAllSubCategoriesByCatid?Category_id=${category_id}&warehouse_id=${warehouseid || 0}`))
        if (response) {
            setAllSubcategoryName(response)
        }
    }

    const getBrandLOVs = async () => {
        const response = await dispatch(handleGetRequest(`/api/Setups/GetAllBrand?warehouse_id=${warehouseid || 0}`))
        if (response) {
            setAllBrandName(response?.filter(item => item?.status == 1));
        }
    }

    const getUomLOVs = async () => {
        const response = await dispatch(handleGetRequest(`/api/Inventory/GetUnitOfMeasurement`))
        if (response) {
            setAllUomName(response)
        }
    }

    const getVendorLOVs = async () => {
        const response = await dispatch(handleGetRequest(`/api/Setups/GetAllVendor?warehouse_id=${warehouseid || 0}`))
        if (response) {
            setAllvendorName(response?.filter(item => item?.status == 1));
        }
    }


    useEffect(() => {
        const myPermissions = JSON.parse(localStorage.getItem("permissions"))
        const allPermModules = myPermissions?.find(module => module?.routepath?.includes("/inventory"));
        if (allPermModules) {
            const result = allPermModules?.submodules?.find(item => item?.routepath == "/checkin")
            setPermissions(result?.permissions?.map(item => item?.permission_id) || []);
        }

        getCategoryLOVs();
        getBrandLOVs();
        getUomLOVs();
        getVendorLOVs();

    }, []);

    const getAssetListByIdEditable = async (rowData, isEditable) => {

        setIsEditableForStage(true)

        const response = await dispatch(handleGetRequest(`/api/Asset/GetAssetByid?obj_id=${rowData?.id}`));
        const keyData = response[0];

        if (keyData) {

            setStatusField(keyData?.isactive)
            formik.setFieldValue("checkbox", keyData?.inventory_type)
            formik.setFieldValue("asset_id", keyData?.asset_id)
            formik.setFieldValue("category", keyData?.category_id)
            formik.setFieldValue("subCategory", keyData?.subcategory_id)
            formik.setFieldValue("assetName", keyData?.name)
            formik.setFieldValue("brand", keyData?.brand_id)
            formik.setFieldValue("uom", keyData?.unit_of_measurement)
            formik.setFieldValue("cost", keyData?.salesDescription)
            formik.setFieldValue("vendor", keyData?.vendor_ids_string)
            formik.setFieldValue("rfid_code", keyData?.rfid)
            formik.setFieldValue("description", keyData?.description)
            formik.setFieldValue("notes", keyData?.notes)
            formik.setFieldValue("sku", keyData?.sku)

            let selectedDoc = {};
            const selectedFiles = [];
            const selectedFiles2 = [];

            // Split the keydata.link by commas to get individual entries
            const entries = keyData?.link?.split(',');

            // Iterate through each entry and split by $$ to check the type
            entries && entries.forEach(entry => {
                const [path, type] = entry.trim().split('$$');  // Trim the entry to remove leading/trailing spaces
                if (type === 'file') {
                    const obj = { fileType: null, fileName: null, base64Data: null, attachment_type: null, path: `${path}$$file` }
                    selectedDoc = obj;
                } else if (type === 'images') {
                    selectedFiles.push({ fileType: null, fileName: null, base64Data: null, attachment_type: null, path: `${path}$$images` });
                } else if (type === 'video') {
                    selectedFiles2.push({ fileType: null, fileName: null, base64Data: null, attachment_type: null, path: `${path}$$video` });
                }
            });

            // Assuming setSelectedDoc, setSelectedFiles, and setSelectedFiles2 are your state setters
            setSelectedDoc(selectedDoc);
            setSelectedFiles(selectedFiles);
            setSelectedFiles2(selectedFiles2);

        }
    }

    useEffect(() => {
        if (rowData && isEditable) {
            getAssetListByIdEditable(rowData, isEditable)
        }

    }, [rowData, isEditable])

    //Disable Form while Viewing
    const formStyle = {
        pointerEvents: isView ? 'none' : 'auto',
        opacity: isView ? 0.7 : 1,
    };

    const formStyle2 = {
        pointerEvents: 'auto',
        opacity: 1,
    };

    const handleGenerateAssetId = () => {
        const newAssetId = generateRandomAssetId();
        formik.setFieldValue('asset_id', newAssetId);
    };

    const handleDownloadFile = async (file) => {

        let filePath;

        if (file?.path) {
            filePath = `${baseURL}/${file?.path?.split('$$')[0].replace(/\\/g, '/')}`;
        }
        else {
            filePath = file?.base64Data;
        }

        try {
            let response;

            if (file?.path) {
                response = await axios.get('https://dev-wms.appinsnap.com/api/Inventory/DownloadFile?url=' + filePath, {
                    responseType: 'blob',
                    headers: {
                        Authorization:  `Bearer ${localStorage.getItem("token")}`,  
                    },
                });
            } else {
                response = await axios.get(filePath);
            }

            const blob = new Blob([response.data])
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Document.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.log(error)
        }



    };

    const generateRandomAssetId = () => {
        const prefix = "ast-";
        const length = 4;
        const charset = "0123456789";
        let userId = "";

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            userId += charset[randomIndex];
        }

        return prefix + userId;
    };

    const handleGenerateRfidCode = () => {
        const newRfidCode = generateRandomRfidCode();
        formik.setFieldValue('rfid_code', newRfidCode);
    };

    const generateRandomRfidCode = () => {
        const prefix = "A-";
        const length = 4;
        const charset = "0123456789";
        let userId = "";

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            userId += charset[randomIndex];
        }

        return prefix + userId;
    };

    return (

        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgb(38 41 51 / 78%)',
                        width: '107.9%',
                        height: '125%',
                        top: '-27px',
                        left: '-35px'
                    })
                }}
            >
                <Toast ref={showToast} position="bottom-center" />

                <div className='main-form-card'>
                    <div className='form-header flex flex-row justify-content-between'>

                        {
                            isView ?
                                <div className='form-header-content'>
                                    <h4 style={{ fontWeight: '600', letterSpacing: '1px', color: 'black' }}>
                                        View Details
                                    </h4>
                                    <h6 style={{ color: 'gray' }}> View </h6>
                                </div>
                                :
                                <div className='form-header-content'>
                                    <h4 style={{ fontWeight: '600', letterSpacing: '1px', color: 'black' }}>
                                        {isEditable ? 'Update Asset' : editableForStage ? 'Update Details' : 'Add Asset'}
                                    </h4>
                                    <h6 style={{ color: 'gray' }}> {isEditable ? '' : editableForStage ? '' : 'Add Details'}</h6>
                                </div>
                        }

                        <div className='mt-4 mr-3'>
                            <Button className='Back-Button mr-3' icon="pi pi-arrow-left" label='Go Back' onClick={() => history.goBack()}>
                            </Button>
                        </div>
                    </div>

                    <div className='form-div'>
                        <form onSubmit={formik.handleSubmit} style={formStyle}>
                            <div className="card-form">
                                <div className="card-nested-form">
                                    <div class="grid">
                                        <div class="col-12 lg:col-4 md:col-4 align-content-center ">
                                            <div className='flex align-items-center'>
                                                <RadioButton value={1} name="checkbox" onChange={formik.handleChange} checked={formik?.values?.checkbox === 1} />
                                                <label className='ml-2 mr-4'>Complete Set</label>
                                                <RadioButton value={2} name="checkbox" onChange={formik.handleChange} checked={formik?.values?.checkbox === 2} />
                                                <label className='ml-2'>Single Unit</label>
                                            </div>
                                            <div className='ml-2 mt-2'>
                                                {getFormErrorMessage("checkbox")}
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Asset ID </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <div style={{ position: 'relative' }}>
                                                    <InputText
                                                        disabled
                                                        type='text'
                                                        placeholder='Auto Generate Asset Id'
                                                        id='asset_id'
                                                        name="asset_id"
                                                        value={formik.values.asset_id}
                                                        onChange={formik.handleChange}
                                                        className="p-inputtext-sm"
                                                        autoComplete="off"
                                                    />
                                                    <Button
                                                        disabled={isEditable}
                                                        tooltip='Auto Generate Asset Id'
                                                        tooltipOptions={{ position: "top" }}
                                                        type="button"
                                                        icon="pi pi-refresh"
                                                        className='auto-generate-button'
                                                        onClick={handleGenerateAssetId}
                                                        style={{
                                                            background: '#8d7ad5',
                                                            borderColor: "#8d7ad5",
                                                            position: 'absolute',
                                                            top: '30%',
                                                            right: '8px',
                                                            transform: 'translateY(-50%)',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='ml-2 mt-2'>
                                                {getFormErrorMessage("asset_id")}
                                            </div>

                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">

                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Category </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <Dropdown
                                                    placeholder='--Select Category--'
                                                    id="category"
                                                    name="category"
                                                    options={allCategoryName}
                                                    optionLabel='name'
                                                    optionValue='id'
                                                    value={formik.values.category}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                    autoComplete="off"
                                                ></Dropdown>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("category")}
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Sub Category </label>
                                                </div>
                                                <TreeSelect
                                                    placeholder='--Select Sub Category--'
                                                    id="subCategory"
                                                    name="subCategory"
                                                    options={allSubcategoryName}
                                                    optionLabel='label'
                                                    optionValue='key'
                                                    value={formik.values.subCategory}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm text-sm text-grey-500"
                                                ></TreeSelect>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("subCategory")}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label>Asset Name</label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText
                                                    maxLength={30}
                                                    keyfilter={/^[a-zA-Z\s]*$/}
                                                    placeholder='Enter Asset Name'
                                                    id="assetName"
                                                    name="assetName"
                                                    value={formik?.values?.assetName}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                ></InputText>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("assetName")}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label>Brand</label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <Dropdown
                                                    placeholder='--Select Brand--'
                                                    id="brand"
                                                    name="brand"
                                                    options={allBrandName}
                                                    optionLabel='obj_Name'
                                                    optionValue='obj_id'
                                                    value={formik.values.brand}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                    autoComplete="off"
                                                ></Dropdown>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("brand")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="grid">
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Unit of Measurement </label>
                                                </div>
                                                <Dropdown
                                                    placeholder='--Select Unit of Measurement--'
                                                    id="uom"
                                                    name="uom"
                                                    options={allUomName}
                                                    optionLabel='name'
                                                    optionValue='id'
                                                    value={formik?.values?.uom}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                    autoComplete="off"
                                                ></Dropdown>

                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Supplier/ Vendor Information </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <MultiSelect
                                                    // display='chip'
                                                    placeholder='--Select--'
                                                    id="vendor"
                                                    name="vendor"
                                                    options={allVendorName}
                                                    optionLabel='obj_Name'
                                                    optionValue='obj_id'
                                                    value={formik.values.vendor}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                ></MultiSelect>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("vendor")}
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> RFID Code </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <div style={{ position: 'relative' }}>
                                                    <InputText
                                                        disabled
                                                        type='text'
                                                        placeholder='Auto Generate Rfid Code'
                                                        id='rfid_code'
                                                        name="rfid_code"
                                                        value={formik.values.rfid_code}
                                                        onChange={formik.handleChange}
                                                        className="p-inputtext-sm"
                                                        autoComplete="off"
                                                    />
                                                    <Button
                                                        tooltip='Auto Generate Rfid Code'
                                                        tooltipOptions={{ position: "top" }}
                                                        type="button"
                                                        icon="pi pi-refresh"
                                                        className='auto-generate-button'
                                                        onClick={handleGenerateRfidCode}
                                                        style={{
                                                            background: '#8d7ad5',
                                                            borderColor: "#8d7ad5",
                                                            position: 'absolute',
                                                            top: '30%',
                                                            right: '8px',
                                                            transform: 'translateY(-50%)',
                                                        }}
                                                    />
                                                </div>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("rfid_code")}
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label>SKU</label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText maxLength={10} className="p-inputtext-sm" id="sku" name="sku" placeholder='Enter SKU ' value={formik.values?.sku} onChange={formik.handleChange} type="text" />
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("sku")}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Description </label>
                                                </div>
                                                <InputTextarea rows={5} maxLength={150} className="p-inputtext-sm" id="description" name="description" placeholder='Enter Description' value={formik.values.description} onChange={formik.handleChange} type="text" />
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("description")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="grid">
                                        <div class="col-12 lg:col-8 md:col-8">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Add Product Images </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <div className="flex flex-column mt-2">
                                                    <div className="mb-2 flex flex-column">
                                                        <div className='flex flex-row ml-2'>
                                                            <div className='flex flex-column'>
                                                                <img
                                                                    src={photo}
                                                                    width="100px" height="100px"
                                                                    alt="Profile Image"
                                                                    className="ml-2 photo-bg"
                                                                />
                                                                <FileUpload
                                                                    className="mt-2 p-0 hidden"
                                                                    onSelect={handleFileUpload}
                                                                    accept="image/*"
                                                                    ref={uploadImg}
                                                                />
                                                                <div className='p-fileupload-choose cursor-pointer flex justify-content-center align-items-center mt-3' onClick={() => handleImages()}>
                                                                    <i className='pi pi-plus text-lg' style={{ marginLeft: "1.3rem" }}></i>
                                                                    <span className='p-button-label  ml-2 text-sm'>Choose File</span>
                                                                </div>
                                                                <div onClick={() => handleCamera()} className='p-fileupload-choose cursor-pointer flex justify-content-center align-items-center mt-3'>
                                                                    <i className='pi pi-camera text-lg' style={{ marginLeft: "1.3rem" }}></i>
                                                                    <span className='p-button-label  ml-2 text-sm'>Take a Picture</span>
                                                                </div>
                                                                <Dialog header="Photo" visible={visible} style={{ width: '50vw', height: '50vw' }}
                                                                    onHide={() => {
                                                                        if (!visible) return;
                                                                        setVisible(false)
                                                                        stopCamera();
                                                                    }}>
                                                                    <div className='flex justify-content-center w-full' >
                                                                        <Webcam width={"80%"} ref={webcamRef} />
                                                                    </div>
                                                                    <div className='mt-5'>
                                                                        <Button className='desaturated_blue px-5  block m-auto' onClick={capturePicture} >Take picture</Button>
                                                                    </div>
                                                                </Dialog>
                                                            </div>

                                                            <div className="flex flex-row ">
                                                                {selectedFiles && selectedFiles.length ?
                                                                    <div className='flex flex-row'>
                                                                        {selectedFiles.length === 0 && selectedFiles.length === 0 ? (
                                                                            <p style={{ fontSize: "14px", fontWeight: "600", fontStyle: "italic", color: "gray" }}></p>
                                                                        ) : (
                                                                            selectedFiles && selectedFiles.map((file, index) => (
                                                                                <span key={index} className="" style={{ position: "relative", marginLeft: "8px", marginRight: "8px" }}>
                                                                                    <Image
                                                                                        style={formStyle2}
                                                                                        preview
                                                                                        src={file?.base64Data || `${baseURL}/${file?.path?.split('$$')[0]}`}
                                                                                        zoomSrc={file?.base64Data || `${baseURL}/${file?.path?.split('$$')[0]}`}
                                                                                        alt={`Attachment ${index + 1}`}
                                                                                        width="100"
                                                                                        height="100"
                                                                                        imageStyle={{ borderRadius: "10px", border: '2px solid rgb(172 172 173)' }}
                                                                                    />
                                                                                    <Button
                                                                                        tooltip='Remove Image'
                                                                                        tooltipOptions={{ position: "top" }}
                                                                                        type='button'
                                                                                        icon="pi pi-times"
                                                                                        className='remove-file-button'
                                                                                        style={{
                                                                                            position: "absolute",
                                                                                            top: '0px',
                                                                                            right: '0px',
                                                                                            zIndex: 1,
                                                                                            fontSize: '10px'
                                                                                        }}
                                                                                        onClick={() => handleCancelFile(file)}
                                                                                    />
                                                                                </span>
                                                                            ))
                                                                        )}
                                                                    </div>
                                                                    :
                                                                    <div className='mt-4 flex'>
                                                                        <p style={{ fontSize: "14px", fontWeight: "600", fontStyle: "italic", color: "gray" }}></p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Add Video </label>
                                                </div>
                                                <div className="flex flex-column mt-2">
                                                    <div className="mb-2 flex flex-column">
                                                        <div className='flex flex-row ml-2'>
                                                            <div className='flex flex-column'>
                                                                <img
                                                                    src={video}
                                                                    width="100px" height="100px"
                                                                    alt="Video"
                                                                    className="ml-4 video-bg"
                                                                />
                                                                <FileUpload
                                                                    ref={uploadVideo}
                                                                    accept="video/*"
                                                                    onSelect={(e) => handleFileUpload2(e)}
                                                                    style={{ display: "none" }}
                                                                />
                                                                <div className='p-fileupload-choose cursor-pointer flex justify-content-center align-items-center mt-3' onClick={handleVideo}>
                                                                    <i className='pi pi-plus text-lg' style={{ marginLeft: "1.3rem" }}></i>
                                                                    <span className='p-button-label  ml-2 text-sm'>Choose File</span>
                                                                </div>
                                                            </div>

                                                            <div className="flex flex-row ml-4">
                                                                {selectedFiles2 && selectedFiles2.length ?
                                                                    <div className='flex flex-row'>
                                                                        {selectedFiles2.length === 0 && selectedFiles2.length === 0 ? (
                                                                            <p style={{ fontSize: "14px", fontWeight: "600", fontStyle: "italic", color: "gray" }}></p>
                                                                        ) : (
                                                                            selectedFiles2 && selectedFiles2.map((file, index) => (
                                                                                <span key={index} className="" style={{ position: "relative", marginLeft: "10px" }}>
                                                                                    <video
                                                                                        src={file?.base64Data || `${baseURL}/${file?.path?.split('$$')[0]}`}
                                                                                        alt='Video'
                                                                                        style={{
                                                                                            width: '130px',
                                                                                            height: '100px',
                                                                                            border: '2px solid rgb(172 172 173)',
                                                                                            borderRadius: '10px',
                                                                                            pointerEvents: 'auto',
                                                                                            opacity: 1
                                                                                        }}
                                                                                        controls
                                                                                    />
                                                                                    <Button
                                                                                        tooltip='Remove Video'
                                                                                        tooltipOptions={{ position: "top" }}
                                                                                        type='button'
                                                                                        icon="pi pi-times"
                                                                                        className='remove-file-button'
                                                                                        style={{
                                                                                            position: "absolute",
                                                                                            top: '0px',
                                                                                            right: '0px',
                                                                                            zIndex: 1,
                                                                                            fontSize: '10px'
                                                                                        }}
                                                                                        onClick={() => handleCancelFile2(file)}
                                                                                    />
                                                                                </span>
                                                                            ))
                                                                        )}
                                                                    </div>
                                                                    :
                                                                    <div className='mt-4 flex'>
                                                                        <p style={{ fontSize: "14px", fontWeight: "600", fontStyle: "italic", color: "gray" }}></p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Add Notes </label>
                                                </div>
                                                <InputTextarea rows={5} maxLength={150} className="p-inputtext-sm" id="notes" name="notes" placeholder='Enter Notes' value={formik.values.notes} onChange={formik.handleChange} type="text" />
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("notes")}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label>Product Documentation </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <div className='flex justify-content-between align-items-center px-2 mt-2 ' >
                                                    <p className=' mb-0  text-500' style={{ width: "97%" }}>
                                                        {
                                                            selectedDoc && Object.keys(selectedDoc)?.length !== 0 ? (
                                                                <span className='flex justify-content-between align-items-center mb-0 text-500' >
                                                                    <span className='text-overflow-ellipsis overflow-hidden'>{selectedDoc?.fileName ? selectedDoc?.fileName : selectedDoc?.path ? 'Document' : 'Upload'}</span>
                                                                    <div className='flex flex-row'>
                                                                        <i className='pi pi-times mr-2' style={{ cursor: "pointer" }} onClick={() => setSelectedDoc("")} ></i>
                                                                        <i className='pi pi-download mr-2' style={{ cursor: "pointer", pointerEvents: 'auto', opacity: 1 }} onClick={() => handleDownloadFile(selectedDoc)} ></i>
                                                                    </div>
                                                                </span>
                                                            ) : (
                                                                <>
                                                                    <span className='mb-0 text-500 w-full '>
                                                                        Upload
                                                                    </span>
                                                                </>
                                                            )
                                                        }
                                                    </p>
                                                    <FileUpload ref={uploadDoc} multiple accept={".pdf"} onSelect={(e) => handleSelectedDoc(e)} style={{ display: "none" }} />
                                                    <i className='pi pi-paperclip cursor-pointer' onClick={handleDocument}></i>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {permissions.includes(2) ?
                                        isView ? null : (
                                            <div className='col-12 d-flex flex-row text-center mt-4 pb-2'>
                                                <Button className="Cancel-Button" label="Reset" disabled={isEditable || editableForStage} type="button" onClick={ResetForm} />
                                                <Button className="Save-Button ml-2" label={isEditable || editableForStage ? "Update" : 'Add'} loading={saveBtnLoading} type="submit" />
                                            </div>)
                                        :
                                        null
                                    }

                                </div>
                            </div>

                        </form>

                    </div>
                </div>

            </LoadingOverlay>
        </>
    )
}

export default AddEditAsset