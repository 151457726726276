import { useFormik } from 'formik';
import * as Yup from "yup";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useEffect, useState } from 'react'
import LoadingOverlay from 'react-loading-overlay'
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { handlePostRequest } from '../../services/PostTemplate';
import { handlePutRequest } from '../../services/PutTemplate';
import { useDispatch } from 'react-redux';
import { handleGetRequest } from '../../services/GetTemplate';

function AddEditVendor() {

  const [isActive, setIsActive] = useState(false);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false)

  const location = useLocation();
  const history = useHistory()
  const dispatch = useDispatch()

  const user_id = localStorage.getItem("user_id")
  const warehouseid = localStorage.getItem("warehouseid")
  const roletypeid = localStorage.getItem("roletypeid")
  const usertype_id = localStorage.getItem("usertype_id")

  const { isEditable, rowData, isView } = location?.state;

  const validationSchema = Yup.object().shape({
    name: Yup.mixed().required("This field is required."),
    email: Yup.string()
    .matches(/.*\..*/, "Please enter a valid email")
    .required("This field is required."),
    company_name: Yup.mixed().required("This field is required."),

  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      name: "",
      address: "",
      email: "",
      contact_number: "",
      company_name: "",
      add_note: "",
    },

    onSubmit: async (data) => {

      if (isEditable) {
        setSaveBtnLoading(true);

        const obj = {
          obj_id: rowData?.obj_id,
          name: formik.values.name,
          description: formik.values.add_note,
          warehouse_id: warehouseid,
          address: formik.values.address,
          contact_no: formik.values.contact_number,
          email: formik.values.email,
          company: formik.values.company_name,
          isActive: rowData?.status
        }

        const response = await dispatch(handlePutRequest(obj, "/api/Setups/UpdateVendor", true, true));
        if (response?.statusCode == 200) {
          history.push('./vendor')
          setSaveBtnLoading(false);
        }
      }
      else {
        setSaveBtnLoading(true);

        const obj = {
          name: formik.values.name,
          description: formik.values.add_note,
          warehouse_id: warehouseid,
          address: formik.values.address,
          contact_no: formik.values.contact_number,
          email: formik.values.email,
          company: formik.values.company_name,
        }

        const response = await dispatch(handlePostRequest(obj, "/api/Setups/AddVendor", true, true));
        if (response?.statusCode == 200) {
          history.push('./vendor')
          setSaveBtnLoading(false);
        }

        setSaveBtnLoading(false);
      }
    },
  })

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  const ResetForm = (e) => {
    e.preventDefault()
    formik.setValues({
      name: "",
      address: "",
      email: "",
      contact_number: "",
      company_name: "",
      add_note: "",
    })

  }

  //Get WH By Id
  const getVendorById = async (rowData) => {
    setIsActive(true)
    const response = await dispatch(handleGetRequest(`/api/Setups/GetVendorByid?obj_id=${rowData?.obj_id}`, false));
    const keyData = response[0]
    if (keyData) {
      formik.setFieldValue('address', keyData?.address)
      formik.setFieldValue('contact_number', keyData?.contact_no)
      formik.setFieldValue('email', keyData?.email)
      formik.setFieldValue('company_name', keyData?.company)
      formik.setFieldValue('add_note', keyData?.obj_Description)
      formik.setFieldValue('name', keyData?.obj_Name)
    }
    setIsActive(false)
  };

  useEffect(() => {
    if (isEditable, rowData) {
      getVendorById(rowData);
    }
  }, [isEditable, rowData]);

  //Disable Form while Viewing
  const formStyle = {
    pointerEvents: isView ? 'none' : 'auto',
    opacity: isView ? 0.7 : 1,
  };


  return (
    <>
      <LoadingOverlay
        active={isActive}
        spinner
        text='Loading your content...'
        styles={{
          overlay: (base) => ({
            ...base,
            background: 'rgb(38 41 51 / 78%)',
            width: '107.9%',
            height: '125%',
            top: '-27px',
            left: '-35px'
          })
        }}
      >

        <div className='main-form-card'>
          <div className='form-header flex flex-row justify-content-between'>
            <div className='form-header-content'>
              <h4 style={{ fontWeight: '600', letterSpacing: '1px', color: 'black' }}>{isEditable && isView ? 'View Vendor' : isEditable && isView === false ? 'Edit Vendor' : 'Add New Vendor'}</h4>
              <h6 style={{ color: 'gray' }}>{isView ? '' : 'Add Details'} </h6>
            </div>
            <div className='mt-4 mr-3'>
              <Button className='Back-Button mr-3' icon="pi pi-arrow-left" label='Go Back' onClick={() => history.goBack()}>
              </Button>
            </div>
          </div>

          <div className='form-div'>
            <form onSubmit={formik.handleSubmit} style={formStyle}>
              <div className="card-form">
                <div className="card-nested-form">
                  <div className="grid">
                    <div className="col-12 lg:col-4 md:col-4">
                      <div className="input-card flex flex-column p-3">
                        <div className='flex-row'>
                          <label> Name </label>
                          <span className="Label__Required">*</span>
                        </div>
                        <InputText keyfilter={/^[a-zA-Z\s]*$/} maxLength={30} className="p-inputtext-sm" id="name" name="name" placeholder='Enter Vendor Name' value={formik.values.name} onChange={formik.handleChange} type="text" />
                        <span className='ml-2'>
                          {getFormErrorMessage("name")}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 lg:col-4 md:col-4">
                      <div className="input-card flex flex-column p-3">
                        <div className='flex-row'>
                          <label> Address </label>
                          {/* <span className="Label__Required">*</span> */}
                        </div>
                        <InputText maxLength={30} className="p-inputtext-sm" id="address" name="address" placeholder='Enter Address' value={formik.values.address} onChange={formik.handleChange} type="text" />
                        <span className='ml-2'>
                          {getFormErrorMessage("address")}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 lg:col-4 md:col-4">
                      <div className="input-card flex flex-column p-3">
                        <div className='flex-row'>
                          <label> Email </label>
                          <span className="Label__Required">*</span>
                        </div>
                        <InputText maxLength={30} className="p-inputtext-sm" id="email" name="email" placeholder='Enter Email' value={formik.values.email} onChange={formik.handleChange} type="email" />
                        <span className='ml-2'>
                          {getFormErrorMessage("email")}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 lg:col-4 md:col-4">
                      <div className="input-card flex flex-column p-3">
                        <div className='flex-row'>
                          <label> Contact No </label>
                          {/* <span className="Label__Required">*</span> */}
                        </div>
                        <InputText keyfilter={/^[0-9.!@#$%^&*]+$/} maxLength={15} minLength={10} className="p-inputtext-sm" id="contact_number" name="contact_number" placeholder='Enter Contact Number' value={formik.values.contact_number} onChange={formik.handleChange} type="text" />
                        <span className='ml-2'>
                          {getFormErrorMessage("contact_number")}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 lg:col-4 md:col-4">
                      <div className="input-card flex flex-column p-3">
                        <div className='flex-row'>
                          <label> Company Name </label>
                          <span className="Label__Required">*</span>
                        </div>
                        <InputText maxLength={30} className="p-inputtext-sm" id="company_name" name="company_name" placeholder='Enter Company Name' value={formik.values.company_name} onChange={formik.handleChange} type="text" />
                        <span className='ml-2'>
                          {getFormErrorMessage("company_name")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="grid">
                    <div className="col-12 lg:col-4 md:col-4">
                      <div className="input-card flex flex-column p-3">
                        <div className='flex-row'>
                          <label> Description </label>
                          {/* <span className="Label__Required">*</span> */}
                        </div>
                        <InputTextarea maxLength={250} rows={5} className="p-inputtext-sm" id="add_note" name="add_note" placeholder='Add Description' value={formik.values.add_note} onChange={formik.handleChange} type="text" />
                        <span className='ml-2'>
                          {getFormErrorMessage("add_note")}
                        </span>
                      </div>
                    </div>
                  </div>
                  {isView ?
                    null
                    :
                    <div className='col-12 d-flex flex-row text-center mt-4 pb-2'>
                      <Button className="Cancel-Button" label="Reset" type="button" onClick={(e) => ResetForm(e)} />
                      <Button className="Save-Button ml-2" label={isEditable ? "Update" : "Add"} loading={saveBtnLoading} type="submit" />
                    </div>
                  }
                </div>
              </div>
            </form>
          </div>
        </div>

      </LoadingOverlay>
    </>
  )
}

export default AddEditVendor