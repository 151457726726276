import React, { useState, useEffect, useContext, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { InputText } from "primereact/inputtext";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { confirmDialog } from "primereact/confirmdialog";
import { useHistory } from "react-router-dom";
import { SplitButton } from "primereact/splitbutton";
import { handlePutRequest } from "../../services/PutTemplate";
import { Tag } from "primereact/tag";
import { baseURL } from "../../Config";
import dp from '../../assets/warehouse-assets/dp.jpeg'
import { Image } from "primereact/image";

const ManageWarehouse = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")

    const [permissions, setPermissions] = useState([])
    const [isActive, setIsActive] = useState(false)
    const [allWH, setAllWH] = useState([]);
    const [acceptButtonClass, setAcceptButtonClass] = useState('p-button-danger');

    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const actionTemplate = (rowData) => {
        const items = [
            {
                label: 'Edit',
                command: () => handleEditWH(rowData),
            },
            {
                label: rowData?.status == 1 ? 'Deactivate' : 'Activate',
                command: () => confirm(rowData),
            },
        ];
        return (
            <div className="">
                <SplitButton label="" model={items} />
            </div>
        );
    };


    const confirm = (rowData) => {
        confirmDialog({
            message: rowData?.status == 1 ? 'Are you sure you want to deactivate this Warehouse?' : 'Are you sure you want to Activate this Warehouse?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: acceptButtonClass,
            acceptLabel: 'Yes',
            accept: () => {
                if (rowData?.status == 1) {
                    handleDeactivateWH(rowData);
                } else {
                    handleActivateWH(rowData);
                }
            },
            reject: () => {
                return null;
            },
        });
    };

    const handleDeactivateWH = async (rowData) => {

        let obj = {
            obj_id: rowData?.obj_id,
            p_isactive: 0,
        }

        setIsActive(true);

        const response = await dispatch(handlePutRequest(obj, "/api/Setups/Deletewarehouse", true, true));
        if (response?.statusCode == 200) {
            await getWHList();
        }
        setIsActive(false);
    }

    const handleActivateWH = async (rowData) => {

        let obj = {
            obj_id: rowData?.obj_id,
            p_isactive: 1,
        }

        setIsActive(true);
        const response = await dispatch(handlePutRequest(obj, `/api/Setups/Deletewarehouse`, true, true));
        if (response?.statusCode == 200) {
            await getWHList();
        }
        setIsActive(false);
    }

    const handleEditWH = (rowData) => {
        const item = true;
        history.push({
            pathname: './addeditwarehouse',
            state: {
                isEditable: item,
                rowData: rowData,
            }
        });
    };

    //Get WH List
    const getWHList = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest(`/api/Setups/GetAllWarehouse`, true));
        if (response) {
            setAllWH(response);
        }
        setIsActive(false);
    };

    useEffect(() => {
        getWHList()
    }, [])

    const imageTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div>
                    {rowData?.logo_img ?
                        <Image src={`${baseURL}/${rowData?.logo_img}`} preview zoomSrc={`${baseURL}/${rowData?.logo_img}`} imageStyle={{ borderRadius: "50%" }} className='mr-3' alt='dp' width="45" height="45" borderRadius='50%' style={{ width: '45px', height: '45px', borderRadius: "50%", }} />
                        :
                        <Image src={dp} zoomSrc={dp} preview className='mr-3' alt='dp' width="45" height="45" borderRadius='50%' imageStyle={{ borderRadius: "50%" }} style={{ width: '45px', height: '45px', borderRadius: "50%", }} />
                    }
                </div>
            </React.Fragment>
        );
    };

    useEffect(() => {

        const myPermissions = JSON.parse(localStorage.getItem("permissions"))
        const allPermModules = myPermissions?.find(module => module?.routepath?.includes("/warehouse"));
        if (allPermModules) {
            setPermissions(allPermModules?.permissions?.map(item => item?.permission_id) || []);
        }
    }, []);

    return (
        
        <>
            <div className="flex flex-column align-items-start md:flex-row  md:justify-content-between md:align-items-center ">
                <h4 className="mb-0" style={{ fontWeight: '600', letterSpacing: '0.5px', color: 'black' }}>
                    Warehouse Management
                </h4>
                <div className="flex flex-column align-items-start  md:flex-row md:align-items-center">
                    <span className="p-input-icon-left mr-3 resp-margin my-3 md:my-0">
                        <i className="pi pi-search" />
                        <InputText
                            placeholder="Search"
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            className="search-input"
                        />
                    </span>
                    {permissions.includes(2) ?
                        <div className="">
                            <Button
                                label="Add New"
                                className="Add__New-Button ml-3"
                                icon="pi pi-plus"
                                onClick={() => {
                                    const item = false;
                                    history.push({
                                        pathname: './addeditwarehouse',
                                        state: {
                                            isEditable: item,
                                        }
                                    });
                                }}
                            />
                        </div>
                        :
                        null}
                </div>
            </div>
            
            <div className="card mt-3">

                <LoadingOverlay
                    active={isActive}
                    spinner={<Triangle
                        height="120"
                        width="120"
                        color="#755fc8"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />}

                    // text='Loading your content...'
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: 'rgb(38 41 51 / 78%)',
                        })
                    }}
                >

                    <DataTable
                        responsive={true}
                        filters={filters}
                        globalFilterFields={[
                            "warehouse_id",
                            "obj_Name",
                            "warehouse_poc",
                            "contact_no",
                            "location",
                            "address",
                        ]}
                        responsiveLayout="scroll"
                        stripedRows
                        paginator
                        rows={20}
                        value={allWH}
                        emptyMessage="No records found"
                        paginatorClassName="custom-paginator"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    >
                        <Column body={imageTemplate} header="Warehouse Logo"></Column>
                        <Column sortable field='warehouse_id' body={(rowData) => rowData?.warehouse_id || 'N/A'} header="Warehouse Id"></Column>
                        <Column sortable field='obj_Name' body={(rowData) => rowData?.obj_Name || 'N/A'} header="Warehouse Name"></Column>
                        <Column
                            field="warehouse_poc"
                            sortable
                            body={(rowData) => (
                                <div>
                                    {<div style={{ whiteSpace: 'pre-wrap' }}>
                                        {rowData?.warehouse_poc?.replace(/(.{25})/g, '$1\n') || 'N/A'}
                                    </div>}
                                </div>
                            )}
                            header="Warehouse POC"
                        ></Column>
                        <Column sortable field='contact_no' body={(rowData) => rowData?.contact_no || 'N/A'} header="Contact No."></Column>
                        <Column
                            field="location"
                            sortable
                            body={(rowData) => (
                                <div>
                                    {<div style={{ whiteSpace: 'pre-wrap' }}>
                                        {rowData?.location?.replace(/(.{25})/g, '$1\n') || 'N/A'}
                                    </div>}
                                </div>
                            )}
                            header="Location"
                        ></Column>
                        <Column
                            sortable
                            field="status"
                            body={(rowData) => (
                                <Tag severity={rowData?.status == 1 ? "success" : "danger"} value={rowData?.status == 1 ? 'Acitve' : 'Inactive'}></Tag>
                            )}
                            header="Status"
                        />
                        {permissions.includes(1) ?
                            <Column header="Action" body={actionTemplate} />
                            :
                            null
                        }
                    </DataTable>
                </LoadingOverlay>
            </div>
        </>
    );
};

export default ManageWarehouse