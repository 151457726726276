import React, { useEffect, useState, useRef } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import LoadingOverlay from 'react-loading-overlay';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { handleGetRequest } from '../../services/GetTemplate';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../services/PostTemplate';
import { handlePutRequest } from '../../services/PutTemplate';
import { useHistory, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import profilePic from '../../assets/warehouse-assets/warehouse.png'
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import uploadImage from '../../assets/warehouse-assets/uploadImage.png'
import dp from '../../assets/warehouse-assets/dp.jpeg'
import { baseURL } from '../../Config';

const Settings = () => {

    const userId = localStorage.getItem("userId")
    const location = useLocation()

    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [userInput, setUserInput] = useState("")
    const [allRoles, setAllRoles] = useState([])

    const toast = useRef(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const [fileInfo, setFileInfo] = useState(null);
    const [showImage, setShowImage] = useState('');
    const [editableRemoved, setEditableRemoved] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };


    const togglePasswordVisibility2 = () => {
        setShowPassword2(prevState => !prevState);
    };


    const history = useHistory()
    const dispatch = useDispatch()

    const validationSchema = Yup.object().shape({
        first_name: Yup.mixed().required("This field is required."),
        last_name: Yup.mixed().required("This field is required."),
        email: Yup.mixed().required("This field is required."),
        newPassword: Yup.mixed().required("This field is required."),
        gender: Yup.mixed().required("This field is required."),
        contact: Yup.mixed().required("This field is required."),
        designation: Yup.mixed().required("This field is required."),
        department: Yup.mixed().required("This field is required."),
        role: Yup.mixed().required("This field is required."),
    });

    const formik = useFormik({

        validationSchema: validationSchema,
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            newPassword: "",
            password: "",
            gender: "",
            designation: "",
            contact: "",
            department: "",
            role: ""
        },

        onSubmit: async (data) => {


        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    //Profile Pic
    const handleIconClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.onchange = handleChange;
        fileInput.click();
    };

    const handleChange = (event) => {
        processFile(event.target.files[0]);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            processFile(event.dataTransfer.files[0]);
            event.dataTransfer.clearData();
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const processFile = (event) => {
        const file = event;
        if (file) {
            const objectURL = URL.createObjectURL(file);
            setFileInfo({
                file: file,  // Storing the actual File object
                objectURL: objectURL,
                lastModified: file.lastModified,
                lastModifiedDate: file.lastModifiedDate,
                name: file.name,
                size: file.size,
                type: file.type,
                webkitRelativePath: file.webkitRelativePath || ""
            });
        }
    };

    const handleRemoveImage = () => {
        if (showImage) {
            URL.revokeObjectURL(fileInfo.objectURL); // Free up memory by releasing object URL
            setShowImage('')
            setEditableRemoved(true)
        }
        if (fileInfo) {
            URL.revokeObjectURL(fileInfo.objectURL); // Free up memory by releasing object URL
            setShowImage('')
        }
        setFileInfo(null);
    };

    return (

        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgb(38 41 51 / 78%)',
                        width: '107.9%',
                        height: '125%',
                        top: '-27px',
                        left: '-35px'
                    })
                }}
            >

                <div className='main-form-card'>
                    <div className='settings-header'>
                        <div className='form-header-content'>
                            <h4 style={{ fontWeight: '600', letterSpacing: '1px', color: 'black' }}> Profile Settings </h4>
                            <h6 style={{ color: 'gray' }}> Update your profile</h6>
                        </div>
                    </div>
                    <div className='form-div'>
                        <form onSubmit={formik.handleSubmit}>
                            {/* 1st card */}
                            <div className="card-form">
                                <div className="grid p-3">
                                    <div className="col-12 lg:col-4 md:col-4 p-5">
                                        <div className='flex flex-column'>
                                            <span style={{ fontWeight: "bold", fontSize: "18px", color: 'black' }}>Profile Photo</span>
                                            <span style={{ color: "light", fontSize: "14px" }}>This image will be displayed on your profile</span>
                                        </div>
                                    </div>
                                    <div className="settings-header col-12 lg:col-2 md:col-2 p-3 flex">
                                        {fileInfo || showImage ?
                                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                                <img src={fileInfo?.objectURL} alt="Uploaded" style={{ width: '120px', height: '120px', borderRadius: "50%" }} />
                                                <i className="pi pi-times" style={{ position: 'absolute', top: '0', right: '0', cursor: 'pointer', fontSize: '1em', color: 'red', padding: '5px', background: 'rgba(255, 255, 255, 0.8)', borderRadius: '50%' }} onClick={handleRemoveImage}></i>
                                            </div>
                                            :
                                            null}
                                    </div>
                                    <div className="settings-header col-12 lg:col-6 md:col-6 p-3">
                                        <div>
                                            <div className="flex align-items-center flex-column dp-scrollable"
                                                onDrop={handleDrop} onDragOver={handleDragOver}
                                                style={{ border: '2px dashed #ccc', padding: '20px', width: '100%', textAlign: 'center' }}>
                                                <div style={{ backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <i className="pi pi-cloud-upload" style={{ fontSize: '2em', cursor: 'pointer' }} onClick={handleIconClick}></i>
                                                </div>
                                                <span style={{ fontSize: '1.2em', color: 'black' }} className="mb-1 image-template">
                                                    <span style={{ fontWeight: "bold" }}> Click to Upload </span> or drag and drop
                                                </span>
                                                <span style={{ fontSize: '1em', color: 'gray' }} className="mb-2 image-template">
                                                    JPG or PNG (Recommended size 500x500px)
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='settings-header col-12 d-flex flex-row text-right mt-0 p-2'>
                                    <Button className="Cancel-Button" label="Cancel" type="button" onClick='' />
                                    <Button className="Save-Button ml-2" label="Save" loading={saveBtnLoading} type="submit" />
                                </div>
                            </div>
                            {/* 2nd card */}
                            <div className="card-form">
                                <div className="grid p-3">
                                    <div className="settings-header col-12 lg:col-4 md:col-4 p-3">
                                        <div className='flex flex-column'>
                                            <span style={{ fontWeight: "bold", fontSize: "18px", color: 'black' }}> Personal Information</span>
                                            <span style={{ color: "gray", fontSize: "14px" }}>Your Personal Details</span>
                                        </div>
                                    </div>
                                    <div className="col-12 lg:col-8 md:col-8 p-3">
                                        <div className="grid">
                                            <div className="col-12 md:col-6">
                                                <div className="input-card p-3">
                                                    <div className='flex-row'>
                                                        <label> User ID</label>
                                                    </div>
                                                    <InputText className="p-inputtext-sm" id="user_id" name="user_id" placeholder='User ID' value={formik.values.user_id} onChange={formik.handleChange} type="text" disabled={true} />
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <div className="input-card p-3">
                                                    <div className='flex-row'>
                                                        <label> Full Name </label>
                                                        <span className="Label__Required">*</span>
                                                    </div>
                                                    <InputText className="p-inputtext-sm" id="full_name" name="full_name" placeholder='Enter Full Name' value={formik.values.full_name} onChange={formik.handleChange} type="text" />
                                                    {getFormErrorMessage("full_name")}
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <div className="input-card p-3">
                                                    <div className='flex-row'>
                                                        <label> Contact Number </label>
                                                        <span className="Label__Required">*</span>
                                                    </div>
                                                    <InputText className="p-inputtext-sm" id="contact_number" name="contact_number" placeholder='Enter Contact Number' value={formik.values.contact_number} onChange={formik.handleChange} type="text" />
                                                    {getFormErrorMessage("contact_number")}
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <div className="input-card p-3">
                                                    <div className='flex-row'>
                                                        <label> Email Address </label>
                                                        <span className="Label__Required">*</span>
                                                    </div>
                                                    <InputText className="p-inputtext-sm" id="email_address" name="email_address" placeholder='Enter Email Address' value={formik.values.email_address} onChange={formik.handleChange} type="text" />
                                                    {getFormErrorMessage("email_address")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='settings-header col-12 d-flex flex-row text-right mt-2 p-4'>
                                    <Button className="Cancel-Button" label="Cancel" type="button" onClick='' />
                                    <Button className="Save-Button ml-2" label="Save" loading={saveBtnLoading} type="submit" />
                                </div>
                            </div>
                            {/* 3rd card */}
                            <div className="card-form">
                                <div className="grid p-3">
                                    <div className="settings-header col-12 lg:col-4 md:col-4 p-3">
                                        <div className='flex flex-column'>
                                            <span style={{ fontWeight: "bold", fontSize: "18px", color: 'black' }}> Activity Log</span>
                                            <span style={{ color: "gray", fontSize: "14px" }}>Last 5 login history</span>
                                        </div>
                                    </div>
                                    <div className="col-12 lg:col-8 md:col-8" style={{ fontWeight: '600', color: 'black' }}>
                                        <div className="grid">
                                            <div className="col-12 md:col-6">
                                                <span>Monday 8:00 am (19 April,2024)</span>
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <span>Tuesday 9:00 am (20 April,2024)</span>
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <span>Wednesday 8:00 am (21 April,2024)</span>
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <span>Thursday 10:00 am (22 April,2024)</span>
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <span>Friday 12:00 am (23 April,2024)</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 4th card */}
                            <div className="card-form">
                                <div className="grid p-3">
                                    <div className="settings-header col-12 lg:col-4 md:col-4 p-3">
                                        <div className='flex flex-column'>
                                            <span style={{ fontWeight: "bold", fontSize: "18px", color: 'black' }}> Password</span>
                                            <span style={{ color: "gray", fontSize: "14px" }}>Enter current password to make update</span>
                                        </div>
                                    </div>
                                    <div className="col-12 lg:col-8 md:col-8 p-3">
                                        <div className="grid">
                                            <div className="col-12 md:col-6">
                                                <div className="input-card p-3">
                                                    <div className='flex-row'>
                                                        <label>Current Password</label>
                                                    </div>
                                                    <InputText
                                                        className="p-inputtext-sm"
                                                        id="password"
                                                        name="password"
                                                        placeholder='Enter Current Password'
                                                        value={formik.values.password}
                                                        onChange={formik.handleChange}
                                                        type={showPassword ? 'text' : 'password'}
                                                    />
                                                    <i
                                                        style={{ color: 'gray', marginLeft: "-30px", marginTop: "0px" }}
                                                        className={!showPassword ? 'pi pi-eye' : 'pi pi-eye-slash'}
                                                        onClick={togglePasswordVisibility}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <div className="input-card p-3">
                                                    <div className='flex-row'>
                                                        <label> New Password </label>
                                                        <span className="Label__Required">*</span>
                                                    </div>
                                                    <InputText
                                                        className="p-inputtext-sm"
                                                        id="password"
                                                        name="password"
                                                        placeholder='Enter New Password'
                                                        value={formik.values.newPassword}
                                                        onChange={formik.handleChange}
                                                        type={showPassword2 ? 'text' : 'password'}
                                                    />
                                                    <i
                                                        style={{ color: 'gray', marginLeft: "-30px", marginTop: "0px" }}
                                                        className={!showPassword2 ? 'pi pi-eye' : 'pi pi-eye-slash'}
                                                        onClick={togglePasswordVisibility2}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='settings-header col-12 d-flex flex-row text-right mt-2 p-4'>
                                    <Button className="Cancel-Button" label="Cancel" type="button" onClick='' />
                                    <Button className="Save-Button ml-2" label="Save" loading={saveBtnLoading} type="submit" />
                                </div>
                            </div>
                        </form>
                    </div>

                </div>

            </LoadingOverlay>
        </>
    )
}

export default Settings