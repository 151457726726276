import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import img1 from '../../assets/warehouse-assets/report-icons/svg/inventory by warehouse.svg'
import img2 from '../../assets/warehouse-assets/report-icons/svg/inventory by user.svg'
import img3 from '../../assets/warehouse-assets/report-icons/svg/items by value for each user.svg'
import img4 from '../../assets/warehouse-assets/report-icons/svg/Inventory vendor for each user.svg'
import img5 from '../../assets/warehouse-assets/report-icons/svg/asset vendor for each user.png'
import img6 from '../../assets/warehouse-assets/report-icons/svg/items replanished in last 15 days.png'

import img7 from '../../assets/warehouse-assets/report-icons/svg/to be replanished.svg'
import img8 from '../../assets/warehouse-assets/report-icons/svg/registered assets.svg'
import img9 from '../../assets/warehouse-assets/report-icons/svg/registered inventory.svg'
import img10 from '../../assets/warehouse-assets/report-icons/svg/Inventory by value.svg'
import img11 from '../../assets/warehouse-assets/report-icons/svg/inventory by quantity.svg'
import img12 from '../../assets/warehouse-assets/report-icons/svg/vendor wise Inventory.svg'
import img13 from '../../assets/warehouse-assets/report-icons/svg/vendor wise asset.png'
import img14 from '../../assets/warehouse-assets/report-icons/svg/items replanished in last 15 days.png'


import { useHistory } from "react-router-dom";

const Reporting = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")

    return (

        <>
            <div className="" style={{ overflow: 'auto' }}>
                <h4 className="mb-0" style={{ fontWeight: '600', letterSpacing: '0.5px', color: 'black' }}>
                    Reporting
                </h4>
                {/* Super Admin */}
                {(roletypeid == 1) || (roletypeid == 2 && usertype_id == 1 && !warehouseid)
                    ?
                    <>
                        <div className="card mt-4 flex flex-row justify-content-between p-4 mr-3 reporting-card-gradient align-items-center" onClick={() => history.push('./report-warehouseinv')}>
                            <div className="reporting-card-flex">
                                <img src={img1} />
                                <span className="ml-6 reporting-header"> Inventory by warehouse </span>
                            </div>
                            <i className="pi pi-angle-right icon-angle-styling"></i>
                        </div>
                        <div className="card mt-4 flex flex-row justify-content-between p-4 mr-3 reporting-card-gradient align-items-center" onClick={() => history.push('./report-usersinv')}>
                            <div className="reporting-card-flex">
                                <img src={img2} />
                                <span className="ml-6 reporting-header"> Inventory by Users </span>
                            </div>
                            <i className="pi pi-angle-right icon-angle-styling"></i>
                        </div>
                        <div className="card mt-4 flex flex-row justify-content-between p-4 mr-3 reporting-card-gradient align-items-center" onClick={() => history.push('./report-usersinvvalue')}>
                            <div className="reporting-card-flex">
                                <img src={img3} />
                                <span className="ml-6 reporting-header"> Inventory value for each user  </span>
                            </div>
                            <i className="pi pi-angle-right icon-angle-styling"></i>
                        </div>
                        <div className="card mt-4 flex flex-row justify-content-between p-4 mr-3 reporting-card-gradient align-items-center" onClick={() => history.push('./report-vendorinvvalue')}>
                            <div className="reporting-card-flex">
                                <img src={img4} />
                                <span className="ml-6 reporting-header"> Inventory vendors for each user </span>
                            </div>
                            <i className="pi pi-angle-right icon-angle-styling"></i>
                        </div>
                        <div className="card mt-4 flex flex-row justify-content-between p-4 mr-3 reporting-card-gradient align-items-center" onClick={() => history.push('./report-vendorassetvalue')}>
                            <div className="reporting-card-flex">
                                <img src={img5} />
                                <span className="ml-6 reporting-header"> Asset vendors for each user </span>
                            </div>
                            <i className="pi pi-angle-right icon-angle-styling"></i>
                        </div>
                        <div className="card mt-4 flex flex-row justify-content-between p-4 mr-3 reporting-card-gradient align-items-center" onClick={() => history.push('./report-replinisheditems')}>
                            <div className="reporting-card-flex">
                                <img src={img6} />
                                <span className="ml-6 reporting-header"> Items replenished in last 15 days </span>
                            </div>
                            <i className="pi pi-angle-right icon-angle-styling"></i>
                        </div>
                    </>
                    :
                    <>
                        {/* Admin */}
                        <div className="card mt-4 flex flex-row justify-content-between p-4 mr-3 reporting-card-gradient align-items-center" onClick={() => history.push('./replenisheditemreport')}>
                            <div className="reporting-card-flex">
                                <img src={img7} />
                                <span className="ml-6 reporting-header">  Replenished Item Report </span>
                            </div>
                            <i className="pi pi-angle-right icon-angle-styling"></i>
                        </div>
                        <div className="card mt-4 flex flex-row justify-content-between p-4 mr-3 reporting-card-gradient align-items-center" onClick={() => history.push('./report-registeredassets')}>
                            <div className="reporting-card-flex">
                                <img src={img8} />
                                <span className="ml-6 reporting-header"> Registered Assets </span>
                            </div>
                            <i className="pi pi-angle-right icon-angle-styling"></i>
                        </div>
                        <div className="card mt-4 flex flex-row justify-content-between p-4 mr-3 reporting-card-gradient align-items-center" onClick={() => history.push('./report-registeredinventory')}>
                            <div className="reporting-card-flex">
                                <img src={img9} />
                                <span className="ml-6 reporting-header"> Registered Inventory </span>
                            </div>
                            <i className="pi pi-angle-right icon-angle-styling"></i>
                        </div>
                        <div className="card mt-4 flex flex-row justify-content-between p-4 mr-3 reporting-card-gradient align-items-center" onClick={() => history.push('./inventoryvaluereport')}>
                            <div className="reporting-card-flex">
                                <img src={img10} />
                                <span className="ml-6 reporting-header"> Inventory by value </span>
                            </div>
                            <i className="pi pi-angle-right icon-angle-styling"></i>
                        </div>
                        <div className="card mt-4 flex flex-row justify-content-between p-4 mr-3 reporting-card-gradient align-items-center" onClick={() => history.push('./inventoryquantityreport')}>
                            <div className="reporting-card-flex">
                                <img src={img11} />
                                <span className="ml-6 reporting-header"> Inventory by quantity </span>
                            </div>
                            <i className="pi pi-angle-right icon-angle-styling"></i>
                        </div>
                        <div className="card mt-4 flex flex-row justify-content-between p-4 mr-3 reporting-card-gradient align-items-center" onClick={() => history.push('./report-vendorinventory')}>
                            <div className="reporting-card-flex">
                                <img src={img12} />
                                <span className="ml-6 reporting-header"> Vendor-wise Inventory </span>
                            </div>
                            <i className="pi pi-angle-right icon-angle-styling"></i>
                        </div>
                        <div className="card mt-4 flex flex-row justify-content-between p-4 mr-3 reporting-card-gradient align-items-center" onClick={() => history.push('./report-vendorasset')}>
                            <div className="reporting-card-flex">
                                <img src={img13} />
                                <span className="ml-6 reporting-header"> Vendor-wise Assets </span>
                            </div>
                            <i className="pi pi-angle-right icon-angle-styling"></i>
                        </div>
                        <div className="card mt-4 flex flex-row justify-content-between p-4 mr-3 reporting-card-gradient align-items-center" onClick={() => history.push('./report-borrowitems')}>
                            <div className="reporting-card-flex">
                                <img src={img14} />
                                <span className="ml-6 reporting-header">  Borrowed items by days </span>
                            </div>
                            <i className="pi pi-angle-right icon-angle-styling"></i>
                        </div>
                    </>
                }
            </div>

        </>
    );
};

export default Reporting