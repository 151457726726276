import { createSlice } from "@reduxjs/toolkit";

const initialState = JSON.parse(localStorage.getItem('customerDetail')) || {};
export const checkoutSlice = createSlice({
    name: "customerScreen",
    initialState,
    reducers: {
        setCustomerData: (state, action) => {
            return { ...action.payload };
        }
    }
})

export const { setCustomerData } = checkoutSlice.actions
export default checkoutSlice.reducer


