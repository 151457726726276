import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { confirmDialog } from "primereact/confirmdialog";
import { useHistory } from "react-router-dom";
import { SplitButton } from "primereact/splitbutton";
import { handlePutRequest } from "../../../services/PutTemplate";
import { handleGetRequest } from "../../../services/GetTemplate";
import { handleDeleteRequest } from "../../../services/DeleteTemplate";
import moment from "moment";

const CheckOutGrid = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const [isActive, setIsActive] = useState(false)
    const [checkInList, setCheckInList] = useState([]);
    const [acceptButtonClass, setAcceptButtonClass] = useState('p-button-danger');

    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")

    const [permissions, setPermissions] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const actionTemplate = (rowData) => {

        const items = [];

        if ((roletypeid == 1) || (roletypeid == 2 && usertype_id == 1 && !warehouseid)) {
            items.push({
                label: 'No Action Permitted',
            });
        } else {
            items.push(
                {
                    label: 'Delete',
                    command: () => confirm(rowData),
                },
            );
        }

        return (
            <div>
                <SplitButton label="" model={items} />
            </div>
        );
    };

    const confirm = (rowData) => {

        confirmDialog({
            message: rowData?.status == 1 ? 'Are you sure you want to deactivate this Checkout record?' : 'Are you sure you want to Activate this Checkout record?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: acceptButtonClass,
            acceptLabel: 'Yes',
            accept: () => {
                handleDeleteCheckOut(rowData);
            },
            reject: () => {
                return null;
            },
        });

    };

    const handleDeleteCheckOut = async (rowData) => {

        const obj = {
            p_id: rowData?.id,

        };
        setIsActive(true);

        const response = await dispatch(handleDeleteRequest(obj, `/api/Inventory/DeleteCheckOutById`, true, true));
        if (response) {
            await getCheckOutList();
        }
        setIsActive(false);
    }


    //Get Inventory List
    const getCheckOutList = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest(`/api/Inventory/GetCheckoutList?warehouseid=${warehouseid || 0}`, true));
        if (response) {
            setCheckInList(response);
        }
        setIsActive(false);

    };

    useEffect(() => {
        getCheckOutList()
    }, [])

    useEffect(() => {

        const myPermissions = JSON.parse(localStorage.getItem("permissions"))
        const allPermModules = myPermissions?.find(module => module?.routepath?.includes("/inventory"));
        if (allPermModules) {
            const result = allPermModules?.submodules?.find(item => item?.routepath == "/checkout")
            setPermissions(result?.permissions?.map(item => item?.permission_id) || []);
        }
    }, []);

    return (

        <>
            <div className="flex flex-column align-items-start md:flex-row  md:justify-content-between md:align-items-center ">
                <h4 className="mb-0" style={{ fontWeight: '600', letterSpacing: '0.5px', color: 'black' }}>
                  Check Out Inventory 
                </h4>
                <div className="flex flex-column align-items-start  md:flex-row md:align-items-center  ">
                    <span className="p-input-icon-left mr-3 resp-margin">
                        <i className="pi pi-search" />
                        <InputText
                            placeholder="Search"
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            className="search-input"
                        />
                    </span>

                    {(roletypeid == 1) || (roletypeid == 2 && usertype_id == 1 && !warehouseid) ?
                        null
                        :
                        permissions.includes(2) ?
                            <div className="">
                                <Button
                                    label="Check Out"
                                    className="Add__New-Button ml-0 mt-1 md:ml-3 md:mt-0 "
                                    icon="pi pi-minus"
                                    onClick={() => {
                                        const item = false;
                                        history.push({
                                            pathname: './checkoutaddedit',
                                            state: {
                                                isEditable: item,
                                            }
                                        });
                                    }}
                                />
                            </div>
                            :
                            null
                    }
                </div>
            </div>
            <div className="card mt-5">

                <LoadingOverlay
                    active={isActive}
                    spinner={<Triangle
                        height="120"
                        width="120"
                        color="#755fc8"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />}

                    // text='Loading your content...'
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: 'rgb(38 41 51 / 78%)',
                        })
                    }}
                >

                    <DataTable
                        responsive={true}
                        filters={filters}
                        globalFilterFields={[
                            "inventory_name",
                            "inventory_Type_Name",
                            "warehousE_NAME",
                            "rfid",
                            "notes",
                            "cost",
                        ]}
                        responsiveLayout="scroll"
                        stripedRows
                        paginator
                        rows={20}
                        value={checkInList}
                        emptyMessage="No records found"
                        paginatorClassName="custom-paginator"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    >
                        <Column sortable field='doc_no' body={(rowData) => rowData?.inventory_name || 'N/A'} header="Doc No."></Column>
                        <Column sortable field='inventory_name' body={(rowData) => rowData?.inventory_name || 'N/A'} header="Inventory Name"></Column>
                        <Column sortable field='inventory_Type_Name' body={(rowData) => rowData?.inventory_Type_Name || 'N/A'} header="Inventory Type"></Column>
                        <Column sortable field='warehousE_NAME' body={(rowData) => rowData?.warehousE_NAME || 'N/A'} header="Warehouse"></Column>
                        <Column sortable field='rfid' body={(rowData) => rowData?.rfid || 'N/A'} header="RFID Code"></Column>
                        <Column sortable field='doc_no' body={(rowData) => rowData?.doc_no || 'N/A'} header="Transaction ID"></Column>
                        <Column sortable field='quantity' body={(rowData) => rowData?.quantity || 'N/A'} header="Quantity"></Column>
                        <Column sortable field='cost' body={(rowData) => rowData?.cost || 'N/A'} header="Cost"></Column>
                        {/* <Column sortable field='notes' body={(rowData) => rowData?.notes || 'N/A'} header="Notes"></Column> */}
                        <Column
                            sortable body={(rowData) => rowData?.createddatetime ? moment(rowData.createddatetime).format('DD-MM-YYYY HH:mm A') : 'N/A'}
                            header="Created Date"
                        />
                        {/* <Column
                            body={(rowData) => rowData?.expiry_Date ? moment(rowData.expiry_Date).format('DD-MM-YYYY') : 'N/A'}
                            header="Expiry Date"
                        /> */}
                        {permissions.includes(2) ?
                            <Column header="Action" body={actionTemplate} />
                            :
                            null
                        }
                    </DataTable>
                </LoadingOverlay>
            </div>
        </>
    );
};

export default CheckOutGrid